import PropTypes from 'prop-types';
import { updateLastHit } from '../../actions/mixpanel';
import { usePathValidation } from './hooks/usePathValidation';

function LocalizedRouter({ children }) {
  updateLastHit();
  usePathValidation();

  return children;
}

LocalizedRouter.propTypes = {
  children: PropTypes.node.isRequired
};

export default LocalizedRouter;
