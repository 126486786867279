import Const from '../config/const';
import { trackEventDeleteOrder, parseAndTrackAnalyticEvents } from './analytics';
import {
  loadOrderDetailsAPI,
  sendOrderReviewAPI,
  deleteOrderAPI,
  sendOrderMessageAPI
} from '../config/api';
import { advisorOrdersDelete } from './advisor_orders';
import { log, LOG_LEVELS } from '../../common/config/app_logger';
import { toCamelCase } from '../../common/config/utils';

const loadingOrderDetails = () => ({
  type: Const.orderDetails.loading
});

const loadedOrderDetails = (orderDetails) => ({
  type: Const.orderDetails.loaded,
  orderDetails
});

const sendedOrderReview = (orderDetails) => ({
  type: Const.orderDetails.sendedOrderReview,
  orderDetails
});

const sendOrderReviewError = (error) => ({
  type: Const.orderDetails.sendedOrderReviewError,
  error
});

export const sendedOrderReviewClearError = () => ({
  type: Const.orderDetails.sendedOrderReviewClearError
});

export const loadOrderDetails = (orderId) => (dispatch) => {
  dispatch(loadingOrderDetails());
  loadOrderDetailsAPI(orderId)
    .then((orderDetails) => {
      dispatch(loadedOrderDetails(orderDetails));
    })
    .catch((error) => log(LOG_LEVELS.ERROR, 'Order', 'Error loadOrderDetails', error));
};

export const sendOrderReview = (orderId, feedback, feedbackData) => (dispatch) => {
  dispatch(loadingOrderDetails());
  sendOrderReviewAPI(feedback, orderId).then((payload) => {
    parseAndTrackAnalyticEvents(payload.analyticEvents, feedbackData);
    dispatch(sendedOrderReview(payload));
  })
    .catch((error) => {
      error.json()
        .then(jsonError => {
          log(LOG_LEVELS.ERROR, 'Order', 'Error sendOrderReviewAPI', toCamelCase(jsonError));
          dispatch(sendOrderReviewError(toCamelCase(jsonError).fullMessages[0]));
        })
        .catch(() => {
          log(LOG_LEVELS.ERROR, 'Order', 'Error sendOrderReviewAPI', error);
          dispatch(sendOrderReviewError(error.statusText));
        });
    });
};

export const deleteOrder = (order) => (dispatch) => {
  const { id } = order;
  dispatch(loadingOrderDetails());
  deleteOrderAPI(id).then(orderDetails => {
    dispatch(trackEventDeleteOrder(order));
    dispatch(advisorOrdersDelete(id));
    dispatch(loadedOrderDetails(orderDetails));
  })
    .catch((error) => log(LOG_LEVELS.ERROR, 'Order', 'Error deleteOrderAPI', error));
};

export const sendOrderMessage = (orderId, authorName, text, onComplete) => (dispatch) => {
  dispatch(loadingOrderDetails());
  sendOrderMessageAPI(orderId, authorName, text)
    .then(() => {
      dispatch(loadOrderDetails(orderId));
      onComplete();
    })
    .catch((error) => log(LOG_LEVELS.ERROR, 'Order', 'Error sendOrderMessageAPI', error));
};

export const clearOrderDetails = () => (dispatch) => {
  dispatch(({ type: Const.orderDetails.clear }));
};
