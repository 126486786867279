import React from 'react';
import PropTypes from 'prop-types';
import classes from '../classes.module.scss';
import { SvgIcon } from '../../../../../../common/sprites';

function Handle({ handle:{ id, percent }, getHandleProps, isLeft }) {
  return (
    <div
      className={ isLeft ? classes.sliderLeftHandle : classes.sliderRigthHandle }
      { ...getHandleProps(id) }
      style={ {
        left: `${ percent }%`
      } }
    >
      <SvgIcon
        id={ isLeft ? 'slider_arrow_left' : 'slider_arrow_right' }
        className={ classes.sliderArrowImg }
      />

    </div>
  );
}
Handle.propTypes = {
  handle: PropTypes.object.isRequired,
  getHandleProps: PropTypes.func.isRequired,
  isLeft: PropTypes.bool.isRequired
};

export default Handle;
