import React from 'react';
import classes from './classes.module.scss';
import { SvgIcon } from '../../../common/sprites';

export const errorTypes = {
  nickname: 'nickname',
  gender: 'gender',
  birthDate: 'birthDate',
  error: 'error'
};

export const validateNickname = ({
  nickname, t, introErrors, setIntroErrors, returnErrors = false
}) => {
  let errors = {};
  if (!nickname) errors = { [errorTypes.nickname]: t('intro.please_pick_a_username') };
  errors = { ...introErrors, ...errors };
  if (returnErrors) return errors;
  return setIntroErrors(errors);
};

export const validateGender = ({
  gender, t, introErrors, setIntroErrors, returnErrors = false
}) => {
  let errors = {};
  if (!gender) errors = { [errorTypes.gender]: t('intro.please_select_your_gender') };
  errors = { ...introErrors, ...errors };
  if (returnErrors) return errors;
  return setIntroErrors(errors);
};

export const renderPopupTitle = ({
  title, subTitle, onCloseBtnClick, closeIconId
}) => (
  <div className={ classes.popupTitleContainer }>
    <div className={ classes.popupTitleTextContainer }>
      <div className={ classes.popupTitle }>{ title }</div>
      <div className={ classes.popupSubTitle }>{ subTitle }</div>
    </div>
    <button type="button" onClick={ onCloseBtnClick } className={ classes.popupCloseBtn }>
      <SvgIcon id={ closeIconId } size={ 16 } />
    </button>
  </div>
);

export const renderRadioBtnContent = ({
  name, value, iconId, iconSelectedId
}) => {
  const radioBtn = {
    radioBtnNotSelectedContent: (
      <div className={ classes.radioBtnNotSelectedContent }>
        <SvgIcon id={ iconId } className={ classes.radioBtnContentGenderIcon } size={ 18 } />
        { name }
      </div>
    ),
    radioBtnSelectedContent: (
      <div className={ classes.radioBtnSelectedContent }>
        <SvgIcon id={ iconSelectedId } className={ classes.radioBtnContentGenderIcon } size={ 18 } />
        { name }
      </div>
    ),
    value,
    key: `${ name }NotSelected`
  };
  return radioBtn;
};

export const renderErrorModalContent = ({ introErrors, clearShowErrorModal, t }) => (
  <div>
    <div className={ classes.errorModalHeader }>
      <b>{t('app_name_title')}</b>
    </div>
    <div className={ classes.errorModalBody }>
      { introErrors.error }
    </div>
    <div className={ classes.errorModalFooter }>
      <button type="button" value="Promo Code" className={ classes.errorModalOkButton } onClick={ clearShowErrorModal }>
        <b>{ t('promoCode.ok') }</b>
      </button>
    </div>
  </div>
);
