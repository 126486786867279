import { matchPath, matchRoutes } from 'react-router';
import i18n, {
  DEFAULT_LANGUAGE, getCurrentLocalization, routesTranslationKeys
} from '../../config/i18n';
import { isKA } from '../../../common/config/utils';

export const findMatchingRouteKey = (route, isPattern = false) => {
  for (let i = 0; i < routesTranslationKeys.length; ++i) {
    const translationObject = routesTranslationKeys[i];
    const json = i18n.t(translationObject, { returnObjects: true });

    const translationKey = Object.keys(json).find(key => {
      const [pattern, pathname] = isPattern ? [route, json[key]] : [json[key], route];
      return matchPath(pattern, pathname);
    });
    if (translationKey) return `${ translationObject }.${ translationKey }`;
  }

  return null;
};

export const findFullRouteKey = (key) => {
  for (let i = 0; i < routesTranslationKeys.length; ++i) {
    const translationObject = routesTranslationKeys[i];
    const json = i18n.t(translationObject, { returnObjects: true });
    if (json && key in json) return `${ translationObject }.${ key }`;
  }
  return null;
};

export const isActiveRoute = (key) => {
  const routeKey = i18n.exists(key) ? key : findFullRouteKey(key);
  const route = i18n.t(routeKey, key);
  const lang = getCurrentLocalization();
  const pattern = lang === DEFAULT_LANGUAGE ? '/:path' : '/:lang/:path';

  const match = matchPath(pattern, window.location.pathname);
  return `/${ match?.params.path || '' }` === route;
};

export const updatePathTrailingSlashes = (path) => {
  const lastChar = path.slice(-1);

  if (isKA() && lastChar !== '/') {
    return `${ path }/`;
  }

  if (!isKA() && lastChar === '/') {
    return path.slice(0, -1);
  }

  return path;
};

export const getCurrentPath = (routes) => {
  const [{ route, params }] = matchRoutes(routes.map(x => ({ path: `/:lang?${ i18n.t(x) }` })), window.location.pathname);
  const routeKey = findMatchingRouteKey(route.path, true);

  return { route, params, routeKey };
};
