import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import queryString from 'query-string';
import Skeleton from 'react-loading-skeleton';
import classes from './classes.module.scss';
import ReviewItem, { reviewItemThemes } from '../reviews_items';
import ExpandedText from '../../../../../common/helpers/expanded_text';
import { SvgIcon } from '../../../../../common/sprites';

const filters = {
  positive: 'positive',
  negative: 'negative',
  default: 'default'
};

const reviewImage = (like, loader) => (loader ? <Skeleton width={ 15 } /> : <SvgIcon className={ classes.reviewImage } id={ like ? 'rate_positive' : 'rate_negative' } size={ 23 } />);

function Review({ review, loader }) {
  const {
    like, buyerNickname, comment, createdAt
  } = review || {};
  return (
    <div className={ classes.reviewContainer }>
      <div className={ classes.reviewerName }>
        <div className={ classes.reviewImgContainer }>
          {reviewImage(like, loader)}
        </div>
        <div className={ classes.reviewBuyerNicknameContainer }>
          <ExpandedText ellipsis>
            <div className={ classes.reviewBuyerNickname } suppressHydrationWarning>
              {buyerNickname || <Skeleton width={ 60 } />}
            </div>
          </ExpandedText>
        </div>
        <div className={ classes.reviewDateContainer } suppressHydrationWarning>
          {createdAt ? format(new Date(createdAt), 'dd MMM yy') : <Skeleton width={ 15 } />}
        </div>
      </div>
      <span className={ classes.comment } suppressHydrationWarning>
        {comment || <Skeleton count={ 3 }  />}
      </span>
    </div>
  );
}

Review.propTypes = {
  review: PropTypes.object,
  loader: PropTypes.bool
};

Review.defaultProps = {
  review: null,
  loader: false
};

const defaultSelectedFilter = () => {
  const {
    positive, negative
  } = queryString.parse(window.location.search);
  if (positive) {
    return filters.positive;
  }
  if (negative) {
    return filters.negative;
  }
  return filters.default;
};

const updateQuery = (name, history) => {
  if (name === filters.positive) {
    history(`${ window.location.pathname }?${ queryString.stringify({ positive:true }) }`, { replace: true });
    return;
  }
  if (name === filters.negative) {
    history(`${ window.location.pathname }?${ queryString.stringify({ negative:true }) }`, { replace: true });
    return;
  }
  history(`${ window.location.pathname }`, { replace: true });
};

const reviewItems = (items, loading) => {
  if (!items || !items.length) return null;
  const itemsArray = items.map((r) => <Review key={ r.orderId } review={ r } />);
  if (loading) {
    itemsArray.push(...[
      <Review loader key="loader_1" />,
      <Review loader key="loader_2" />,
      <Review loader key="loader_3" />,
      <Review loader key="loader_4" />]);
  }
  return itemsArray;
};

const getSelectedButtonStyle = (buttonId, selectedId) => ({
  height: '4px',
  backgroundColor: buttonId === selectedId ? '#000' : '#FFF',
  width:'100%'
});

const needShowPlaceHolder = (likesCount, dislikesCount) => !(likesCount || dislikesCount);

const reviewShowMore = (showMore, onShowMore, t) => (
  !showMore ? null : (
    <button
      type="button"
      className={ classes.showMoreButton }
      onClick={ onShowMore }
    >
      {t('advisor_profile.show_more')}
    </button>
  ));
function ReviewsSection({
  likesCount, dislikesCount, reviews, loadAdvisorReviews, id, t, advisorsReviewsReset
}) {
  const {
    items, showMore, loading
  } = reviews || {};

  const history = useNavigate();
  const [selectedId, setSelectedId] = useState(defaultSelectedFilter());

  const onItemTap = (e) => {
    advisorsReviewsReset(id);
    updateQuery(filters[e.currentTarget.id], history);
    setSelectedId(filters[e.currentTarget.id]);
    loadAdvisorReviews(id);
  };

  const buttonItem = (count, buttonId, children) => (count === 0 ? null : (
    <div
      id={ buttonId }
      onClick={ onItemTap }
      onKeyDown={ onItemTap }
      className={ classes.item }
    >
      <div className={ classes.reviewItemHolder }>
        { children }
      </div>
      <div style={ getSelectedButtonStyle(buttonId, selectedId) } suppressHydrationWarning />
    </div>
  ));

  const onShowMore = () => {
    loadAdvisorReviews(id);
  };

  const placeHolder = () => (
    <div className={ classes.emptyReviewsContainer }>
      <div className={ classes.noReviews }>{t('advisor_profile.no_reviews')}</div>
    </div>
  );

  const reviewsSection = () => (
    <div className={ classes.reviewsContainer }>
      { reviewItems(items, loading) }
      { reviewShowMore(showMore, onShowMore, t) }
    </div>
  );

  const reviewsContent = React.useMemo(() => {
    if (needShowPlaceHolder(likesCount || dislikesCount)) {
      return placeHolder();
    }
    return reviewsSection();
  }, [reviews]);

  return (
    <div className={ classes.container }>
      <div className={ classes.headerContainer }>
        {buttonItem(1, filters.default, t('advisor_profile.reviews'))}
        {buttonItem(
          likesCount,
          filters.positive,
          <ReviewItem
            value={ likesCount }
            isPositive
            ignoreLeftMargin
            theme={ reviewItemThemes.reviewsSection }
          />
        )}
        {buttonItem(
          dislikesCount,
          filters.negative,
          <ReviewItem
            value={ dislikesCount }
            isPositive={ false }
            ignoreLeftMargin
            theme={ reviewItemThemes.reviewsSection }
          />
        )}
      </div>
      {reviewsContent}
    </div>
  );
}
ReviewsSection.propTypes = {
  likesCount: PropTypes.number,
  dislikesCount: PropTypes.number,
  reviews: PropTypes.object,
  loadAdvisorReviews: PropTypes.func.isRequired,
  id:PropTypes.any,
  t: PropTypes.func.isRequired,
  advisorsReviewsReset: PropTypes.func.isRequired
};

ReviewsSection.defaultProps = {
  likesCount:0,
  dislikesCount:0,
  reviews: null,
  id:null
};

export default ReviewsSection;
