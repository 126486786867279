import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const InfiniteScroll = ({
  children, loadMore, loading, initialLoad, page, initialPage, loader,
  hasMore, className, threshold, scrollElementRef, paginationParams, dateLabelScrollHandler
}) => {
  useEffect(() => {
    if (initialLoad) {
      loadMore(initialPage);
    }
  }, []);

  const handleScroll = () => {
    if (scrollElementRef) {
      const { scrollHeight, scrollTop, clientHeight } = scrollElementRef.current;
      if (dateLabelScrollHandler) dateLabelScrollHandler();
      if (clientHeight - scrollHeight === Math.floor(scrollTop) && hasMore && !loading) {
        loadMore();
      }
      return;
    }
    const { innerHeight, scrollY } = window;
    const contentHeight = document.getElementById('scroller')?.clientHeight;
    if (contentHeight !== null && innerHeight + scrollY > contentHeight - threshold && hasMore && !loading) {
      loadMore(page);
    }
  };

  useEffect(() => {
    (((scrollElementRef || {}).current) || window).addEventListener('scroll', handleScroll);
    return () => {
      ((scrollElementRef || {}).current || window).removeEventListener('scroll', handleScroll);
    };
  }, [paginationParams, page, hasMore]);

  return (
    <div id="scroller" className={ className }>
      { children }
      { loading ? loader : null }
    </div>
  );
};

InfiniteScroll.propTypes = {
  children: PropTypes.node,
  loadMore: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  initialLoad: PropTypes.bool,
  page: PropTypes.number,
  initialPage: PropTypes.number,
  loader: PropTypes.node,
  hasMore: PropTypes.bool.isRequired,
  className: PropTypes.string,
  threshold: PropTypes.number,
  scrollElementRef: PropTypes.any,
  paginationParams: PropTypes.object,
  dateLabelScrollHandler: PropTypes.func
};

InfiniteScroll.defaultProps = {
  children: null,
  initialLoad: false,
  initialPage: 0,
  loader: null,
  className: '',
  threshold: 250,
  loadMore: null,
  page: null,
  scrollElementRef: null,
  paginationParams: {},
  dateLabelScrollHandler: null
};

const mapStateToProps = ({ advisorOrders }) => ({
  paginationParams: (advisorOrders || {}).paginationParams
});

export default connect(mapStateToProps, {})(InfiniteScroll);
