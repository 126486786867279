import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import withPGTranslation  from '../../../../config/withPGTranslation';
import { SvgIcon }  from '../../../../../common/sprites';

const handleClickAction = (e, dropdownId, setOpen, onOptionClick) => {
  if (document[dropdownId] && !document[dropdownId].parentNode.contains(e.target)) {
    e.stopPropagation();
    setOpen(false);
  }
  if (document[dropdownId] && document[dropdownId].parentNode.contains(e.target)) {
    e.stopPropagation();
    onOptionClick(e);
  }
};

function FilterDropdown({
  title, options, selectedOptionId, onChange, dropdownId, t
}) {
  const [open, setOpen] = useState(false);

  const onOptionClick = (e) => {
    setOpen(false);
    if (e.target.id && e.target.id !== 'null' && e.target.id !== 'undefined')  onChange(e.target.id);
  };

  const handleClick = e => {
    handleClickAction(e, dropdownId, setOpen, onOptionClick);
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const onDropdownClick = () => {
    setOpen(!open);
  };

  const dropdownMenu = () => {
    if (!open) return null;
    return (
      <div
        className={ classes.dropdownMenu }
        ref={ node => { document[dropdownId] = node; } }
        id={ `${ document[dropdownId] }` }
      >
        {Object.keys(options).map((key) => (
          <button
            key={ key }
            id={ key }
            type="button"
            className={
                key === selectedOptionId ? classes.selectedDropdownOption : classes.dropdownOption
}
            onClick={ onOptionClick }
          >
            {t(options[key])}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className={ classes.itemContainer }>
      <div className={ classes.itemHolder }>
        <span className={ classes.itemTitle }>
          {title}
        </span>
        <button
          type="button"
          className={ classes.dropdown }
          onClick={ onDropdownClick }
        >
          { t(options[selectedOptionId]) }
          <SvgIcon id={ open ? 'filter_arrow_open' : 'filter_arrow_closed' } className={ classes.arrowImg } />
        </button>
        {dropdownMenu()}
      </div>
    </div>
  );
}

FilterDropdown.propTypes = {
  options: PropTypes.object,
  title: PropTypes.string.isRequired,
  selectedOptionId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  dropdownId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

FilterDropdown.defaultProps = {
  options: [],
  selectedOptionId: null
};

export default withPGTranslation(FilterDropdown);
