import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { updateSmartBannerVisibility } from '../../../actions/application';
import { appRoutes } from '../../../config/app_routes';
import { useCurrentPath } from './useCurrentPath';

export const useSmartBannerVisibility = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.homePage?.loading);
  const location = useLocation();
  const { routeKey } = useCurrentPath();

  useEffect(() => {
    if (loading && routeKey === appRoutes.home) return;
    dispatch(updateSmartBannerVisibility(routeKey));
  }, [location.pathname, loading, routeKey]);
};
