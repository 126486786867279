/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@barges/react';
import classes from '../classes.module.scss';
import { appGeneralPopupTheme } from '../../../../common/config/const';
import AppGeneralPopup from '../../../../common/app_general_popup';
import { trackPageVisit } from '../../../actions/analytics';
import { SvgIcon } from '../../../../common/sprites';

import '@barges/scss/lib/title.css';
import '@barges/scss/lib/button.css';

export const MODAL = 'modal';
export const SETTINGS = 'settings';
export default class SignOut extends PureComponent {
  static propTypes = {
    success: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    logOutAction: PropTypes.func.isRequired,
    logOutClear: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    theme: PropTypes.oneOf([MODAL, SETTINGS])
  };

  static defaultProps = {
    theme: SETTINGS
  };

  state = {
    showSignOutModal: false
  };

  componentDidUpdate(prevProps) {
    const { success, logOutClear } = this.props;
    if (!prevProps.success && success) {
      logOutClear();
    }
  }

  signOutClick = () => {
    this.setState({ showSignOutModal: true });
    trackPageVisit('sign out? popup');
  };

  signOutCancel = () => {
    this.setState({ showSignOutModal: false });
  };

  signOutOk = () => {
    this.setState({ showSignOutModal: false });
    const { logOutAction } = this.props;
    logOutAction();
  };

  renderSingOutContent = () => {
    const { t } = this.props;
    return (
      <div className={ classes.signOutContentContainer }>
        <div className={ classes.signOutHeader }>
          <b>{ t('app_name_title') }</b>
        </div>
        <div className={ classes.signOutBody }>
          { t('settings.are_you_sure_you_want_sign_out') }
        </div>
        <div className={ classes.footer }>
          <div className={ classes.signOutYesBtnContainer }>
            <button type="button" onClick={ this.signOutCancel } className={ classes.signOutCancelButton }>{ t('settings.cancel') }</button>
          </div>
          <div className={ classes.signOutCancelBtnContainer }>
            <button type="button" onClick={ this.signOutOk } className={ classes.signOutYesButton }>{ t('settings.yes') }</button>
          </div>
        </div>
      </div>
    );
  };

  renderBoldText = (text) => (
    <div>
      <b>{ text }</b>
    </div>
  );

  renderSignOutButton = () => {
    const { t, theme } = this.props;
    const isModal = theme === MODAL;
    if (isModal) {
      return (
        <button
          type="button"
          className = { classes.signOutButtonModal }
          onClick = { this.signOutClick }
        >
          <SvgIcon
            id="popover_logout"
            size={ 24 }
          />
          <div className={ isModal ? classes.modalSignOutText : '' }>{ t('settings.sign_out') }</div>
        </button>
      );
    }
    const icon =  (
      <SvgIcon
        size={ 24 }
        id="sign_out"
        className = { classes.signOutImage }
      />
    );
    return (
      <div className={ classes.signOutButton }>
        <Button type="secondary" title={ t('settings.sign_out') } onClick={ this.signOutClick } icon={ icon } />
      </div>
    );
  };

  render() {
    const { showSignOutModal } = this.state;
    const { theme } = this.props;
    const isModal = theme === MODAL;
    return (
      <div className={ isModal ? classes.signOutContainerModal : classes.signOutContainer }>
        { this.renderSignOutButton() }
        <AppGeneralPopup
          visible={ showSignOutModal }
          closable={ this.signOutCancel }
          theme={ appGeneralPopupTheme.CENTERED }
        >
          { this.renderSingOutContent()}
        </AppGeneralPopup>
      </div>
    );
  }
}
