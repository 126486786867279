import { useNavigate, useMatch } from 'react-router';
import { useLocalizedPath } from './useLocalizedPath';

export const useLocalizedNavigate = () => {
  const history = useNavigate();
  const match = useMatch('/:lang?/*');
  const generatePath = useLocalizedPath();

  return (pathKey, { params, options } = {}) => {
    if (pathKey === 0) {
      history(0);
      return;
    }

    const path = generatePath(match.params.lang, pathKey, params);

    history({ pathname: path, search: params?.search }, options);
  };
};
