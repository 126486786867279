/* eslint-disable camelcase */
import { isAdvisorBusy, getModeColor } from '../../../../config/util';
import { isWebView } from '../../../../../common/config/utils';

export const modeIsOnline = (modeState) => modeState === 'online';
export const getBGColor = (mode, modeState) => {
  if (modeState !== 'online') return '#EAEAEA';
  if (isWebView() && (mode === 'voice' || mode === 'video')) {
    return '#EAEAEA';
  }
  const defaultModeColors = { chat: getModeColor('chat'), voice: getModeColor('voice'), video: getModeColor('video') };
  return defaultModeColors[mode];
};

export const getModeShadowColor = ({ modeState }) => {
  if (modeState !== 'online') return null;
  return { boxShadow: 'inset 0px -2px 0px rgba(0, 0, 0, 0.25)' };
};

export const getModeStatusColor = ({
  mode, modeState, liveModes, isFreeMinLabel = false
}) => {
  if (isAdvisorBusy({ liveModes }) && !isFreeMinLabel) return { color: 'transparent', backgroundColor: 'transparent' };
  if (modeState === 'busy') return { color: window.shared.advisorBusyColor };
  if (modeState !== 'online') return { color: '#A1A1A1' };
  if (isWebView() && (mode === 'voice' || mode === 'video')) {
    return { color: '#A1A1A1' };
  }
  const modeColors = { chat:{ color: getModeColor('chat') }, voice: { color: getModeColor('voice') }, video: { color: getModeColor('video') } };
  return modeColors[mode];
};

export const getModeTitleColor = (modeState, mode) => {
  if (isWebView() && (mode === 'voice' || mode === 'video')) {
    return { color: '#A1A1A1' };
  }
  if (modeState === 'disabled') return { color: '#A1A1A1' };
  if (modeState !== 'online') return { color: '#363636' };
  return { color: '#FFFFFF' };
};

export const getModePriceColor = (modeState, mode) => {
  if (isWebView() && (mode === 'voice' || mode === 'video')) {
    return { color: '#7D7D7D' };
  }
  if (modeState !== 'online') return { color: '#7D7D7D' };
  return { color: '#FFFFFF' };
};

export const modeStatusStrings =  {
  online: 'advisor_profile.live', offline: 'advisor_profile.offline', busy: 'advisor_profile.busy', disabled: 'advisor_profile.disabled'
};
