import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { updateNavigationStack } from '../../../actions/application';

export const useNavigationStackUpdate = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(updateNavigationStack({}));
  }, [location.pathname]);
};
