import { connect } from 'react-redux';
import withPGTranslation from '../../config/withPGTranslation';
import { clearApplicationError } from '../../actions/aplication_error';
import NotFound from './not_found';

const mapStateToProps = ({ applicationError }) => ({
  ...applicationError
});

export default connect(mapStateToProps, {
  clearApplicationError
})(withPGTranslation(NotFound));
