import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import { log, LOG_LEVELS } from '../../../common/config/app_logger';

const getRadioBtnStyle = ({ value, selectedValue }) => {
  if (value === selectedValue) return classes.radioBtnChecked;
  return classes.radioBtn;
};

const getBorderColor = ({ error }) => {
  if (error) return { borderColor: '#FF4242' };
  return {};
};

function RadioGroup({
  children, onChange, selectedValue, error
}) {
  const onSelectionChanged = e => {
    const newValue = e.currentTarget.getAttribute('value');
    log(LOG_LEVELS.DEBUG, 'General', 'RadioGroup value changed', { value: newValue });
    onChange(newValue);
  };

  const renderRadioBtn = ({ child }) => {
    if (child.value === selectedValue) return child.radioBtnSelectedContent;
    return child.radioBtnNotSelectedContent;
  };

  const renderRadioGroup = () => (
    <div className={ classes.radioGroupContainer } style={ { ...getBorderColor({ error }) } }>
      { children.map((child) => (
        <div
          key={ child.key }
          className={ getRadioBtnStyle({ value: child.value, selectedValue }) }
          onClick={ onSelectionChanged }
          onKeyDown={ onSelectionChanged }
          value={ child.value }
          style={ { ...getBorderColor({ error }) } }
        >
          { renderRadioBtn({ child }) }
        </div>
      ))}
    </div>
  );

  return renderRadioGroup();
}

RadioGroup.propTypes = {
  children: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
  error: PropTypes.string
};

RadioGroup.defaultProps = {
  children: null,
  selectedValue: null,
  error: null
};

export default RadioGroup;
