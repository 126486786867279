import { appLogo } from '../../logo/logo';

const { shared } = window;

export const componentStyles = {
  appLogo: 'appLogo',
  sideMenuIcon: 'sideMenuIcon',
  titleColor: 'titleColor',
  joinButton: 'joinButton',
  mainContainer: 'mainContainer',
  headerTitle: 'headerTitle',
  profileInfoContainer: 'profileInfoContainer',
  headerContainer: 'headerContainer',
  signIn: 'signIn'
};

const transparentStyles = {
  [componentStyles.appLogo]: appLogo(),
  [componentStyles.sideMenuIcon]: 'header_menu_transparent',
  [componentStyles.titleColor]: { color: '#FFFFFF' },

  [componentStyles.joinButton]: {
    backgroundColor: shared.appColor,
    borderColor: '',
    borderStyle: 'none',
    borderRadius: 'var(--app-default-btn-md-border-radius)'
  },
  [componentStyles.mainContainer]: {
    backgroundColor: '#FFFFFF00',
    borderBottomStyle:'none',
    borderBottomWidth: '0',
    borderBottomColor: 'none'
  },
  [componentStyles.headerTitle]: {
    color: '#FFFFFF'
  },
  [componentStyles.profileInfoContainer]: {
    color: '#FFFFFF'
  },
  [componentStyles.signIn]: {
    color: '#FFFFFF'
  }
};

const regularStyles = {
  [componentStyles.appLogo]: appLogo(),
  [componentStyles.sideMenuIcon]: 'header_menu',
  [componentStyles.titleColor]: { color: '#000000' },

  [componentStyles.joinButton]: {
    backgroundColor: shared.appColor,
    borderColor: '',
    borderStyle: 'none',
    borderRadius: 'var(--app-default-btn-md-border-radius)'
  },
  [componentStyles.mainContainer]: {
    backgroundColor: '#FFFFFF',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: '#D6D6D6'
  },
  [componentStyles.headerTitle]: {
    color: '#000000'
  },
  [componentStyles.profileInfoContainer]: {
    color: '#000000'
  },
  [componentStyles.headerContainer]: {
    backgroundColor: '#FFFFFF',
    borderBottom: '1px solid #E4E4E4'
  },
  [componentStyles.signIn]: {
    color: '#000000'
  }
};

export const getComponentStyle = (transparent, component) =>   (
  transparent ? transparentStyles[component] : regularStyles[component]);
