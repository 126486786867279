import React, { useState, useEffect } from 'react';
import  queryString  from 'query-string';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router';
import classes from './classes.module.scss';
import AppLink from '../../routes/app_link';
import { toCamelCase } from '../../../../common/config/utils';
import { SvgIcon } from '../../../../common/sprites';

const left = 2;
const right = 3;

const getPaginationPageItemClassName = ({ page, paginationPage, seenPages }) => {
  if (page === paginationPage) return classes.paginationPageItemActive;
  if (seenPages.includes(page)) return classes.paginationPageItemSeen;
  return classes.paginationPageItemNotActive;
};

const getSearchWithPage = ({ page, location }) => {
  const srarch = queryString.parse(location.search);
  delete srarch.page;
  const searchWithPage = queryString.stringify({
    ...srarch,
    ...(page > 1 ? { page } : null)
  });
  return searchWithPage;
};

const sortPaginationPagesNumbers = ({ paginationPagesNumbers, lastPageNum }) => {
  paginationPagesNumbers.sort((a, b) => a - b);
  if (paginationPagesNumbers[paginationPagesNumbers.length - 1] !== lastPageNum) {
    paginationPagesNumbers[paginationPagesNumbers.length - 1] = lastPageNum;
    paginationPagesNumbers[paginationPagesNumbers.length - 2] = '...';
  }
  paginationPagesNumbers = paginationPagesNumbers.filter((item) => item > 0 || item === '...');
  return paginationPagesNumbers;
};

const generatePaginationPageNumbers = ({ lastPageNum, paginationPage }) => {
  let paginationPagesNumbers = [];
  for (let i = left; i >= -right; i--) {
    let page = paginationPage - i;
    if (page <= 0) {
      page = paginationPage + right + (1 - page);
    }
    if (page > lastPageNum) {
      page = paginationPage - left - (page - lastPageNum);
    }
    paginationPagesNumbers.push(page);
  }
  paginationPagesNumbers = sortPaginationPagesNumbers({ paginationPagesNumbers, lastPageNum });
  return paginationPagesNumbers;
};

const generatePaginationPageItems = ({
  lastPageNum, paginationPage, seenPages, location, setPaginationPageAction
}) => {
  const paginationPagesNumbers = generatePaginationPageNumbers({ lastPageNum, paginationPage });
  const paginationPageItems = [];
  paginationPagesNumbers.forEach((page) => {
    if (page === '...') {
      paginationPageItems.push(
        <div key={ page } className={ classes.paginationPageItemNotActive }>
          { page }
        </div>
      );
    } else {
      paginationPageItems.push(
        <AppLink
          to={ {
            pathname: window.location.pathname,
            search: getSearchWithPage({ page, location }),
            state: { ...location.state }
          } }
          key={ page }
          className={ getPaginationPageItemClassName({ page, paginationPage, seenPages }) }
          onClick={ () => setPaginationPageAction(page) }
          blank={ page ===  paginationPage }
        >
          {page}
        </AppLink>
      );
    }
  });
  return paginationPageItems;
};

const renderPaginationPageLeftArrow = ({ paginationPage, leftArrowClick }) => {
  if (paginationPage === 1) return null;
  return (
    <div
      className={ classes.paginationPageArrowContainer }
      onKeyDown={ leftArrowClick }
      onClick={ leftArrowClick }
    >
      <SvgIcon id="left_arrow" className={ classes.paginationPageArrow } size={ 12 } />
    </div>
  );
};

const renderPaginationPageRightArrow = ({ paginationPage, lastPageNum, rightArrowClick }) => {
  if (paginationPage === lastPageNum) return null;
  return (
    <div
      className={ classes.paginationPageArrowContainer }
      onKeyDown={ rightArrowClick }
      onClick={ rightArrowClick }
    >
      <SvgIcon id="right_arrow" className={ classes.paginationPageArrow } size={ 12 } />
    </div>
  );
};

function PaginationPages({
  loadAdvisors, params, path, seenPages, lastPageNum
}) {
  const history = useNavigate();
  const location = useLocation();
  const [paginationPage, setPaginationPage] = useState(
    toCamelCase(queryString.parse(window.location.search)).page && typeof toCamelCase(queryString.parse(window.location.search)).page !== 'undefined'
      ? parseFloat(toCamelCase(queryString.parse(window.location.search)).page) : 1
  );
  const setPaginationPageAction = (page) => {
    setPaginationPage(parseFloat(page));
    loadAdvisors({ ...params, page }, path, page);
  };

  const seeMoreBtnClick = ({ page }) => {
    const srarch = queryString.parse(location.search);
    const searchWithPage = queryString.stringify({ ...srarch, page });
    history(`${ location.pathname }?${ searchWithPage }`, {
      state: { ...location.state },
      replace: true
    });
    setPaginationPageAction(page);
  };

  useEffect(() => {
    if (lastPageNum && paginationPage > lastPageNum) {
      seeMoreBtnClick({ page: lastPageNum });
    }
  }, []);

  const renderPaginationPageItems = () => {
    const paginationPageItems = generatePaginationPageItems({
      lastPageNum, paginationPage, seenPages, location, setPaginationPageAction
    });
    return paginationPageItems.map((item) => item);
  };

  const leftArrowClick = () => {
    seeMoreBtnClick({ page: paginationPage - 1 });
  };

  const rightArrowClick = () => {
    seeMoreBtnClick({ page: paginationPage + 1 });
  };

  const renderPaginationPages = () => (
    <div className={ classes.paginationPagesContainer }>
      { renderPaginationPageLeftArrow({ paginationPage, leftArrowClick }) }
      { renderPaginationPageItems() }
      { renderPaginationPageRightArrow({ paginationPage, lastPageNum, rightArrowClick }) }
    </div>
  );

  return renderPaginationPages();
}

PaginationPages.propTypes = {
  loadAdvisors: PropTypes.func,
  params: PropTypes.object,
  path: PropTypes.string,
  lastPageNum: PropTypes.number,
  seenPages: PropTypes.array
};

PaginationPages.defaultProps = {
  loadAdvisors: null,
  path: null,
  params: null,
  lastPageNum: null,
  seenPages: []
};

export default PaginationPages;
