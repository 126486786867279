import { connect } from 'react-redux';
import withPGTranslation from '../../../config/withPGTranslation';
import { clearApplicationError } from '../../../actions/aplication_error';
import TerminatedAdvisor from './terminated_advisor';

const mapStateToProps = ({
  applicationError,
  advisors
}, { advisorId }) => ({
  ...applicationError,
  advisor: { ...(advisors || {})[parseInt(advisorId, 10)] || {} }
});

export default  connect(mapStateToProps, {
  clearApplicationError
})(withPGTranslation(TerminatedAdvisor));
