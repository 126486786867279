import React from 'react';
import { Speciality } from '@barges/react';
import '@barges/scss/lib/speciality.css';
import '@barges/scss/lib/title.css';
import { SectionProps, SectionDefaultProps } from '../prop_types';
import classes from '../classes.module.scss';
import AppLink from '../../routes/app_link';
import { specialitiesClickSource } from '../../../config/const';
import { isKA, isPG } from '../../../../common/config/utils';

const specialityClass = isKA() ? classes.specialityKA : classes.specialityPG;

function SpecialitiesSection({ content }) {
  const renderSpeciality = (speciality, index) => {
    const {
      iconUrl, name, slug, params, iconName
    } = speciality;
    return (
      <AppLink
        to={ {
          pathname: slug,
          state: {
            needRefresh: true,
            trackCategoryEvent: true,
            clickSource: specialitiesClickSource.homePage,
            params
          }
        } }
        key={ index }
        className={ `${ classes.speciality } ${ specialityClass }` }
      >
        <Speciality
          type="button"
          speciality={ { iconName: isPG() ? iconName : null, name, iconUrl } }
        />
      </AppLink>
    );
  };

  const renderContent = () => {
    if (!(content || {}).specialities) return null;
    return (
      <div className={ classes.specialitiesContainer }>
        { content.specialities.map((speciality, index) => renderSpeciality(speciality, index)) }
      </div>
    );
  };

  return renderContent();
}

SpecialitiesSection.propTypes = {
  ...SectionProps
};
SpecialitiesSection.defaultProps = {
  ...SectionDefaultProps
};

export default SpecialitiesSection;
