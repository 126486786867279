import { connect } from 'react-redux';
import withPGTranslation from '../../config/withPGTranslation';
import { clearApplicationError } from '../../actions/aplication_error';
import GeneralError from './general_error';

const mapStateToProps = ({ applicationError }) => ({
  ...applicationError
});

export default  connect(mapStateToProps, {
  clearApplicationError
})(withPGTranslation(GeneralError));
