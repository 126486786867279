import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from './classes.module.scss';
import Badge from '../badge';
import AppLink from '../routes/app_link';
import { getComponentStyle, componentStyles } from './component_styles';
import { appLogo } from '../../logo/logo';
import { SvgIcon } from '../../../common/sprites';
import { appRoutes } from '../../config/app_routes';

const logo = window.config.logo.url;

const getAltLogo = ({ t }) => {
  const altLogo = window.config.logo.alt_text;
  if (altLogo) return altLogo;
  return `${ t('app_name_title').toLowerCase() } live psychic readings`;
};

const renderLogoImg = ({ itemPropImage, t }) => (
  <img
    src= { logo || appLogo() }
    alt= { getAltLogo({ t }) }
    className={ classes.logoImg }
    itemProp={ itemPropImage.imageContentUrl }
    suppressHydrationWarning
  />
);

const renderLogoMeta = (itemPropImage) => {
  if (!itemPropImage.meta) return null;
  return Object.keys(itemPropImage.meta).map((meta) => itemPropImage.meta[meta]);
};

const renderLogo = ({ onSideMenu, itemProp, t }) => {
  if (onSideMenu) return null;
  const itemPropImage = (itemProp || {}).image || {};
  return (
    <span
      className={ classes.headerLogoH1Container }
      itemProp={ itemPropImage.imageItemProp }
      itemScope= { itemPropImage.itemScope }
      itemType= { itemPropImage.itemType }
    >
      { renderLogoImg({ itemPropImage, t }) }
      { renderLogoMeta(itemPropImage) }
    </span>
  );
};

function HeaderLogo({
  toggleDrawer, sideMenuIcon, badgeCount, transparent, onSideMenu, itemProp
}) {
  const { t } = useTranslation();
  return (
    <div className={ classes.headerLogoContainer } suppressHydrationWarning>
      <button type="button" onClick={ toggleDrawer } className={ classes.menuButton } suppressHydrationWarning>
        <SvgIcon id={ sideMenuIcon } className={ classes.menuButtonImg } suppressHydrationWarning />
        <Badge customClass={ classes.badge } count={ badgeCount || 0 } suppressHydrationWarning />
      </button>
      <AppLink to={ appRoutes.home } className={ classes.headerLink }>
        <div>
          { renderLogo({ onSideMenu, itemProp, t }) }
        </div>
        <div suppressHydrationWarning style={ onSideMenu ? { display: 'none' } : {} }>
          <div
            suppressHydrationWarning
            className={ classes.headerTitle }
            style={ getComponentStyle(transparent, componentStyles.headerTitle) }
          >
            {t('logo_text')}
            <div className={ classes.headerTitleTM }>TM</div>
          </div>
        </div>
      </AppLink>
    </div>
  );
}

HeaderLogo.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  sideMenuIcon: PropTypes.string.isRequired,
  badgeCount: PropTypes.number,
  transparent: PropTypes.bool,
  onSideMenu: PropTypes.bool,
  itemProp: PropTypes.object
};

HeaderLogo.defaultProps = {
  badgeCount: null,
  transparent: false,
  onSideMenu: null,
  itemProp: null
};

export default HeaderLogo;
