export const advisorHeaderTheme = {
  default: 'default',
  terminatedAdvisor: 'terminatedAdvisor'
};

export const advisorSpecialitiesTheme = {
  default: 'default',
  terminatedAdvisor: 'terminatedAdvisor'
};

export const advisorProfileAvatarTheme = {
  default: 'default',
  terminatedAdvisor: 'terminatedAdvisor'
};

export const GDPRConsernPopupThemes = {
  regular: 'regular',
  social: 'social',
  server: 'server'
};
