import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import AppGeneralPopup from '../../../../common/app_general_popup';
import { appGeneralPopupTheme } from '../../../../common/config/const';
import DownloadAppPopup from './download_app_popup';
import { trackPageVisit, getSessionType } from '../../../actions/analytics';
import RateReviewAfterChat from '../../rate_review/rate_review_after_chat';
import { appRoutes } from '../../../config/app_routes';
import { SvgIcon } from '../../../../common/sprites';
import { useLocalizedNavigate } from '../../routes/hooks/useLocalizedNavigate';

const STATE_NEED_REVIEW = 'need_review';

const getNeedShowReviewBrowser = ({ orderState }) => STATE_NEED_REVIEW === orderState;

function ChatEnded({
  cancelChat,
  t,
  clearAffiliatePromoPopup,
  clientSummaryScreen,
  orderState,
  name,
  orderId,
  advisorId,
  profileLanguage,
  createdAt,
  chatType
}) {
  const {
    title, alert, largeNumbers, smallNumbers, total, hangupError
  } = clientSummaryScreen || {};

  const history = useLocalizedNavigate();
  const [needShowReviewBrowser, setNeedShowReviewBrowser] = useState(getNeedShowReviewBrowser({ orderState }));

  useEffect(() => {
    trackPageVisit(`${ getSessionType(chatType) } ended`);
  }, []);

  const redirectTo = () => {
    history(appRoutes.orderDetails, { params: { advisorId, orderId } });
  };

  const cancelAction = () => {
    cancelChat();
    clearAffiliatePromoPopup();
  };

  const cancelRateReviewBrowser = () => {
    clearAffiliatePromoPopup();
    setNeedShowReviewBrowser(false);
    redirectTo();
  };

  const renderTitleBlock = () => (
    <div className={ classes.titleContainerBrowser }>
      <h4>{title}</h4>
    </div>
  );

  const renderHangupErrorBlock = () => (
    hangupError && (
    <div className={ classes.hangupErrorBlock }>
      <div className={ classes.hangupError }>{hangupError?.label}</div>
    </div>
    )
  );

  const renderAlertBlock = () => (
    alert && (
    <div className={ classes.alertBlock }>
      <div className={ classes.alert }>{alert?.label}</div>
      <div className={ classes.alertHint }>{alert?.hint}</div>
    </div>
    )
  );

  const renderLargeNumbersBlock = () => (
    <div style={ { overflowY: 'auto', height: 300 } } className={ classes.chatInfoContainerBrowser }>
      {largeNumbers?.map((line, index) => (
        <div key={ index }>
          <span className={ classes.descriptionTextBrowser }>{ line?.label }</span>
          <br />
          <div className={ classes.descriptionDataBrowser }>
            {line?.value}
          </div>
        </div>
      ))}
    </div>
  );

  const renderSmallNumbersBlock = () => (
    smallNumbers?.length > 0 && (
      <div className={ classes.discountBlock }>
        {smallNumbers?.map((line, index) => (
          <div key={ index } className={ classes.smallBlockLine }>
            <div className={ classes.smallBlockLabel }>{line?.label}</div>
            <div className={ classes.smallBlockValue }>{line?.value}</div>
          </div>
        ))}
      </div>
    )
  );

  const renderTotalHint = () => {
    if (!total?.hint) return null;
    return (
      <div className={ classes.totalHint }>
        { total?.hint }
        <span className={ classes.totalHintHighlighted }>{ ` ${ total?.hintHighlighted }` }</span>
      </div>
    );
  };

  const renderTotalBlock = () => (
    <div className={ classes.totalWrapper }>
      <div className={ classes.totalLabel }>{total?.label}</div>
      <div className={ classes.totalValue }>{total?.value}</div>
      { renderTotalHint() }
    </div>
  );

  const closeButton = () => (
    <button
      onClick={ () => {
        cancelAction();
        redirectTo();
      } }
      type="button"
      className={ classes.closeAction }
    >
      <SvgIcon id="close_icon" size={ 28 } />
    </button>
  );

  const renderChatModalContentNumbers = () => (
    <>
      {renderTitleBlock()}
      {renderHangupErrorBlock()}
      {renderLargeNumbersBlock()}
      {renderSmallNumbersBlock()}
    </>
  );

  const renderChatEndedModalContentHeader = () => (
    <>
      {renderChatModalContentNumbers()}
      {renderTotalBlock()}
      {renderAlertBlock()}
      {closeButton()}
    </>
  );

  const renderChatEndedModalContent = () => (
    <>
      {renderChatEndedModalContentHeader()}
    </>
  );

  const renderChatEndedModal = () => (
    <AppGeneralPopup theme={ needShowReviewBrowser ? appGeneralPopupTheme.CHAT_ENDED_RATE_REVIEW : appGeneralPopupTheme.CHAT_ENDED }>
      <div className={ classes.container }>
        {renderChatEndedModalContent()}
        <RateReviewAfterChat
          visible={ needShowReviewBrowser }
          onCancel={ cancelRateReviewBrowser }
          advisorName={ name }
          orderId={ orderId }
          advisorId={ advisorId }
          profileLanguage= { profileLanguage }
          createdAt={ createdAt }
          clickSource = "chat ended"
          chatType={ chatType }
        />
      </div>
      <div className={ classes.doneButtonContainerBrowser }>
        <button
          type="button"
          className={ classes.doneBtnBrowser }
          onClick={ () => {
            cancelAction();
            redirectTo();
          } }
          onKeyDown={ () => {
            cancelAction();
            redirectTo();
          } }
        >
          {t('chat.continue')}
        </button>
      </div>
    </AppGeneralPopup>
  );

  const renderChatEnded = () => (
    <>
      { renderChatEndedModal() }
      <DownloadAppPopup />
    </>
  );

  return renderChatEnded();
}

ChatEnded.propTypes = {
  cancelChat: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  clearAffiliatePromoPopup: PropTypes.func.isRequired,
  clientSummaryScreen: PropTypes.object.isRequired,
  textChatReason: PropTypes.string
};

export default ChatEnded;
