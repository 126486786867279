import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Const from '../../../config/const';
import { isXfmProgram } from '../../../config/util';
import Stopwatch from '../../stopwatch';
import { getAdvisorSlug } from '../../../project_items/helper_functions';

function TimerComponent({
  freeDuration, paidDuration, state, t, advisorXfmProgram, userXfmProgram, advisorId, loadAdvisor, buyerDurationCounterDirection, trackSessionHireEventIfNeeded
}) {
  const [seconds, setSecondsLeft] = useState(null);
  const [interval, setTimerInterval] = useState(null);

  const calculateEndDate = ({ duration }) => {
    const currentDate = new Date();
    return currentDate.setSeconds(currentDate.getSeconds() + duration);
  };

  const stopTimer = () => {
    if (interval) {
      clearInterval(interval);
      setTimerInterval(null);
    }
  };

  const getDuration = () => freeDuration + paidDuration;

  const startTimer = () => {
    const endDate = calculateEndDate({ duration: getDuration() });
    stopTimer();
    const timer = setInterval(() => {
      const datesSecondsDiff = (new Date(endDate).getTime() - new Date().getTime()) / 1000;
      setSecondsLeft(Math.round(datesSecondsDiff));
    }, 1000);
    setTimerInterval(timer);
  };

  useEffect(() => {
    if (state === Const.chatStates.paused || state === Const.chatStates.ended) {
      stopTimer();
      if (isXfmProgram({ advisorXfmProgram, userXfmProgram })) loadAdvisor(getAdvisorSlug({ advisorId }));
    }
    if (state === Const.chatStates.started) {
      startTimer();
    }
    return () => {
      stopTimer();
    };
  }, [state]);

  useEffect(() => {
    if (seconds && seconds <= paidDuration) {
      trackSessionHireEventIfNeeded();
    }
  }, [seconds]);

  const renderNumber = number => (number < 10 ? `0${ number }` : number);

  const renderRemainingTime = () => {
    const minutes = parseInt(seconds / 60, 10);
    const remainigSeconds = seconds % 60;

    return (
      <div>
        {renderNumber(minutes)}
        :
        {renderNumber(remainigSeconds)}
      </div>
    );
  };

  const renderFreeSetupTime = () => <div>{ t('chat.free_setup_time') }</div>;

  const renderTimer = () => {
    if (seconds <= 0) return null;
    if (buyerDurationCounterDirection === 'stopwatch') {
      return (
        <Stopwatch
          secondsCount={ seconds }
          paidDuration={ paidDuration }
          callType="chat"
        />
      );
    }
    return (
      <div>
        {seconds <= paidDuration ? renderRemainingTime() : renderFreeSetupTime()}
      </div>
    );
  };

  return renderTimer();
}

TimerComponent.propTypes = {
  freeDuration: PropTypes.number.isRequired,
  paidDuration: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  advisorXfmProgram: PropTypes.object,
  userXfmProgram: PropTypes.object,
  loadAdvisor: PropTypes.func.isRequired,
  advisorId: PropTypes.number.isRequired,
  buyerDurationCounterDirection: PropTypes.string.isRequired
};

TimerComponent.defaultProps = {
  advisorXfmProgram: {},
  userXfmProgram: {}
};

export default TimerComponent;
