import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router';
import classes from './classes.module.scss';
import { appRoutes } from '../../config/app_routes';
import { SvgIcon } from '../../../common/sprites';
import { useLocalizedNavigate } from '../routes/hooks/useLocalizedNavigate';

const renderNotFound = (errorCode, notFoundAction, renderServiceUnavailablePage) => {
  if (errorCode === 404) return notFoundAction();
  if (errorCode >= 500) return renderServiceUnavailablePage();
  return null;
};

function NotFound({
  errorCode, t, clearApplicationError, statusText
}) {
  const history = useLocalizedNavigate();
  const isAdvisorPage = matchPath(`/:lang?${ t(appRoutes.advisor) }`, window.location.pathname);

  const onHomePress = () => {
    clearApplicationError();
    history(appRoutes.home, { options: { replace: true } });
  };

  useEffect(() => {
    if (isAdvisorPage && errorCode === 404) onHomePress();
  }, [errorCode]);

  const renderNotFoundPage = () => (
    <div className={ classes.container }>
      <div className={ classes.innerContainer }>
        <span className={ classes.title }>
          {t('error.oops')}
        </span>
        <SvgIcon
          id="not_found"
          className={ classes.img }
          size={ [400, 180] }
        />
        <span className={ classes.notFound }>
          {t('error.not_found')}
        </span>
        <span className={ classes.subtitle }>
          {t('error.404_info')}
        </span>
        <button
          type="button"
          className={ classes.link }
          onClick={ onHomePress }
        >
          {t('error.home_button')}
        </button>
      </div>
    </div>
  );

  const notFoundAction = () => {
    if (isAdvisorPage) return null;
    return renderNotFoundPage();
  };

  const renderServiceUnavailablePage = () => (
    <div className={ classes.container }>
      <div className={ classes.innerContainer }>
        <span className={ classes.title }>
          {t('error.oops')}
        </span>
        <span
          className={ classes.serviceUnavailableErrorCode }
        >
          {errorCode}
        </span>

        <span className={ classes.serviceUnavailableText }>
          {statusText}
        </span>
        <button
          type="button"
          className={ classes.link }
          onClick={ onHomePress }
        >
          {t('error.home_button')}
        </button>
      </div>
    </div>
  );

  return renderNotFound(errorCode, notFoundAction, renderServiceUnavailablePage);
}

NotFound.propTypes = {
  errorCode: PropTypes.number,
  t: PropTypes.func.isRequired,
  clearApplicationError: PropTypes.func.isRequired,
  statusText: PropTypes.string
};

NotFound.defaultProps = {
  errorCode: null,
  statusText: null
};

export default NotFound;
