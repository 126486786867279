import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import { HomePageAdvisorLiveModes } from '../../prop_types';
import PriceText from '../price_text';
import { isPrerenderMode } from '../../../../config/util';
import { AdvisorModesThemes } from '../../../../config/const';
import { isKA, isPS } from '../../../../../common/config/utils';
import { SvgIcon } from '../../../../../common/sprites';

const modeStateToIconId = {
  online: 'online',
  busy: 'online',
  offline: 'offline'
};

function AdvisorModes({
  liveModeMinPrice, liveModes, theme, liveModeMinPromoPrices, text, loading, showPrice
}) {
  const renderMode = (liveMode) => {
    const { modeState, mode } = liveMode;
    if (modeState === 'disabled') return null;

    const classContainer = theme === AdvisorModesThemes.recommendedAdvisor && isPS() ? classes[theme] : classes.modeIconContainer;
    return (
      <div className={ `${ classContainer } ${ isPS() ? classes.withBg : '' }` } key={ mode }>
        <SvgIcon id={ `mode_${ mode }_${ modeStateToIconId[modeState] || 'offline' }` } className={ `${ classes.modeIcon } ${ isKA() ? classes.big : '' }` } />
      </div>
    );
  };

  const renderModes = () => {
    if (isPrerenderMode() || loading || liveModes.length === 0) return <div className={ classes.modesHolderSkeleton } />;
    return liveModes.map((mode) => renderMode(mode));
  };

  return (
    <div className={ `${ classes[theme] || classes[AdvisorModesThemes.preview] } ${ isKA() ? classes.v2 : '' }` }>
      <div className={ `${ classes.mainContainer } ${ isKA() ? classes.reverse : '' }` }>
        <div className={ classes.modesHolder }>
          { renderModes() }
        </div>
        { showPrice && <PriceText liveModeMinPrice={ liveModeMinPrice } liveModeMinPromoPrices={ liveModeMinPromoPrices } text={ text } liveModes={ liveModes } /> }
      </div>
    </div>
  );
}

AdvisorModes.propTypes = {
  liveModeMinPrice: PropTypes.string,
  liveModeMinPromoPrices: PropTypes.object,
  liveModes: PropTypes.arrayOf(PropTypes.shape(HomePageAdvisorLiveModes)),
  theme: PropTypes.string,
  text: PropTypes.string,
  loading: PropTypes.bool,
  showPrice: PropTypes.bool
};

AdvisorModes.defaultProps = {
  liveModeMinPrice: null,
  liveModes: [],
  liveModeMinPromoPrices: {},
  theme: AdvisorModesThemes.preview,
  text: null,
  loading: false,
  showPrice: true
};

export default AdvisorModes;
