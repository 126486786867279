import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import classes from './classes.module.scss';
import { hideBodyScroll, unlockBodyScroll, toCamelCase } from '../../../common/config/utils';
import Spiner from '../../../common/helpers/spiner';
import AppGeneralPopup from '../../../common/app_general_popup';
import { appGeneralPopupTheme } from '../../../common/config/const';
import { setCookie } from '../../config/cookies_action';

const { shared } = window;

function AutoRedemePromoCode({
  loading, autoRedemPromoCode, authHandlerSuccess, justSignedUpAuthHandlerSuccess, bonusPopup, autoRedemSetBonusPopup
}) {
  const history = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const { promo } = toCamelCase(queryString.parse(window.location.search));
    if (promo) {
      const date = new Date();
      date.setTime(date.getTime() + (30 * 60 * 1000));

      setCookie({
        cookieName: 'autoRedemePromoCode',
        cookieValue: promo,
        cookieOptions: {
          path: '/',
          expires: date
        }
      });
    }
  }, []);

  useEffect(() => {
    if (window.currentUserId) {
      autoRedemPromoCode({ history, needShowErrorPoppup: true });
    }
  }, []);

  useEffect(() => {
    if (authHandlerSuccess) {
      autoRedemPromoCode({ history, needShowErrorPoppup: false, authHandlerSuccess });
    }
  }, [authHandlerSuccess]);

  useEffect(() => {
    if (justSignedUpAuthHandlerSuccess) {
      autoRedemPromoCode({ history, needShowErrorPoppup: false, justSignedUpAuthHandlerSuccess });
    }
  }, [justSignedUpAuthHandlerSuccess]);

  useEffect(() => {
    if (loading) {
      hideBodyScroll();
      return () => {
        unlockBodyScroll();
      };
    }
    return () => {};
  }, [loading]);

  const clearBonusPopup = () => {
    autoRedemSetBonusPopup({ bonusPopup: null });
  };

  const renderBonusPopup = () => (
    <AppGeneralPopup visible={ !!bonusPopup } theme={ appGeneralPopupTheme.CENTERED }>
      <div className={ classes.autoRedemePromoCodeBonusPopupContainer }>
        <div className={ classes.autoRedemePromoCodeBonusPopupHeader }>
          <b>{ bonusPopup?.title }</b>
        </div>
        <div className={ classes.autoRedemePromoCodeBonusPopupDescription }>
          { bonusPopup?.description }
        </div>
        <div className={ classes.autoRedemePromoCodeBonusPopupFooter }>
          <button type="button" value="Promo Code" className={ classes.autoRedemePromoCodeBonusPopupOkButton } onClick={ clearBonusPopup }>
            <b>{ t('promoCode.ok') }</b>
          </button>
        </div>
      </div>
    </AppGeneralPopup>
  );

  const renderAutoRedemePromoCode = () => {
    if (loading) {
      return (
        <div className={ classes.generalLoadingOverlayContainer }>
          <Spiner loaderColor={ shared.appColor } className={ classes.generalLoadingOverlayLoaderContainer } />
        </div>
      );
    }
    return renderBonusPopup();
  };

  return renderAutoRedemePromoCode();
}

AutoRedemePromoCode.propTypes = {
  loading: PropTypes.bool,
  authHandlerSuccessAction: PropTypes.func
};

AutoRedemePromoCode.defaultProps = {
  loading: false,
  authHandlerSuccessAction: null
};

export default AutoRedemePromoCode;
