import React from 'react';
import classes from './classes.module.scss';
import { isNotificationSupported } from '../../../actions/firebase_messaging';
import { linkType } from '../../../actions/general_popup';
import { subscribedToAdvisor, isPrerenderMode } from '../../../config/util';
import { trackPageVisit } from '../../../actions/analytics';
import { isWebView } from '../../../../common/config/utils';
import { SvgIcon } from '../../../../common/sprites';

const navigateToRegisterScreen = (displayRegister) => {
  displayRegister({ shouldDisplay: true });
};

const callCallbackOrSetItPending = (authorizedCallback, needToAuthCallback) => (
  window.currentUserId ? authorizedCallback() : needToAuthCallback()
);

export const renderFav = (onFavoriteClick, favorite) => (
  <button
    type="button"
    onClick={ onFavoriteClick }
    className={ classes.iconButton }
    suppressHydrationWarning
  >
    <SvgIcon id={ favorite && window.currentUserId ? 'white_favorite_active' : 'white_favorite_empty' } className={ classes.topIconImgWhite } />
    <SvgIcon id={ favorite && window.currentUserId ? 'black_favorite_active' : 'black_favorite_empty' } className={ classes.topIconImgBlack } />
  </button>
);

export const renderNotify = ({
  onNotifyClick, availabilityNotification, advisorStatus, t
}) => {
  if (advisorStatus === 'online' || !advisorStatus || isPrerenderMode()) return null;
  return (
    <button
      type="button"
      onClick={ onNotifyClick }
      className={ classes.iconButton }
      suppressHydrationWarning
    >
      <SvgIcon id={ subscribedToAdvisor(availabilityNotification) ? 'white_notify_active' : 'white_notify' } className={ classes.topIconImgWhite } />
      <SvgIcon id={ subscribedToAdvisor(availabilityNotification) ? 'black_notify_active' : 'black_notify' } className={ classes.topIconImgBlack } />

      {!isWebView() && (<div className={ classes.notifyMeTooltipContainer } data-tooltip={ t('notifyMe.default_title') } suppressHydrationWarning />) }
    </button>
  );
};

const getClickSource = (location) => {
  if (location.state && location.state.clickSource) return location.state.clickSource;
  return 'advisor profile';
};

export const handleFavoriteClick = (
  e,
  favoriteAdvisor,
  favorite,
  id,
  displayRegister,
  authPendingAction,
  location,
  favoriteLoading
) => {
  if (favoriteLoading) return;
  e.preventDefault();
  const favoriteAction = () => {
    favoriteAdvisor(favorite, id, getClickSource(location), true);
  };

  const authNeedAction = () => {
    authPendingAction(() => {
      if (!favorite) favoriteAction(favorite, id, favoriteAdvisor);
    });
    navigateToRegisterScreen(displayRegister);
  };

  callCallbackOrSetItPending(favoriteAction, authNeedAction);
};

export const handleNotifyClick = (
  e,
  displayNotifyMe,
  id,
  showGeneralPopup,
  authPendingAction,
  displayRegister,
  location,
  needToLoadAdv
) => {
  e.preventDefault();
  const notifyClickAction = () => {
    if (isNotificationSupported()) {
      displayNotifyMe(true, parseInt(id, 10), getClickSource(location), needToLoadAdv);
    } else {
      showGeneralPopup(null, linkType.notificationNotSupported);
      trackPageVisit('service unavailable popup');
    }
  };
  const authNeedAction = () => {
    authPendingAction(() => {
      notifyClickAction(id, displayNotifyMe, showGeneralPopup);
    });
    navigateToRegisterScreen(displayRegister);
  };
  callCallbackOrSetItPending(notifyClickAction, authNeedAction);
};
