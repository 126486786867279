import { connect } from 'react-redux';
import TimerComponent from './timer_component';
import withPGTranslation from '../../../config/withPGTranslation';
import { loadAdvisor } from '../../../actions/advisors';
import { trackSessionHireEventIfNeeded } from '../../../actions/chat';

const mapeStateToProps = ({
  chat: {
    paidDuration,
    freeDuration, state, advisorId, durationCounterDisplay, buyerDurationCounterDirection
  },
  user: { user },
  advisors
}) => {
  const advisor = (advisors || {})[parseInt(advisorId, 10)];
  return ({
    paidDuration,
    freeDuration,
    state,
    advisorXfmProgram: advisor && advisor.xfmProgram,
    userXfmProgram: user && user.xfmProgram,
    advisorId,
    buyerDurationCounterDirection,
    durationCounterDisplay
  });
};

export default connect(
  mapeStateToProps,
  {
    loadAdvisor,
    trackSessionHireEventIfNeeded
  }
)(withPGTranslation(TimerComponent));
