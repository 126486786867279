/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import MenuItem from './menu_item';
import MyOrdersBadge from '../header/my_orders_badge';
import MessageCountBadge from '../header/message_count_badge';
import SpecialitiesMenuItem from './specialities_menu_item';
import classes from './classes.module.scss';
import { appLinks } from '../../config/const';
import HeaderLogo from '../header/header_logo';
import { getCurrentLocalization, DEFAULT_LANGUAGE } from '../../config/i18n';
import { appRoutes } from '../../config/app_routes';
import { isKA, isPGW } from '../../../common/config/utils';
import ChangeLanguage from '../change_language';
import { isActiveRoute } from '../routes/routes_helpers';
import { getRouteSettings } from '../../config/util';
import { SvgIcon } from '../../../common/sprites';

const sideMenuIconNames = {
  home: 'home',
  promo: 'promo',
  blog: 'blog',
  language: 'language',
  cashback: 'cashback',
  support: 'support',
  forAdvisors: 'for_advisors',
  settings: 'settings',
  myOrders: 'my_orders',
  favAdvisors: 'fav_advisors',
  credit: 'credit',
  paymentSources: 'payment_sources',
  sideMenuClosed: 'side_menu_closed',
  horoscope: 'horoscope'
};

const language = getCurrentLocalization();

const getIconToUse = ({ iconName, path, needChekActive }) => {
  let iconToUse = iconName;
  if (needChekActive) {
    const pathActive = isActiveRoute(path);
    iconToUse = pathActive ? `${ iconName }_active` : iconName;
  }
  return iconToUse;
};

export const renderSideMenuIcon = ({
  iconName, path, needChekActive = true,  style = {}
}) => {
  if (isKA()) return null;
  const iconToUse = getIconToUse({ iconName, path, needChekActive });
  return (
    <SvgIcon id={ iconToUse } className={ classes.menuItemIcon } style={ { ...style } } />
  );
};

export const renderChangeLanguageMenuItem = ({ t, showChangeLanguage, toggleChangeLanguagePopup }) => {
  if (isKA()) return null;
  return (
    <>
      <MenuItem
        title={ { text: t('sideMenu.language') } }
        icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.language, path: '/language' }) }
        onClick={ () => toggleChangeLanguagePopup({ show: true }) }
        extraNode={ <div className={ classes.languageSign }>{ language }</div> }
        rightPos
      />
      {createPortal(
        <ChangeLanguage
          show={ showChangeLanguage }
          onClose={ toggleChangeLanguagePopup }
        />,
        document.body
      )}
    </>
  );
};

const renderBlogMenuItem = (t) => (isPGW() ? (
  <MenuItem
    title={ { text: t('sideMenu.blog') } }
    icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.blog, path: appLinks.blog }) }
    asHref
    linkTo={ language === DEFAULT_LANGUAGE ? appLinks.blog : `${ appLinks.blog }/${ language }` }
    openInNewTab
  />
) : (
  <MenuItem
    title={ { text: t('sideMenu.articles') } }
    icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.blog, path: appLinks.articles }) }
    asHref
    linkTo={ appLinks.articles }
    openInNewTab
  />
));

const renderMyHoroscopeMenuItem = (t, userLoggedIn) => (
  <>
    {!userLoggedIn ? <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div> : null}
    <MenuItem
      icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.horoscope, path: appLinks.myHoroscope, needChekActive: false }) }
      title={ { text: t('sideMenu.myHoroscope') } }
      linkTo={ appLinks.myHoroscope }
      asHref
    />
  </>
);

function SideMenu({
  t, isSufficientForCashback, cashbackAmount, shouldShowPayoutMethodMenuItem, toggleDrawer,  visible
}) {
  const userLoggedIn = window.currentUserId;
  const [showChangeLanguage, setShowChangeLanguage] = useState(false);

  const toggleChangeLanguagePopup = ({ show }) => {
    setShowChangeLanguage(show);
  };

  const cashbackItem = () => {
    if (!isPGW()) return null;
    return (
      <MenuItem
        title={ { text: t('sideMenu.cashback'), active: isActiveRoute(appRoutes.cashback) } }
        icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.cashback, path: appRoutes.cashback }) }
        linkTo={ appRoutes.cashback }
        extraNode={ isSufficientForCashback ? (
          <div className={
      isSufficientForCashback ? classes.cashbackActive : classes.cashbackInactive
      }
          >
            { `$${ parseFloat(cashbackAmount).toFixed(2) }` }
          </div>
        ) : null }
      />
    );
  };

  function renderLoggedOutSideMenu() {
    return (
      <div className={ classes.sideMenu }>
        <MenuItem
          title={ { text: t('sideMenu.home'), active: isActiveRoute(appRoutes.home) } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.home, path: appRoutes.home }) }
          linkTo={ appRoutes.home }
        />
        <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div>
        <SpecialitiesMenuItem />
        {renderMyHoroscopeMenuItem(t, userLoggedIn)}
        <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div>
        <MenuItem
          title={ { text: t('sideMenu.apply_promo_code'), active: isActiveRoute(appRoutes.promoCode) } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.promo, path: appRoutes.promoCode }) }
          linkTo={ appRoutes.promoCode }
        />
        <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div>
        {renderBlogMenuItem(t)}
        { renderChangeLanguageMenuItem({ t, showChangeLanguage, toggleChangeLanguagePopup }) }
        <MenuItem
          title={ { text: t('sideMenu.customer_support') } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.support, path: appLinks.customerSupport }) }
          asHref
          openInNewTab
          linkTo= { appLinks.customerSupport }
        />
        <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div>
        <MenuItem
          title={ { text: t('sideMenu.menu_apply_advisor') } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.forAdvisors, path: appLinks.forAdvisors }) }
          linkTo={ appLinks.forAdvisors }
          asHref
          openInNewTab
        />
      </div>
    );
  }

  function renderLoggedInSideMenu() {
    const favoriteRouteSettings = getRouteSettings({ routeKey: appRoutes.favoriteAdvisors });
    return (
      <div className={ classes.sideMenu }>
        <MenuItem
          title={ { text: t('sideMenu.home'), active: isActiveRoute(appRoutes.home) } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.home, path: appRoutes.home }) }
          linkTo={ appRoutes.home }
        />
        <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div>
        <SpecialitiesMenuItem />
        <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div>
        {renderMyHoroscopeMenuItem(t, userLoggedIn)}
        <MenuItem
          title={ { text: t('activity.my_activity'), active: isActiveRoute(appRoutes.myOrders) } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.myOrders, path: appRoutes.myOrders }) }
          linkTo={ appRoutes.myOrders }
          extraNode={ (
            <div className={ classes.badgeContainer }>
              <MyOrdersBadge />
              <MessageCountBadge />
            </div>
      ) }
        />
        <MenuItem
          title={ { text: t('sideMenu.favorite_advisors'), active: isActiveRoute(appRoutes.favoriteAdvisors) } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.favAdvisors, path: appRoutes.favoriteAdvisors }) }
          linkTo={ { pathname: appRoutes.favoriteAdvisors, state: { needRefresh: true, params: favoriteRouteSettings.params } } }
        />
        <MenuItem
          title={ { text: t('sideMenu.add_funds'), active: isActiveRoute(appRoutes.addFunds) } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.credit, path: appRoutes.addFunds }) }
          linkTo={ appRoutes.addFunds }
        />
        { shouldShowPayoutMethodMenuItem ? (
          <MenuItem
            title={ { text:t('sideMenu.payment_methods'), active: isActiveRoute(appRoutes.paymentSources) } }
            icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.paymentSources, path: appRoutes.paymentSources }) }
            linkTo={ appRoutes.paymentSources }
          />
        ) : null}
        {cashbackItem()}
        <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div>
        <MenuItem
          title={ { text: t('sideMenu.apply_promo_code'), active: isActiveRoute(appRoutes.promoCode) } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.promo, path: appRoutes.promoCode }) }
          linkTo={ appRoutes.promoCode }
        />
        <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div>
        {renderBlogMenuItem(t)}
        { renderChangeLanguageMenuItem({ t, showChangeLanguage, toggleChangeLanguagePopup }) }
        <MenuItem
          title={ { text: t('sideMenu.customer_support') } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.support, path: appLinks.customerSupport }) }
          asHref
          openInNewTab
          linkTo= { appLinks.customerSupport }
        />
        <MenuItem
          title={ { text: t('sideMenu.menu_apply_advisor') } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.forAdvisors, path: appLinks.forAdvisors }) }
          linkTo={ appLinks.forAdvisors }
          asHref
          openInNewTab
        />
        <MenuItem
          title={ { text: t('sideMenu.settings'), active: isActiveRoute(appRoutes.settings) } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.settings, path: appRoutes.settings }) }
          linkTo={ appRoutes.settings }
        />
      </div>
    );
  }

  const renderContent = () => (
    <div>
      <div suppressHydrationWarning className={ classes.drawer } style={ visible ? { '--display': 'flex' } : { '--display': 'none' } }>
        <div className={ classes.sideMenuHeaderLogoContainer } suppressHydrationWarning>
          <HeaderLogo toggleDrawer={ toggleDrawer } sideMenuIcon={ sideMenuIconNames.sideMenuClosed } onSideMenu />
        </div>
        { userLoggedIn ? renderLoggedInSideMenu() : renderLoggedOutSideMenu() }
      </div>
      <div suppressHydrationWarning className={ classes.drawerMask } onClick={ toggleDrawer } onKeyDown={ toggleDrawer } style={ visible ? { '--display': 'flex' } : { '--display': 'none' } } />
    </div>
  );

  return renderContent();
}

SideMenu.propTypes = {
  t: PropTypes.func.isRequired,
  isSufficientForCashback: PropTypes.bool,
  cashbackAmount: PropTypes.string,
  shouldShowPayoutMethodMenuItem: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};

SideMenu.defaultProps = {
  isSufficientForCashback: false,
  cashbackAmount: '0.0'
};

export default SideMenu;
