import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import classes from './classes.module.scss';
import  withPGTranslation  from '../../config/withPGTranslation';
import { onHeaderSearchClick, renderItemPropMeta } from '../../config/util';
import { SvgIcon } from '../../../common/sprites';
import { useCurrentPath } from '../routes/hooks/useCurrentPath';
import { useLocalizedNavigate } from '../routes/hooks/useLocalizedNavigate';

function SearchBar({
  t, itemProp
}) {
  const history = useLocalizedNavigate();
  const searchInputRef = useRef();
  const currentPath = useCurrentPath();
  const [searchInputValue, setSearchInputValue] = useState(
    queryString.parse(currentPath.search).query
  );
  const itemPropSearch = (itemProp || {}).search || {};
  const onSearch = () => {
    onHeaderSearchClick({
      searchInputRef, setSearchInputValue, history, currentPath
    });
  };

  const onSearchKeyDown = (e) => {
    if (e.key === 'Enter') onSearch();
  };

  const searchInputValueChange = (e) => {
    setSearchInputValue(e.target.value);
  };

  return (
    <div
      className={ classes.searchContainer }
      itemProp={ itemPropSearch.itemProp }
      itemScope={ itemPropSearch.itemScope }
      itemType={ itemPropSearch.itemType }
    >
      <div className={ classes.searchInput }>
        <div className={ classes.inputSearchIconContainer }>
          <SvgIcon className={ classes.inputSearchIcon } id="gray_search_icon" size={ 21 } />
        </div>
        { renderItemPropMeta(itemPropSearch) }
        <input
          type="text"
          ref={ searchInputRef }
          value={ searchInputValue || '' }
          className={ classes.searchField }
          onChange={ searchInputValueChange }
          onKeyDown={ onSearchKeyDown }
          placeholder={ t('search_placeholder') }
          itemProp={ (itemPropSearch.input || {}).itemProp }
          name={ (itemPropSearch.input || {}).name }
          required={ (itemPropSearch.input || {}).required }
        />
      </div>
      <button type="button" onClick={ onSearch } className={ classes.searchButton }>
        <div className={ classes.searchButtonTitle }>
          {t('search_button_title')}
        </div>
        <SvgIcon className={ classes.searchButtonIcon } id="white_search_icon" />
      </button>
    </div>
  );
}

SearchBar.propTypes = {
  t:PropTypes.func.isRequired,
  itemProp: PropTypes.object
};

SearchBar.defaultProps = {
  itemProp: null
};

export default withPGTranslation(SearchBar);
