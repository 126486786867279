import React from 'react';
import PropTypes from 'prop-types';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '../../config/i18n';
import { useSEOData } from './hooks/useSEOData';
import { useNavigationStackUpdate } from './hooks/useNavigationStackUpdate';
import { useSmartBannerVisibility } from './hooks/useSmartBannerVisibility';
import { appRoutes } from '../../config/app_routes';
import LocalizedNavigate from './localized_navigate';

function LocalizedSwitch({
  children
}) {
  useSEOData();
  useNavigationStackUpdate();
  useSmartBannerVisibility();

  const { t } = useTranslation();

  const localizeRoutePaths = (key, lang) => {
    const route = t(key, { lng: lang });

    const localizedRoutes = [`/${ lang }${ route }`];

    if (lang === DEFAULT_LANGUAGE) localizedRoutes.push(route);

    return localizedRoutes;
  };

  return (
    <Routes>
      {SUPPORTED_LANGUAGES.flatMap(lang => (
        React.Children.map(children, (child) => (React.isValidElement(child)
          ? localizeRoutePaths(child.props.path, lang).map(path => (
            React.cloneElement(child, {
              ...child.props,
              path
            })
          ))
          : child))
      ))}
      <Route path="*" element={ <LocalizedNavigate pathKey={ appRoutes.home } options={ { replace: true } } /> } />
    </Routes>
  );
}

LocalizedSwitch.propTypes = {
  children: PropTypes.node
};

LocalizedSwitch.defaultProps = {
  children: null
};

export default LocalizedSwitch;
