import { matchPath } from 'react-router';
import i18n from '../config/i18n';
import { appRoutes } from '../config/app_routes';

export const getAdvisorSlug = ({ advisorId }) => {
  const { advisors } = window.store.getState();
  const { knownPaths } = advisors;
  const knownPath = Object.keys(knownPaths).find((kp) => knownPaths[kp] === advisorId);
  const pattern = `/:lang?${ i18n.t(appRoutes.advisor) }`;
  const slugMatch = matchPath(pattern, knownPath);
  const advisorSlug = slugMatch?.params?.slug;
  return advisorSlug;
};

export const getAdvisorIdBySlug = ({ advisorSlug }) => {
  const { advisors } = window.store.getState();
  const { knownPaths } = advisors;
  const advisorSlugPath = Object.keys(knownPaths).find((kp) => kp.includes(advisorSlug));
  const advisorId =  knownPaths[advisorSlugPath];
  return advisorId;
};
