export const paymentSourcesTheme = {
  wallet: 'wallet',
  purchase: 'purchase'
};

export const purchaseErrorText = {
  cannotBeUsedOnThisBrowser: 'Cannot be used on this browser',
  paymentMethodCannotBeUsed: 'Payment method cannot be used',
  pleaseReEnterTheCardDetailsForSecurityReasons: 'Please re-enter the card details for security reasons'
};

export const appGeneralPopupTheme = {
  CHAT_ENDED: 'CHAT_ENDED',
  CHAT_ENDED_RATE_REVIEW: 'CHAT_ENDED_RATE_REVIEW',
  DEFAULT: 'DEFAULT',
  AFFILIATE_PROMO: 'AFFILIATE_PROMO',
  INTRO_DATE_PICKER: 'INTRO_DATE_PICKER',
  INTRO: 'INTRO',
  ABSOLUTE_POPUP: 'ABSOLUTE_POPUP',
  SCROLLED_POPUP: 'SCROLLED_POPUP',
  CENTERED: 'CENTERED',
  BOTTOM: 'BOTTOM',
  REGISTER: 'REGISTER',
  JUMIO_POPOVER: 'JUMIO_POPOVER',
  VIDEO_POPOVER: 'VIDEO_POPOVER'
};

export const paymentMethodsListTitleTheme = {
  wallet: 'wallet',
  purchase: 'purchase'
};

export const purchaseDetailsTypes = {
  SIDE_MENU: 'SIDE_MENU',
  CHAT: 'CHAT',
  VOICE_CHAT: 'VOICE_CHAT',
  VIDEO_CHAT: 'VIDEO_CHAT',
  AFFILIATE_PROMO_POPUP: 'AFFILIATE_PROMO_POPUP'
};

// Need for app rezise observer
export const walletHolderId = 'wallet_holder';

export const appThemes = {
  light: 'light',
  dark: 'dark'
};

export const NTYPE = {
  PSYCHICS: 'psychic',
  ORDER_MESSAGE: 'new_order_message'
};

export const purchaseTypeToOrderType = {
  live_chat: 'chat',
  video_call: 'video',
  voice_call: 'voice'
};

export const supportedApplePayVer = '5.4.0';
export const supportedNewPOWalletVersion = '6.0.0';
