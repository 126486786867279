import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { getSEOData } from '../../../actions/application';

export const useSEOData = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getSEOData(location.pathname));
  }, [location.pathname]);
};
