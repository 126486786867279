import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './classes.module.scss';
import { log, LOG_LEVELS } from '../../../common/config/app_logger';
import AppGeneralPopup from '../../../common/app_general_popup';
import { appGeneralPopupTheme } from '../../../common/config/const';
import RadioGroup from '../radio_group';
import DatePicker from '../../../common/date_picker';
import { validateDate } from '../../../common/date_picker/date_picker_utils';
import {
  errorTypes, validateNickname, validateGender, renderPopupTitle,
  renderRadioBtnContent, renderErrorModalContent
} from './intro_utils';
import { trackPageVisit } from '../../actions/analytics';
import Const from '../../config/const';

const getButtonTitle = (chatType, t) => {
  switch (chatType) {
    case Const.chatType.text:
      return t('chat.start_chat');
    case Const.chatType.video:
      return t('chat.start_video_call');
    case Const.chatType.voice:
      return t('chat.start_voice_call');
    default:
      return null;
  }
};

function IntroComponent({
  updateUserBaseInfo, loading, t, error, closeButtonAction, clearErrorAction, chatType
}) {
  const [nickname, setNickName] = useState(null);
  const [gender, setGenderChanged] = useState(null);
  const [birthDate, setBirthDate] = useState(null);
  const [introErrors, setIntroErrors] = useState({});

  const clearError = ({ type }) => {
    if (!type) {
      setIntroErrors(null);
      return;
    }
    const clearedErrorKeys = Object.keys(introErrors).filter((err) => err !== type);
    const clearedError = {};
    clearedErrorKeys.forEach((key) => { clearedError[key] = introErrors[key]; });
    setIntroErrors(clearedError);
  };

  const genderSelectorOnChange = (value) => {
    setGenderChanged(value);
    clearError({ type: errorTypes.gender });
  };

  const renderInputError = ({ type }) => (
    <div className={ classes.errorContainer }>
      { introErrors[type] }
    </div>
  );

  const renderGenderSelector = () => (
    <>
      <RadioGroup
        selectedValue={ gender }
        onChange={ genderSelectorOnChange }
        error={ introErrors[errorTypes.gender] }
      >
        {renderRadioBtnContent({
          name: t('intro.female'), value: 'F', iconId: 'female_icon', iconSelectedId: 'female_selected_icon'
        })}
        {renderRadioBtnContent({
          name: t('intro.male'), value: 'M', iconId: 'male_icon', iconSelectedId: 'male_selected_icon'
        })}
        {renderRadioBtnContent({
          name: t('intro.other'), value: 'O', iconId: 'other_icon', iconSelectedId: 'other_selected_icon'
        })}
      </RadioGroup>
      { renderInputError({ type: errorTypes.gender }) }
    </>
  );
  useEffect(() => {
    trackPageVisit('introduce yourself popup');
  }, []);

  useEffect(() => {
    if (error) setIntroErrors({ ...introErrors, [errorTypes.error]: error.error });
  }, [error]);

  const getBorderColor = ({ type }) => {
    if (introErrors[type]) return { borderColor: '#FF4242' };
    return {};
  };

  const onNickNameChanged = e => {
    log(LOG_LEVELS.VERBOSE, 'General', `on nickname changed ${ e.target.value }`);
    e.preventDefault();
    setNickName(e.target.value);
    clearError({ type: errorTypes.nickname });
  };

  const validateNicknameAction = () => {
    validateNickname({
      nickname, t, introErrors, setIntroErrors
    });
  };

  const onStartBtnClicked = () => {
    log(LOG_LEVELS.INFO, 'General', 'on User info Start Clicked');
    const nicknameErrors = validateNickname({
      nickname, t, introErrors, setIntroErrors, returnErrors: true
    });
    const genderErrors = validateGender({
      gender, t, introErrors, setIntroErrors, returnErrors: true
    });
    const validateDateError = validateDate({ t, date: birthDate });
    const birthDateErrors = validateDateError ? { [errorTypes.birthDate]: validateDateError } : {};

    const errors = { ...nicknameErrors, ...genderErrors, ...birthDateErrors  };
    setIntroErrors(errors);
    if (Object.keys(errors).length > 0) return;
    updateUserBaseInfo({ nickname, gender, dateOfBirth: birthDate });
  };

  const isBtnEnabled = () => !loading;

  const clearShowErrorModal = () => clearError({ type: errorTypes.error });

  const closeIntroPopupAction = () => {
    closeButtonAction();
    clearErrorAction();
    clearError({});
  };

  const setDateOfBirthError = (err) => {
    setIntroErrors({ ...introErrors, [errorTypes.birthDate]: err });
  };

  const clearDateOfBirthError = () => {
    clearError({ type: errorTypes.birthDate });
  };

  const renderDatePicker = () => (
    <>
      <DatePicker
        className={ classes.inputClass }
        t={ t }
        setError={ setDateOfBirthError }
        clearError={ clearDateOfBirthError }
        setDate={ setBirthDate }
        datePickerInputStyle={ { fontSize: '16px', fontWeight: 600, color: '#000000' } }
        datePickerHolderStyle={ { ...getBorderColor({ type: errorTypes.birthDate }), color: '#ABABAB' } }
      />
      { renderInputError({ type: errorTypes.birthDate }) }
    </>
  );

  return (
    <AppGeneralPopup
      theme={ appGeneralPopupTheme.SCROLLED_POPUP }
      customTopHeader={ renderPopupTitle({
        title: t('intro.introduce_yourself'), subTitle: t('intro.our_advisors_provide'), onCloseBtnClick: closeIntroPopupAction, closeIconId: 'close_icon'
      }) }
    >
      <div className={ classes.introScreenContainer }>
        <div className={ classes.introContainer }>
          <div className={ classes.nickNameContainer }>
            <div className={ classes.labelContainer } style={ { paddingTop: 0 } }>
              <div className={ classes.labelText }>{ t('intro.your_nickname') }</div>
            </div>
            <input
              className={ classes.inputClass }
              placeholder={ t('intro.nickname') }
              onChange={ onNickNameChanged }
              onBlur={ validateNicknameAction }
              style={ { ...getBorderColor({ type: errorTypes.nickname }) } }
            />
            { renderInputError({ type: errorTypes.nickname }) }
          </div>
          <div className={ classes.nicknameDescriptionContainer }>
            <span>
              <span className={ classes.nicknameDescriptionText }>{ t('intro.this_will_be_your_user_name') }</span>
              <span className={ classes.nicknameDescriptionBoldText }>{ t('intro.cannot_be_changed_later') }</span>
            </span>
          </div>
          <div className={ classes.labelContainer }>
            <div className={ classes.labelText }>{ t('intro.your_gender') }</div>
          </div>
          <div className={ classes.genderSelectorContainer }>
            {renderGenderSelector()}
          </div>
          <div className={ classes.dateContainer }>
            <div className={ classes.labelContainer }>
              <div className={ classes.labelText }>{ t('intro.date_of_birth') }</div>
            </div>
            { renderDatePicker() }
          </div>
          <div>
            <button
              type="button"
              className={ classNames(
                classes.startBtn,
                isBtnEnabled() ? null : classes.buttonDisabled
              ) }
              onClick={ onStartBtnClicked }
              disabled={ !isBtnEnabled() }
            >
              <span>{ getButtonTitle(chatType, t) }</span>
            </button>
          </div>
        </div>
        <AppGeneralPopup
          visible = { !!introErrors.error }
          theme={ appGeneralPopupTheme.CENTERED }
        >
          { renderErrorModalContent({ introErrors, clearShowErrorModal, t }) }
        </AppGeneralPopup>
      </div>
    </AppGeneralPopup>
  );
}

IntroComponent.propTypes = {
  updateUserBaseInfo: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  user: PropTypes.object,
  t: PropTypes.func.isRequired,
  closeButtonAction: PropTypes.func.isRequired,
  clearErrorAction: PropTypes.func.isRequired,
  chatType: PropTypes.string
};

IntroComponent.defaultProps = {
  error: null,
  user: null,
  chatType: ''
};

export default IntroComponent;
