import { generatePath } from 'react-router';
import i18n, { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '../../../config/i18n';
import { findFullRouteKey, updatePathTrailingSlashes } from '../routes_helpers';

export const useLocalizedPath = (needUpdatePathTrailingSlashes = true) => (lang, key, params = {}) => {
  const matchingRouteKey = i18n.exists(key) ? key : findFullRouteKey(key);
  const matchingRoute = i18n.t(matchingRouteKey, { lng: lang });

  if (!matchingRoute) return null;

  let path = generatePath(matchingRoute, params);
  if (needUpdatePathTrailingSlashes) path = updatePathTrailingSlashes(path);

  if (lang === DEFAULT_LANGUAGE || !SUPPORTED_LANGUAGES.includes(lang)) {
    return path || '/';
  }

  return `/${ lang }${ path }`;
};
