import React from 'react';
import PropTypes from 'prop-types';
import { Footer as FooterOrganism } from '@barges/react';
import classes from './classes.module.scss';
import {
  appLinks, socialLinks
} from '../../../config/const';
import { fromMobileApp, isKA, isPGW } from '../../../../common/config/utils';

import '@barges/scss/lib/title.css';
import '@barges/scss/lib/footer.css';
import { appRoutes, dynamicRoutes, getSpecialitiesRoutes } from '../../../config/app_routes';
import { getCurrentLocalization, DEFAULT_LANGUAGE } from '../../../config/i18n';
import { SvgIcon } from '../../../../common/sprites';
import { useLocalizedPath } from '../../routes/hooks/useLocalizedPath';
import { footerLogo } from '../../../logo/logo';

const socialLinksArray = [{
  href: socialLinks.x,
  key: 1,
  icon: <SvgIcon id="twitter_footer" size={ 28 } />
}, {
  href: socialLinks.fb,
  key: 2,
  icon: <SvgIcon id="fb_footer" size={ 28 } />
}, {
  href: socialLinks.ig,
  key: 3,
  icon: <SvgIcon id="ig_footer" size={ 28 } />
}, {
  href: socialLinks.tt,
  key: 4,
  icon: <SvgIcon id="tiktok_footer" size={ 28 } />
}, {
  href: socialLinks.yt,
  key: 5,
  icon: <SvgIcon id="yt_footer" size={ 28 } />
}];

const getInc = () => {
  if (isKA()) return 'Kasamba Inc.';
  return 'Barges Technologies, Inc.';
};

const onIconTap = () => {
  window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
};

const companyInfo = `${ new Date().getFullYear() } `;

const language = getCurrentLocalization();

const logoSrc = () => <img alt="logo" src={ footerLogo() } />;

const Footer = ({ t }) => {
  const currentLang = getCurrentLocalization();
  const generatePath = useLocalizedPath();

  if (fromMobileApp) return null;

  const specialities = getSpecialitiesRoutes()
    .map(key => ({ name: (dynamicRoutes[key].translations?.[currentLang].name || dynamicRoutes[key].name), key }));

  return (
    <div className={ classes.footerHolder }>
      <div className={ classes.footerContent }>
        <FooterOrganism
          companyInfo={ `© ${ companyInfo }${ getInc() }` }
          logo={ logoSrc() }
          termsLinks={ [
            { name: t('privacy_policy'), path: appLinks.privacyPolicy },
            { name: t('terms_of_use'), path: appLinks.tos },
            { name: t('affiliation'), path: appLinks.affiliation }
          ] }
          logoAction={ onIconTap }
          socialLinks={ socialLinksArray }
          links={ [
            {
              title: t('footerLinks.explore'),
              children: [
                { name: t('footerLinks.advisors'), path: generatePath(currentLang, appRoutes.psychics) },
                ...isKA()
                  ? [
                    { name: t('footerLinks.specialities'), path: generatePath(currentLang, appRoutes.specialities) },
                    { name: t('footerLinks.horoscope'), path: appLinks.myHoroscope }
                  ]
                  : [],
                ...isPGW()
                  ? [{ name: t('footerLinks.our_blog'), path: language === DEFAULT_LANGUAGE ? appLinks.blog : `${ appLinks.blog }/${ language }` }]
                  : [{ name: t('footerLinks.articles'), path: appLinks.articles }]
              ]
            },
            {
              title: t('footerLinks.specialities'),
              children: specialities?.map(({ name, key }) => ({ name, path: generatePath(currentLang, key) })) || []
            },
            {
              title: t('footerLinks.more'),
              children: [
                { name: t('footerLinks.apply_promo_code'), path: generatePath(currentLang, appRoutes.promoCode) },
                { name: t('footerLinks.customer_support'), path: appLinks.customerSupport },
                { name: t('footerLinks.about_us'), path: appLinks.aboutUs },
                { name: t('footerLinks.psychic_apply_here'), path: appLinks.forAdvisors }
              ]
            }
          ] }
        />
      </div>
    </div>
  );
};

Footer.propTypes = {
  t: PropTypes.func.isRequired
};

export default Footer;
