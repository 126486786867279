/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-dynamic-require */
import { FC } from 'react';
import {
  isKA, isPOW, isPS, getAppTheme
} from '../config/utils';
import { appThemes } from '../config/const';

const getProjectPrefix = () => {
  let prefix = 'pg';
  if (isKA()) {
    prefix = 'ka';
  } else if (isPS()) {
    prefix = 'ps';
  } else if (isPOW()) {
    prefix = 'po';
  } else {
    prefix = 'pg';
  }
  return prefix;
};

export const getSpriteUrl = () => {
  const ComponentSVG = require(`./images/${ getProjectPrefix() }/generated_sprites.svg`);
  return ComponentSVG;
};

export const getPurchaseSpriteUrl: FC = () => {
  const theme = getAppTheme() || appThemes.light;
  const ComponentSVG = require(`./images/${ getProjectPrefix() }/generated_payments_${ theme }_sprites`);
  return ComponentSVG;
};

export const loadSVGSprite = async (url: string, containerId: string) => {
  try {
    const response = await fetch(url);
    const svgSprite = await response.text();
    document.getElementById(containerId).innerHTML = svgSprite;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error loading SVG sprite:', error);
  }
};
