import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import classes from './classes.module.scss';
import { SUPPORTED_LANGUAGES, getCurrentLocalization } from '../../config/i18n';
import { log, LOG_LEVELS } from '../../../common/config/app_logger';
import AppGeneralPopup from '../../../common/app_general_popup';
import { SvgIcon } from '../../../common/sprites';
import { appGeneralPopupTheme } from '../../../common/config/const';
import { trackPageVisit } from '../../actions/analytics';
import { appRoutes } from '../../config/app_routes';
import AdvisorNotProvideServicesPopup from './advisor_not_provide_services_popup';
import { useCurrentPath } from '../routes/hooks/useCurrentPath';
import { useLocalizedPath } from '../routes/hooks/useLocalizedPath';
import { getAdvisorIdBySlug } from '../../project_items/helper_functions';

function ChangeLanguage({
  show, onClose, advisors
}) {
  const language = getCurrentLocalization();
  const { t } = useTranslation();
  const [advisorNotProvideServicesPopupData, setAdvisorNotProvideServicesPopupData] = useState({});
  const currentPath = useCurrentPath();
  const generatePath = useLocalizedPath();

  useEffect(() => {
    if (show) trackPageVisit('language setting');
  }, [show]);

  const onCloseCliked = useCallback(() => {
    onClose({ show: false });
  }, []);

  const makeChangeLanguage = ({ target, lang }) => {
    target.disabled = true;
    const redirecPath = generatePath(lang, currentPath.routeKey, currentPath.params);
    log(LOG_LEVELS.INFO, 'General', `On popup language changed from ${ language } to ${ lang }`);
    log(LOG_LEVELS.INFO, 'General', `Redirect from  ${ window.location.pathname } to ${ redirecPath }`);
    window.location.replace(redirecPath);
  };

  const onLanguageChanged = useCallback(e => {
    if (e.target.id !== language) {
      if (currentPath.routeKey !== appRoutes.advisor) {
        makeChangeLanguage({ target: e.target, lang: e.target.id });
        return;
      }
      const advisorId = getAdvisorIdBySlug({ advisorSlug:  currentPath.params.slug });
      const advisorProfileLanguages = advisors[advisorId]?.profileLanguages;
      if (!advisorProfileLanguages.includes(e.target.id)) {
        setAdvisorNotProvideServicesPopupData({ show: true, lang: e.target.id });
        return;
      }
      makeChangeLanguage({ target: e.target, lang: e.target.id });
    }
  }, [currentPath.routeKey, advisors]);

  const renderCloseBtn = () => (
    <div className={ classes.closeBtnContainer }>
      <button type="button" onClick={ onCloseCliked } className={ classes.closeBtn }>
        <SvgIcon id="close_icon" size={ 16 } />
      </button>
    </div>
  );

  const renderContent = () => (
    <div className={ classes.container }>
      <div className={ classes.titleContainer }>
        <b>{t('language.title')}</b>
      </div>
      <div className={ classes.radioBtnContainer }>
        {SUPPORTED_LANGUAGES.map(lang => (
          <button
            key={ lang }
            type="button"
            id={ lang }
            onClick={ onLanguageChanged }
            className={ classnames(
              classes.radioBtnLeft,
              lang === language ? classes.radioBtnSelected : null
            ) }
          >
            {t(`language.${ lang }`)}
          </button>
        ))}
      </div>
    </div>
  );

  const renderChangeLanguage = () => {
    if (!show) return null;
    return (
      <>
        <AppGeneralPopup
          visible={ show && !advisorNotProvideServicesPopupData.show }
          customTopHeader={ renderCloseBtn() }
          closable={ onCloseCliked }
          theme={ appGeneralPopupTheme.CENTERED }
        >
          {renderContent()}
        </AppGeneralPopup>
        <AdvisorNotProvideServicesPopup
          onCloseCliked={ onCloseCliked }
          advisorNotProvideServicesPopupData={ advisorNotProvideServicesPopupData }
          setAdvisorNotProvideServicesPopupData={ setAdvisorNotProvideServicesPopupData }
          makeChangeLanguage={ makeChangeLanguage }
        />
      </>
    );
  };

  return renderChangeLanguage();
}

ChangeLanguage.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
  advisors: PropTypes.object.isRequired
};

ChangeLanguage.defaultProps = {
  show: false,
  onClose: null
};

export default ChangeLanguage;
