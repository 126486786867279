import React from 'react';
import PropTypes from 'prop-types';
import { Button, Title } from '@barges/react';
import classes from '../../classes.module.scss';
import AppLink from '../../../routes/app_link';
import { appRoutes } from '../../../../config/app_routes';
import withPGTranslation from '../../../../config/withPGTranslation';

import '@barges/scss/lib/title.css';
import '@barges/scss/lib/button.css';

function ButtonSection({
  title, t, params
}) {
  return (
    <div className={ classes.allAdvisorsButtonContainer }>
      <div className={ classes.allAdvisorsButtonText }>
        <Title fontStyle="heading-h5" bold>{ t('home_page.all_advisors_button_text_1') }</Title>
        <Title fontStyle="heading-h5">{ t('home_page.all_advisors_button_text_2') }</Title>
      </div>
      <AppLink
        to={ {
          pathname: appRoutes.psychics,
          state: {
            needRefresh: true,
            params
          }
        } }
        className={ classes.allAdvisorsButton }
      >
        <Button type="primary" title={ title } />
      </AppLink>
    </div>
  );
}

ButtonSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  t: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired
};

ButtonSection.defaultProps = {
  id: null,
  title: ''
};

export default withPGTranslation(ButtonSection);
