import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from './classes.module.scss';
import Rating from '../../../advisor_avatar/rating/rating';
import AdvisorAvatar, { advisorThemes } from '../../../advisor_avatar';
import AdvisorModes from '../../../home_page/nested_views/advisor_modes';
import { isXfmProgram } from '../../../../config/util';
import AppLink from '../../../routes/app_link';
import ReadingSinceAndCountItem, { readingSinceAndCountItemThemes } from '../readingSinceAndCountItem';
import ExpandedText from '../../../../../common/helpers/expanded_text';
import { AdvisorModesThemes } from '../../../../config/const';
import { renderFreeMinutesLabel, skeletonOrItem } from '../../../home_page/nested_views/nested_views_utils';

export interface AdvisorRecommendPreviewProps {
  loading:boolean,
  advisor: Advisor.SingleItem,
  analytics: Analytic.Source,
  userXfmProgram: Advisor.UserXfmProgramProps,
  onAnalyticsClick: () => void
}

const getadvisorSlug = (advisorSlug: string) => {
  if (advisorSlug !== undefined) return advisorSlug;
  return '';
};

const renderAdvisorModes = (loading: boolean, advisor: object, text: string) => <AdvisorModes { ...advisor } loading={ loading } text={ text } theme={ AdvisorModesThemes.recommendedAdvisor } />;

const AdvisorRecommendPreview = ({
  advisor, loading, analytics, userXfmProgram, onAnalyticsClick
}: AdvisorRecommendPreviewProps) => {
  const { t } = useTranslation();

  const {
    status, profilePictureUrl, id, yearJoined, readingsCount, slug, nickname, serviceTitle, xfmProgram, rating, isNewAdvisor
  } = advisor;

  const renderAdvisorFreeMinutes = React.useMemo(() => {
    if (!isXfmProgram({ advisorXfmProgram: xfmProgram, userXfmProgram })) return null;
    return renderFreeMinutesLabel(t);
  }, [xfmProgram?.active, userXfmProgram?.credits]);

  const renderAdvisorService = () => (
    <div className={ classes.advisorInfo }>
      <div className={ classes.basicInfoContainer }>
        <div className={ classes.advsiorTitleContainer }>
          <ExpandedText ellipsis customClasses={ { text: classes.advisorNickNameContainer } }>
            <span className={ classes.advisrNickName } suppressHydrationWarning>
              {skeletonOrItem({ item: nickname, skeletonWidth: 270, maxChars: null }) as React.ReactNode}
            </span>
          </ExpandedText>
          <ExpandedText ellipsis customClasses={ { text: classes.advisorServiceTitleContainer } }>
            <span className={ classes.advisorServiceTitle } suppressHydrationWarning>
              {skeletonOrItem({ item: serviceTitle, skeletonWidth: 270, maxChars: null }) as React.ReactNode}
            </span>
          </ExpandedText>
          <ReadingSinceAndCountItem
            readingsCount={ readingsCount }
            yearJoined={ yearJoined }
            theme={ readingSinceAndCountItemThemes.recommendAdvisor }
            loading={ loading }
          />
        </div>
        <div className={ classes.ratingContainer }>
          <Rating rating={ rating } isNewAdvisor={ isNewAdvisor } theme={ AdvisorModesThemes.recommendedAdvisor } />
        </div>
      </div>
      <div className={ classes.bottomSection }>
        { renderAdvisorFreeMinutes }
        {renderAdvisorModes(loading, advisor, t('home_page.from'))}
      </div>
    </div>
  );

  const renderAdvisorContent = () => (
    <AppLink
      to={ {
        pathname: getadvisorSlug(slug),
        state: {  ...analytics, startChatClickSource: (analytics || {}).clickSource || 'busy carousel' }
      } }
      className={ classes.advisorRecommendContainer }
      key={ id }
      onClick={ onAnalyticsClick }
    >
      <div className={ classes.avatarHolder }>
        <AdvisorAvatar
          loading={ loading }
          status={ status }
          profilePictureUrl={ profilePictureUrl }
          theme={ advisorThemes.recommendedCarousel }
        />
      </div>
      { renderAdvisorService() }
    </AppLink>
  );

  return renderAdvisorContent();
};

AdvisorRecommendPreview.propTypes = {
  advisor: PropTypes.object,
  loading: PropTypes.bool,
  analytics: PropTypes.object,
  userXfmProgram: PropTypes.object,
  onAnalyticsClick: PropTypes.func
};

AdvisorRecommendPreview.defaultProps = {
  advisor: null,
  loading: null,
  analytics: null,
  userXfmProgram: {},
  onAnalyticsClick: null
};

export default AdvisorRecommendPreview;
