import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { matchPath } from 'react-router';
import { isKA, isPS } from '../../common/config/utils';

let methodsPath = './localization/pg.tsx';
if (isKA()) {
  methodsPath = './localization/ka.tsx';
} else if (isPS()) {
  methodsPath = './localization/ps.tsx';
}

export const {
  DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES, initResources
} = require(`${ methodsPath }`);

export const routesTranslationKeys = ['routes', 'specificRoutes', 'dynamicRoutes'];

export const getCurrentLocalization = () => {
  const match = matchPath('/:lang?/*', window.location.pathname);
  if (!match) return DEFAULT_LANGUAGE;
  const { params: { lang } } = match;
  if (lang === 'my') return DEFAULT_LANGUAGE;
  if (SUPPORTED_LANGUAGES.includes(lang)) {
    return lang;
  }
  return DEFAULT_LANGUAGE;
};

i18n.use(initReactI18next).init({
  resources: initResources,
  lng: getCurrentLocalization(),
  fallbackLng: DEFAULT_LANGUAGE,
  supportedLngs: SUPPORTED_LANGUAGES
});

export default i18n;
