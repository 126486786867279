import React, { useEffect } from 'react';
import { propertiesNames } from '../../../config/const';

const colors = [
  { name: propertiesNames.appColor, value: '#8F63EC' },
  { name: propertiesNames.backgroundSecondary, value: '#F7F7F7' },
  { name: propertiesNames.liveChatChannels, value: '#7BD137' },
  { name: propertiesNames.voiceCallChannels, value: '#8F63EC' },
  { name: propertiesNames.borderPrimary, value: '#EAEAEA' },
  { name: propertiesNames.appOverlayBlackColor, value: '#272727e6' },
  { name: propertiesNames.smartBannerBackground, value: 'radial-gradient(circle at 50% 50%, #9a6bff, #9a6bff 29%, #622bd8 72%)' },
  { name: propertiesNames.addCreditCardZenModeContainerBackgroundColor, value: '#E9DDED' },
  { name: propertiesNames.noPaymentMethodImgWidth, value: '185px' },
  { name: propertiesNames.connectBtnMaxWidth, value: '200px' },
  { name: propertiesNames.specialitiesShadow, value: 'none' },
  { name: propertiesNames.advisprSpecialitiesMobileDirection, value: 'row' },
  { name: propertiesNames.minPriceTextDisplay, value: 'unset' },
  { name: propertiesNames.durationItemPriceContainerBackgroundColor, value: '#F7F7F7' },
  { name: propertiesNames.recommendedAdvisorPriceFlexDirection, value: 'row' },
  { name: propertiesNames.recommendedAdvisorPriceAlign, value: 'center' },
  { name: propertiesNames.piceTextContainerMarginLeft, value: '13px' },
  { name: propertiesNames.durationItemPriceContainerBorderRadius, value: '8px' },
  { name: propertiesNames.durationItemSelectedPriceContainerBackgroundColor, value: '#FFFFFF' },
  { name: propertiesNames.durationOptionXfmLabelColor, value: '#7BD137' },
  { name: propertiesNames.advisorPreviewXfmLabelBackground, value: '#7FD14A' },
  { name: propertiesNames.advisorPreviewXfmLabelBorderRadius, value: '8px' },
  { name: propertiesNames.advisorBusyColor, value: '#FF5A5A' },

  { name: '--mbw-general-light', value: '#FFFFFF' },
  { name: '--mbw-general-dark', value: '#27292F' },
  { name: '--mbw-background-primary', value: '#FFFFFF' },
  { name: '--mbw-background-secondary', value: '#F7F7F7' },
  { name: '--mbw-text-primary', value: '#121212' },
  { name: '--mbw-text-secondary', value: '#636570' },
  { name: '--mbw-text-tertiary', value: '#FFFFFF' },
  { name: '--mbw-default-border', value: '#EAEAEA' },
  { name: '--mbw-default-shadow-color', value: '#F7F7F7' },
  { name: '--mbw-icon-primary', value: '#121212' },
  { name: '--mbw-icon-secondary', value: '#7C7F89' },
  { name: '--mbw-icon-tertiary', value: '#FFFFFF' },
  { name: '--mbw-button-border-radius', value: '0.625rem' },
  { name: '--mbw-button-primary', value: '#8755F4' },
  { name: '--mbw-button-primary-hover', value: '#A479FF' },
  { name: '--mbw-button-primary-dissabled', value: '#C3C5CA' },
  { name: '--mbw-button-secondary', value: '#121212' },
  { name: '--mbw-button-secondary-hover', value: '#8F63EC' },
  { name: '--mbw-button-secondary-disabled', value: '#c3c5ca' },
  { name: '--mbw-chat-color', value: '#62DD13' },
  { name: '--mbw-voice-color', value: '#8755F4' },
  { name: '--mbw-video-color', value: '#1CB4FF' },
  { name: '--mbw-error-color', value: '#FF5A5A' },
  { name: '--mbw-warning-color', value: '#FF8717' },
  { name: '--mbw-info-color', value: '#8755F4' },
  { name: '--mbw-success-color', value: '#7BD137' },
  { name: '--mbw-muted-color', value: '#9C9C9C' },
  { name: '--mbw-branding-primary', value: '#8755F4' },
  { name: '--mbw-branding-secondary', value: '#FF8717' },
  { name: '--mbw-red-accent-color', value: '#FF5A5A' },
  { name: '--mbw-orange-accent-color', value: '#FF8717' },
  { name: '--mbw-yellow-accent-color', value: '#FFB222' },
  { name: '--mbw-green-accent-color', value: '#62DD13' },
  { name: '--mbw-sky-blue-accent-color', value: '#1CB4FF' },
  { name: '--mbw-blue-accent-color', value: '#4F60FF' },
  { name: '--mbw-purple-accent-color', value: '#8755F4' },
  { name: '--mbw-possitive-accent-color', value: '#62DD13' },
  { name: '--mbw-negative-accent-color', value: '#FF5A5A' },
  { name: '--wbw-marketing-banner-first-color', value: '#B38FFF' },
  { name: '--mbw-marketing-banner-second-color', value: '#8957f5' },
  { name: '--mbw-marketing-banner-rotation', value: '93deg' },
  { name: '--mbw-duration-item-selected-fg-color', value: '#8F63EC' },
  { name: '--mbw-duration-item-selected-bg-color', value: '#8755f41a' },
  { name: '--mbw-select-duration-arrows-color', value: '#8F63EC' },
  { name: '--mbw-duration-item-price-bg-color', value: '#FFFFFF' },
  { name: '--mbw-duration-item-price-fg-color', value: '#121212' },
  { name: '--mbw-duration-item-selected-price-fg-color', value: '#8F63EC' },

  { name: '--app-blue-snap-input-border-radius', value: '6px' },
  { name: '--app-blue-snap-input-border-color', value: 'var(--app-border-primary-color)' },
  { name: '--app-blue-snap-placeholder-text-color', value: 'var(--app-text-secondary)' },
  { name: '--app-blue-snap-input-border-focus-color', value: 'var(--app-color)' },
  { name: '--app-blue-snap-input-error-color', value: 'var(--app-error-red)' },

  { name: '--coupon-bg-color-large-active', value: 'linear-gradient(90deg, #8755f4 -3.11%, #fc8d3c 100%)' },
  { name: '--mbw-duration-item-row-gap-lg', value: '0rem' },

  { name: '--app-default-btn-lg-border-radius', value: '0.5rem' },
  { name: '--app-default-btn-md-border-radius', value: '0.375rem' },
  { name: '--app-default-btn-sm-border-radius', value: '0.25rem' },
  { name: '--app-default-btn-xs-border-radius', value: '0.125rem' },
  { name: '--app-online-indicator-color', value: '#73C940' },
  { name: '--app-busy-indicator-color', value: '#FF5050' },
  { name: '--app-offline-indicator-color', value: '#BBBBBB' },
  { name: '--app-badge-border-radius', value: '0.375rem' },
  { name: '--app-payment-sources-border-radius', value: '0.938rem' },
  { name: '--app-busy-label-color', value: '#FD4848' },
  { name: '--app-positive-feedback-btn-color', value: '#58be00' },
  { name: '--app-negative-feedback-btn-color', value: '#ff5050' },
  { name: '--app-new-advisor-badge-color', value: '#7FD14A' },
  { name: '--app-filter-switcher-color', value: '#7FD14A' },
  { name: '--app-personal-section-border-color', value: '#73C940' },
  { name: '--app-sale-price-text-color', value: '#62C124' },
  { name: '--app-rating-bg-color', value: '#FFECBC' },
  { name: '--app-rating-on-img-bg-color', value: '#FFECBC' },
  { name: '--mbw-font-family', value: '"Poppins"' }
];

const MBWColors = () => {
  useEffect(() => {
    colors.forEach((prop) => document.documentElement.style.setProperty(prop.name, prop.value));
  }, []);
  return (<div />);
};
export default MBWColors;
