/* eslint-disable no-console */
import React from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18n from './config/i18n';
import './shared';
import { buildStore } from './config/store';
import RoutesComponent from './components/routes';

import 'react-loading-skeleton/dist/skeleton.css';

import './main.css';

import ContextRenderer from './project_items/context_renderer';

const store = buildStore();

function App() {
  return (
    <I18nextProvider i18n={ i18n }>
      <ContextRenderer componentName="mbw_colors" />
      <Provider store={ store }>
        <RoutesComponent />
      </Provider>
    </I18nextProvider>
  );
}
export default App;
