import React from 'react';
import PropTypes from 'prop-types';
import CallAnimation from '../chat_overlay/outgoin_call/call_animation';
import classes from './classes.module.scss';
import VoipControls from './voip_controls';
import { renderNameAndDiscountLabel } from './voip_utils';
import Const from '../../config/const';
import { getModeColor } from '../../config/util';

function VoiceOverlay({
  profilePictureUrl, blinkSetup, callDurationNow, discountData, t, voipType, name
}) {
  return (
    <div className={ classes.voiceContainer }>
      <img
        className={ classes.backgroundImage }
        src={ profilePictureUrl }
        alt=""
      />
      <div className={ classes.voiceOverlay } />
      <div className={ classes.callAnimation }>
        <CallAnimation
          pictureSrc={ profilePictureUrl }
          borderColor={ getModeColor(Const.chatTypeToModeName[voipType]) }
        />
        { renderNameAndDiscountLabel({
          voipType, discountData, name, t
        }) }
      </div>
      <VoipControls
        blinkSetup={ blinkSetup }
        callDurationNow={ callDurationNow }
      />
    </div>
  );
}

VoiceOverlay.propTypes = {
  profilePictureUrl: PropTypes.string.isRequired,
  blinkSetup: PropTypes.bool.isRequired,
  callDurationNow: PropTypes.number,
  discountData: PropTypes.object,
  t: PropTypes.func.isRequired,
  voipType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

VoiceOverlay.defaultProps = {
  callDurationNow: null,
  discountData: null
};

export default VoiceOverlay;
