/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import queryString from 'query-string';
import { Trans } from 'react-i18next';
import classes from './classes.module.scss';
import {
  numberOfReviewsFilters, filtersAllowedParams, readingViaFilters
} from '../../../../config/const';
import  { paramOrNul } from '../../../../../common/config/utils';
import { SvgIcon } from '../../../../../common/sprites';

export const buildQueryString = ({
  readingVia, numberOfReviews, ppmRange, includeOffline, query, historySearch
}) => {
  const parsedHistorySearch = queryString.parse(historySearch);
  let searchWithoutFilters = { };
  Object.keys(parsedHistorySearch).filter((el) => !filtersAllowedParams.includes(el))
    .forEach((el) => {
      searchWithoutFilters = { ...searchWithoutFilters, [el]: parsedHistorySearch[el] };
    });
  return queryString.stringify({
    ...(readingViaFilters[readingVia] === readingViaFilters.default ? null : { reading_via:readingVia }),
    ...(numberOfReviewsFilters[numberOfReviews] === numberOfReviewsFilters['0'] ? null : { min_number_of_reviews_ge:numberOfReviews }),
    ...(ppmRange[0] === window.config.filterPricePerMinute.min ? null : { ppm_ge: ppmRange[0] }),
    ...(ppmRange[1] === window.config.filterPricePerMinute.max ? null : { ppm_le: ppmRange[1] }),
    ...(includeOffline ? null : { include_offline: includeOffline }),
    ...paramOrNul('query', query),
    ...searchWithoutFilters
  });
};

export const getInitialState = (state) => {
  const {
    include_offline, min_number_of_reviews_ge, ppm_ge, ppm_le, reading_via, query
  } = queryString.parse(window.location.search);
  const { min, max } = window.config.filterPricePerMinute;
  const states = {
    readingVia: reading_via || 'default',
    numberOfReviews: min_number_of_reviews_ge || '0',
    ppmRange: [ppm_ge || min, ppm_le || max],
    includeOffline: include_offline !== 'false',
    searchQuery: query
  };
  return states[state];
};

const renderAffiliatFilterViewBullet = (iconId, localizationKey) => (
  <div className={ classes.newToPGSectionMarksContainer }>
    <div className={ classes.newToPGSectionMarkContainer }>
      <SvgIcon className={ classes.newToPGSectionMarkImg } id={ iconId } />
      <div className={ classes.bullet } />
      <div className={ classes.newToPGSectionMarkTextContainer }>
        <Trans
          i18nKey={ localizationKey }
          components={ {
            bold:<b className={ classes.boldText } />,
            normal:<span className={ classes.normalText } />
          } }
        />
      </div>
    </div>
  </div>
);

export const renderViewForNewAffiliateUser = ({
  showFilterPopover, title, t
}) => (
  <div className={ classes.viewForNewAffiliateUser }>
    <Trans
      i18nKey={ title }
      components={ {
        bold:<b className={ classes.boldText } />,
        normal:<span className={ classes.normalText } />
      } }
    />
    <div className={ classes.newToPGSectionMarksContainer }>
      {renderAffiliatFilterViewBullet('filter_to_new_browse', t('search_results.browse_and_find'))}
      {renderAffiliatFilterViewBullet('filter_to_new_account', t('search_results.create_an_account'))}
      {renderAffiliatFilterViewBullet('filter_to_new_connect', t('search_results.select_chat_duration_and'))}
    </div>
    <div key="short_view" className={ classes.filterButtonContainer }>
      <button type="button" className={ classes.displayFilterButton } onClick={ showFilterPopover }>
        {t('search_results.need_help')}
          &nbsp;
        <span className={ classes.clickHereBtn }>{t('search_results.click_here')}</span>
      </button>
    </div>
  </div>
);

export const renderFiltersMobileView = ({
  popoverView, showFilterPopover, searchIconTap, t,
  eligibleForNewAffiliateUserView
}) => {
  if (popoverView) return null;
  const title = t('app_search_results.new_to_purple_garden');
  return eligibleForNewAffiliateUserView ? (
    <div className={ classes.mobileViewForNewAffiliateUser }>
      {renderViewForNewAffiliateUser({ showFilterPopover, title, t })}
    </div>
  ) : (
    <div key="short_view" className={ classes.filterButtonContainer }>
      <button type="button" className={ classes.displayFilterButton } onClick={ showFilterPopover }>
        <SvgIcon id="filter_icon" className={ classes.displayFilterButtonImg } />
        { t('home_page.filters') }
      </button>
      <button key="search_button" type="button" className={ classes.searchButton } onClick={ searchIconTap }>
        <SvgIcon id="black_search_icon" className={ classes.searchIconImg } />
      </button>
    </div>
  );
};

export const searchSection = ({
  popoverView, t, searchQuery,  onSearchChanged, autofocus, onPopoverSearchInputKeyDown
}) => {
  if (!popoverView) return null;
  return [
    <span key="title" className={ classes.searchTitle }>
      { t('home_page.search_and_filters') }
    </span>,
    <div key="container" className={ classes.filterSearchContainer }>
      <SvgIcon id="black_search_icon" className={ classes.searchIcon } />
      <input value={ searchQuery || '' } onChange={ onSearchChanged } type="text" className={ classes.input } autoFocus={ autofocus } onKeyDown={ onPopoverSearchInputKeyDown } />
    </div>
  ];
};

export const renderCancelBtn = (showFilters, eligibleForNewAffiliateUserView, popoverView, closeFilters) => (
  showFilters && eligibleForNewAffiliateUserView && !popoverView  ? (
    <button type="button" className={ classes.closeButton } onClick={ closeFilters }>
      <SvgIcon id="circle_close_icon" />
    </button>
  ) : null
);

export const getFilterSwitcherId = ({ popoverView }) => {
  if (popoverView) return 'filterSwitcherPopover';
  return 'filterSwitcher';
};
