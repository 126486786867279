/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  handleFavoriteClick, handleNotifyClick, renderNotify, renderFav
} from './top_icons_utils';
import { AdvisorPropType, AdvisorDefaultProps } from '../../../reducers/advisor_prop_types';
import classes from './classes.module.scss';
import { isWebView } from '../../../../common/config/utils';
import { SvgIcon } from '../../../../common/sprites';

function TopIcons({
  id, favoriteAdvisor, favorite, t,
  displayRegister, authPendingAction, displayNotifyMe, showGeneralPopup,
  loader, theme, favoriteLoading, availabilityNotification, advisorStatus
}) {
  const location = useLocation();
  const onFavClick = (e) => {
    handleFavoriteClick(e, favoriteAdvisor, favorite, id, displayRegister, authPendingAction, location, favoriteLoading);
  };

  const onNotifyClick = (e) => {
    handleNotifyClick(e, displayNotifyMe, id, showGeneralPopup, authPendingAction, displayRegister, location);
  };

  const onShare = () => {
    navigator.share({
      text: t('advisor_profile.share_message'),
      url: window.location.href
    });
  };

  const shareTopIcon = () => (
    <button
      type="button"
      onClick={ onShare }
      className={ classes.iconButton }
    >
      <SvgIcon id="black_share" className={ classes.topIconImgBlack } />
      <SvgIcon id="white_share" className={ classes.topIconImgWhite } />
    </button>
  );

  if (loader) return null;

  return (
    <div className={ classes[theme] }>
      <div className={ classes.iconsSectionContainer } suppressHydrationWarning>
        {!isWebView() && renderNotify({
          onNotifyClick, availabilityNotification, advisorStatus, t
        }) }
        { renderFav(onFavClick, favorite) }
        { navigator.share ? shareTopIcon() : null }
      </div>
    </div>
  );
}

TopIcons.propTypes = {
  ...AdvisorPropType,
  showBagde: PropTypes.bool,
  needTrackProfileVideoEvent: PropTypes.bool,
  favoriteAdvisor: PropTypes.func.isRequired,
  authPendingAction: PropTypes.func.isRequired,
  displayRegister: PropTypes.func.isRequired,
  onNotifyClick: PropTypes.func,
  displayNotifyMe: PropTypes.func.isRequired,
  showGeneralPopup: PropTypes.func,
  loader: PropTypes.bool,
  showPopover: PropTypes.func,
  showSharePopup: PropTypes.func,
  advisorShareLink: PropTypes.string.isRequired,
  theme: PropTypes.string,
  t: PropTypes.func.isRequired,
  favoriteLoading: PropTypes.bool.isRequired,
  advisorStatus: PropTypes.string
};

TopIcons.defaultProps = {
  ...AdvisorDefaultProps,
  showBagde: false,
  needTrackProfileVideoEvent: false,
  showGeneralPopup: null,
  loader: false,
  showPopover: null,
  onNotifyClick: null,
  theme: null,
  advisorStatus: null
};

export default TopIcons;
