/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { authPendingAction, displayRegister } from '../../actions/auth';
import { useCurrentPath } from './hooks/useCurrentPath';
import { useLocalizedNavigate } from './hooks/useLocalizedNavigate';

export const ProtectedRoute = ({ user, children }) => {
  const navigate = useLocalizedNavigate();
  const currentPath = useCurrentPath();

  useEffect(() => {
    if (!window.currentUserId && !user) {
      window.store.dispatch(displayRegister({ shouldDisplay: true }));
      window.store.dispatch(authPendingAction(() => {
        navigate(currentPath.routeKey, { params: { search: currentPath.search, ...currentPath.params } });
        navigate(0);
      }));
    }
  }, [user]);

  if (!window.currentUserId && !user) return <></>;

  return children;
};

ProtectedRoute.propTypes = {
  user: PropTypes.object,
  children: PropTypes.node.isRequired
};

ProtectedRoute.defaultProps = {
  user: null
};
