/* eslint-disable max-len */
import React from 'react';
import classes from '../classes.module.scss';

export const rightArrow = (
  <svg width="8" height="19" viewBox="0 0 8 19" fill="none" xmlns="http://www.w3.org/2000/svg" className={ classes.arrowImg }>
    <g filter="url(#filter0_d_1030_12131)">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.435302 0.206639C-0.0367289 0.557462 -0.141089 1.23291 0.202205 1.71529L5.3865 9L0.202205 16.2847C-0.141089 16.7671 -0.0367289 17.4425 0.435302 17.7934C0.907332 18.1442 1.56828 18.0375 1.91158 17.5552L8 9L1.91158 0.444847C1.56828 -0.0375337 0.907332 -0.144183 0.435302 0.206639Z" fill="#531FA5" />
    </g>
    <defs>
      <filter id="filter0_d_1030_12131" x="-4.76837e-07" y="0" width="8" height="19" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1030_12131" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1030_12131" result="shape" />
      </filter>
    </defs>
  </svg>
);
