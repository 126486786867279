import { connect } from 'react-redux';
import AutoRedemePromoCode from './auto_redeme_promo_code';
import { autoRedemPromoCode, autoRedemSetBonusPopup } from '../../actions/promo_code';

const mapStateToProps = ({
  promoCode
}) => ({
  loading: promoCode.autoRedem?.loading,
  authHandlerSuccess: promoCode.autoRedem?.authHandlerSuccess,
  justSignedUpAuthHandlerSuccess: promoCode.autoRedem?.justSignedUpAuthHandlerSuccess,
  bonusPopup: promoCode.autoRedem?.bonusPopup
});

export default connect(
  mapStateToProps,
  {
    autoRedemPromoCode,
    autoRedemSetBonusPopup
  }
)(AutoRedemePromoCode);
