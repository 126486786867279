import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import { SvgIcon } from '../../../../common/sprites';

const renderMessages = count => (
  <div className={ classes.messageContainer }>
    <SvgIcon size={ 18 } className={ classes.messageImage } id="message_count_badge_icon" />
    { count }
  </div>
);

function MessageCountBadge({ count }) {
  return <div>{count > 0 ? renderMessages(count) : null}</div>;
}

MessageCountBadge.propTypes = {
  count: PropTypes.number
};

MessageCountBadge.defaultProps = {
  count: 0
};

export default MessageCountBadge;
