import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DurationItem } from '@barges/react';
import { useTranslation } from 'react-i18next';
import '@barges/scss/lib/duration-item.css';
import '@barges/scss/lib/title.css';
import classes from './classes.module.scss';
import { isXfmProgram, isPayg, getXfmFreeSetupMin } from '../../../../config/util';
import { renderDurationItemDiscountLabel, renderDurationItemPriceContainer } from './duration_selector_utils';
import { convertStringToCamelCase } from '../../../../../common/config/utils';
import Const from '../../../../config/const';
import { freeMinIcon } from '../../../advisor/img/free_min_icon';
import { SvgIcon } from '../../../../../common/sprites';

const { shared } = window;

const renderAdditionalOptionText = (additional, duration, selected, t) => (additional && duration === selected.duration) && t('chat.tap_hero_to_use_your_balance');

const renderMostPopularHeartInCircle = (mostPopular) => {
  if (!mostPopular) return null;
  return (
    <div className={ classes.mostPopularHeartInCircleContainer }>
      <SvgIcon id="heart_in_circle" className={ classes.mostPopularHeartInCircleIcon } size={ 40 } />
    </div>
  );
};

const renderFreeMinText = (freeMin, advisorXfmProgram, userXfmProgram, t) => {
  if (!freeMin || !isXfmProgram({ advisorXfmProgram, userXfmProgram  })) return null;
  const freeMinText = t('advisor_profile.plus_free_min', { amount: getXfmFreeSetupMin() });
  return (
    <div className={ classes.freeMinLabelContainer }>
      <div className={ classes.freeMinIcon }>{ freeMinIcon({ color: shared.xfmLabelColor }) }</div>
      <div className={ classes.freeMinTextContainer }>{ freeMinText }</div>
    </div>
  );
};

const renderDurationItemMinutesCount = (duration) => {
  let minutesCount = parseInt(duration / 60, 10);
  if (isPayg({ duration })) minutesCount = '∞'; // &#8734;
  return minutesCount;
};

const renderDurationItemSecondsCount = (duration, t) => {
  if (isPayg({ duration })) return null;
  const minutes = parseInt(duration / 60, 10);
  const letfSeconds = (duration - minutes * 60);
  return letfSeconds && `${ letfSeconds } ${ t('chat.seconds') }`;
};

const renderDurationItemMinutesText = (duration, texts, titleKey, subtitleKey, t) => {
  let text = t('chat.minutes');
  if (isPayg({ duration })) {
    text = (
      <div className={ classes.durationItemZenModePayContainer }>
        <div className={ classes.durationItemZenModeText }>
          {titleKey && texts[convertStringToCamelCase(titleKey)]}
        </div>
        <div className={ classes.durationItemPayAsYouGoText }>
          {subtitleKey && texts[convertStringToCamelCase(subtitleKey)]}
        </div>
      </div>
    );
  }
  return (
    <div className={ classes.durationItemMinutesTextContainer }>
      { text }
    </div>
  );
};

const renderBottomContainer = ({
  duration, discountData, selected, selectDurationKind, t
}) => {
  if (duration !== selected.duration) return null;
  if (selectDurationKind === Const.selectDurationKind.newUser) return null;
  return renderDurationItemDiscountLabel({ discountData, t });
};

function DurationItemComponent({
  duration, price, mostPopular, onClickAction, disabledStartChatButton, freeMin,
  isNewUser, advisorXfmProgram, userXfmProgram, selected, additional, titleKey, subtitleKey, texts, discountData, priceWithoutDiscount, selectDurationKind, availableSalesPrice, advisorModePricePerMinute
}) {
  const { t } = useTranslation();
  const onClick = () => {
    if (disabledStartChatButton) return;
    onClickAction();
  };

  const onKeyDown = () => {};

  const renderDurationItem = () => (
    <div
      className={ classNames(classes.durationItem) }
      onClick={ onClick }
      onKeyDown={ onKeyDown }
    >
      <DurationItem
        key={ `DurationItem_duration_${ duration }_price_${ price }_priceWithoutDiscount_${ priceWithoutDiscount }` }
        selected={ duration === selected.duration }
        duration={ renderDurationItemMinutesCount(duration) }
        durationText={ renderDurationItemMinutesText(duration, texts, titleKey, subtitleKey, t) }
        durationSubText={ renderFreeMinText(freeMin, advisorXfmProgram, userXfmProgram, t) }
        priceContainer={ renderDurationItemPriceContainer({
          price, priceWithoutDiscount, isNewUser, additional, duration, availableSalesPrice, advisorModePricePerMinute
        }) }
        mostPopular={ renderMostPopularHeartInCircle(mostPopular) }
        isNewUser={ isNewUser }
        useYourBalanceText={ renderAdditionalOptionText(additional, duration, selected, t) }
        secondsText= { renderDurationItemSecondsCount(duration, t) }
        bottomContainer={ renderBottomContainer({
          duration, discountData, selected, selectDurationKind, t
        }) }
      />
    </div>
  );

  return renderDurationItem();
}

DurationItemComponent.propTypes = {
  duration: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  onClickAction: PropTypes.func.isRequired,
  disabledStartChatButton: PropTypes.bool.isRequired,
  mostPopular: PropTypes.bool,
  freeMin: PropTypes.number,
  isNewUser: PropTypes.bool.isRequired,
  advisorXfmProgram: PropTypes.object,
  userXfmProgram: PropTypes.object,
  selected: PropTypes.object,
  additional: PropTypes.bool,
  texts: PropTypes.object,
  titleKey: PropTypes.string,
  subtitleKey: PropTypes.string,
  discountData: PropTypes.object,
  priceWithoutDiscount: PropTypes.number,
  selectDurationKind: PropTypes.string,
  availableSalesPrice: PropTypes.number,
  advisorModePricePerMinute: PropTypes.string.isRequired
};

DurationItemComponent.defaultProps = {
  mostPopular: null,
  freeMin: null,
  advisorXfmProgram: {},
  userXfmProgram: {},
  selected: {},
  additional: false,
  texts: {},
  titleKey: null,
  subtitleKey: null,
  discountData: null,
  priceWithoutDiscount: null,
  selectDurationKind: null,
  availableSalesPrice: null
};

export default DurationItemComponent;
