import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import AppGeneralPopup from '../../../common/app_general_popup';
import { appGeneralPopupTheme, NTYPE } from '../../../common/config/const';
import { refreshReducersFromResponse } from '../../config/api_functions';
import { appRoutes } from '../../config/app_routes';
import { getMyAccountBadgesApi } from '../../config/api';
import { useLocalizedNavigate } from '../routes/hooks/useLocalizedNavigate';
import { useCurrentPath } from '../routes/hooks/useCurrentPath';

const renderNotificationTitle = (title) => (title ? (
  <div className={ classes.pushNotificationTitleContainer }>
    { title }
  </div>
) : null);

function PushNotification({
  notification, t, loadHomePage, loadAdvisor, needToHandle, clearPN, userbadgeCounters, advisors
}) {
  const history = useLocalizedNavigate();
  const currentPath = useCurrentPath();

  const [modalVisible, setModalVisible] = useState(false);

  const handlePsychicNotification = (onOpen) => {
    const { advisorId, advisorSeoName } = notification;
    if (onOpen) {
      clearPN();
      history(appRoutes.advisor, { params: { slug: advisorSeoName } });
      history(0);
      return;
    }

    if (parseInt(currentPath?.params?.slug, 10) === advisorId) {
      loadAdvisor(advisorSeoName);
      clearPN();
      return;
    }
    loadHomePage(true);
    setModalVisible(true);
  };

  const updateUserUnreadMessagesCountTotal = () => {
    const updatedUser = { badgeCounters: { ...userbadgeCounters, unreadMessagesCountTotal: userbadgeCounters.unreadMessagesCountTotal + 1 } };
    refreshReducersFromResponse({ user: updatedUser });
  };

  const updateUserActivityUnreadMessagesCount = () => {
    const { advisorId } = notification;
    const updatedAdvisor = {
      id: advisorId,
      liveModes: advisors[advisorId].liveModes,
      unreadMessagesCount: advisors[advisorId].unreadMessagesCount + 1
    };
    refreshReducersFromResponse({ advisor: updatedAdvisor });
  };

  const handleOrderMessageNotification = (onOpen) => {
    const { senderUserId, ntype, advisorId } = notification;
    getMyAccountBadgesApi({ advisorId }); // Need for update coupon label
    if (onOpen) {
      clearPN();
      history(appRoutes.advisorOrders, { params: { id: senderUserId } });
      history(0);
      return;
    }

    if (currentPath?.params?.advisorId === senderUserId) {
      return;
    }

    updateUserActivityUnreadMessagesCount();
    updateUserUnreadMessagesCountTotal();

    if (currentPath.routeKey === appRoutes.myOrders) {
      return;
    }

    if (ntype !== NTYPE.ORDER_MESSAGE) {
      setModalVisible(true);
    }
  };

  const handleNotification = (onOpen = true) => {
    const { ntype } = notification;
    switch (ntype) {
      case NTYPE.PSYCHICS: {
        handlePsychicNotification(onOpen);
        break;
      }
      case NTYPE.ORDER_MESSAGE: {
        handleOrderMessageNotification(onOpen);
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (notification && needToHandle) {
      handleNotification(false);
    }
  }, [notification]);

  if (!notification) return null;

  const renderContent = () => (
    <div className={ classes.pushNotificationContentContainer }>
      {renderNotificationTitle(notification.title)}
      <div className={ classes.pushNotificationTextContainer }>
        { notification.message || '' }
      </div>
      <div className={ classes.pushNotificationButtonsContainer }>
        <div>
          <button type="button" onClick={ clearPN } className={ classes.pushNotificationCancelBtn }>
            { t('push.cancel') }
          </button>
        </div>
        <div>
          <button type="button" onClick={ handleNotification } className={ classes.pushNotificationOkBtn }>
            { t('push.open') }
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <AppGeneralPopup
      visible={ modalVisible && !!notification }
      theme={ appGeneralPopupTheme.CENTERED }
    >
      {renderContent()}
    </AppGeneralPopup>
  );
}

PushNotification.propTypes = {
  notification: PropTypes.object,
  t: PropTypes.func.isRequired,
  loadHomePage: PropTypes.func.isRequired,
  loadAdvisor: PropTypes.func.isRequired,
  needToHandle: PropTypes.bool.isRequired,
  clearPN: PropTypes.func.isRequired,
  loadOrderDetails:PropTypes.func.isRequired,
  userbadgeCounters: PropTypes.object,
  advisors: PropTypes.object
};

PushNotification.defaultProps = {
  notification: null,
  userbadgeCounters: null,
  advisors: null
};

export default PushNotification;
