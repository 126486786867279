import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-dom-last-location';
import Advisor from './advisor';
import { loadAdvisor, getSimilarOnlineAdvisors, trackBusyCarouselEvent } from '../../actions/advisors';
import { trackBEvent } from '../../actions/b_events';
import { trackAdvisorProfileViewEvent } from '../../actions/analytics';
import { startChat } from '../../actions/chat';
import { initVoipChat } from '../../actions/voip_chat';
import { showGeneralPopup } from '../../actions/general_popup';
import { displayNotifyMe } from '../../actions/notify_me';
import { favoriteAdvisor } from '../../actions/favorite_advisors';
import { authPendingAction, displayRegister } from '../../actions/auth';
import withPGTranslation from '../../config/withPGTranslation';
import { matchPaths } from '../../../common/config/utils';
import { getAdvisorDataIfSsrMode } from '../../config/util';

const mapStateToProps = (
  {
    advisors, applicationError, user: { loading: userLoading, user }
  },
  ownProps
) => {
  const { match } = ownProps;
  const { pathname } = match;
  const knownPaths = ((advisors || {}).knownPaths || {});
  const matchPathname = matchPaths({ pathname: Object.keys(knownPaths), path: pathname });
  const id = knownPaths[matchPathname?.pattern?.path];
  const advisor = (advisors || {})[id];
  return {
    ...(advisor || {}),
    ...applicationError,
    advisorLoaded: !!advisor,
    userId: user && user.id,
    userLoading,
    loading: (advisors.loading || !advisor) && !getAdvisorDataIfSsrMode()
  };
};

export default connect(
  mapStateToProps,
  {
    loadAdvisor,
    startChat,
    showGeneralPopup,
    trackAdvisorProfileViewEvent,
    initVoipChat,
    displayNotifyMe,
    favoriteAdvisor,
    authPendingAction,
    displayRegister,
    trackBEvent,
    getSimilarOnlineAdvisors,
    trackBusyCarouselEvent
  }
)(withLastLocation(withPGTranslation(Advisor)));
