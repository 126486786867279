/* eslint-disable no-empty */
import { useEffect, useRef } from 'react';
import snakeCase from 'lodash.snakecase';
import camelCase from 'lodash.camelcase';
import lowerCase from 'lodash.lowercase';
import queryString from 'query-string';
import  semver  from 'semver';
import { browserName } from 'react-device-detect';
import {
  differenceInDays, differenceInYears, getHours, getDay, getMonth, getYear, getDate, differenceInMilliseconds, addMilliseconds
} from 'date-fns';
import { matchPath } from 'react-router-dom';
import { purchaseDetailsTypes, appThemes } from './const';

export const hideBodyScroll = () => {
  const invisibleScrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
  document.documentElement.style.setProperty(
    '--invisible-scrollbar-width',
    `${ invisibleScrollbarWidth }px`
  );
  document.body.style.setProperty('overflow', 'hidden');
  document.body.style.setProperty('-webkit-overflow-scrolling', 'touch');
};

export const unlockBodyScroll = () => {
  document.body.style.setProperty('overflow', 'unset');
  document.documentElement.style.setProperty('--invisible-scrollbar-width', '0px');
};

export const paramOrNul = (key, value) => (value || value === undefined || value === 0 ? { [key]: value } : null);

export const mobileClickSource = (queryString.parse(window.location.search) || {}).purchase_screen || (queryString.parse(window.location.search) || {}).click_source;

export const clickSourceOrMobileClickSource = (clickSource) => clickSource || mobileClickSource;

export const getStartChatClickSource = (location) => {
  const startChatClickSource = location?.state && location?.state?.startChatClickSource ? location?.state?.startChatClickSource : 'direct';
  return startChatClickSource;
};

export const isMobile = () => parseInt(getComputedStyle(document.documentElement).getPropertyValue('--window-width'), 10) <= window.shared.phoneOnlyUpSize;

const clientPlatforms = {
  pgapp: 'pgapp',
  poapp: 'poapp',
  kasamba: 'kasamba',
  psapp: 'psapp'
};

const fromMobileAppHandler = () => {
  const search = (queryString.parse(window.location.search) || {});
  if (search.pg_client === clientPlatforms.pgapp) return true;
  if (Object.keys(clientPlatforms).map(k => clientPlatforms[k]).includes(search.client_platform)) return true;
  return false;
};

export const fromMobileApp = fromMobileAppHandler();

export const isOldMobileApp = (queryString.parse(window.location.search) || {}).pg_client === 'pgapp';

export const getClientPlatform = () => {
  const search = (queryString.parse(window.location.search) || {});
  // posible value pgapp, poapp, kasamba, psapp
  if (search.pg_client || search.client_platform) return 'customer app';
  return 'customer web';
};

export const getClientPlatformForAPI = () => {
  const platform = getClientPlatform();
  return platform === 'customer web' ? 'Web' : 'app';
};

export const browserContext = { browser_context: browserName };

export const isWebView = () => {
  if (typeof window === 'undefined') { return false; }

  const { navigator } = window;

  const { standalone } = navigator;
  const userAgent = navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad|macintosh/.test(userAgent);
  const iosIpadWebView = ios && !safari;

  return ios ? ((!standalone && !safari) || iosIpadWebView) : userAgent.includes('wv');
};

export const isPGW = () => window.location.host?.includes('purplegarden');

export const isPOW = () => window.location.host?.includes('purpleocean');

export const isKA = () => process.env.APP_CONTEXTS === 'ka';

export const isPS = () => process.env.APP_CONTEXTS === 'ps';

export const isPG = () => process.env.APP_CONTEXTS.includes('pg');

export const getAppName = () => {
  if (isPOW()) return 'Purple Ocean';
  if (isKA()) return 'Kasamba';
  return 'Purple Garden';
};

export const appContext = {
  po: 'po',
  pg: 'pg',
  ka: 'ka',
  ps: 'ps'
};

export const getIcon = ({
  iconName, requireContext, forAppContext = false, appTheme
}) => {
  let name = iconName;
  let appCode;
  if (forAppContext) {
    if (isPGW()) {
      appCode = appContext.pg;
      name = `${ appCode }_${ name }`;
    } else if (isKA()) {
      appCode = appContext.ka;
      name = `${ appCode }_${ name }`;
    } else {
      appCode = appContext.po;
      name = `${ appCode }_${ name }`;
    }
  }
  const images = requireContext.keys().map(requireContext);
  let img = images.find(item => item.includes(name));
  if (appTheme && Object.keys(appThemes).includes(appTheme)) {
    const nameWithTheme = `${ name }_${ appTheme }`;
    const imgWithTheme = images.find(item => item.includes(nameWithTheme));
    if (imgWithTheme) img = imgWithTheme;
  }
  if (!img) {
    const unknowImg = images.find(item => item.includes('unknow')); // Need for unknown credit card
    if (unknowImg) return unknowImg;
    if (forAppContext && appCode) {
      const nameWithoutAppContext = name.replace(`${ appCode }_`, '');
      const imgWithoutAppContext = images.find(item => item.includes(`${ nameWithoutAppContext }`));
      return imgWithoutAppContext;
    }
  }
  return img;
};

export const compareObjects = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

function UsePreviousValue({ value }) {
  const ref = useRef();
  useEffect(() => { ref.current = value; });
  return ref.current;
}

UsePreviousValue.propTypes = {};

UsePreviousValue.defaultProps = {};

export default UsePreviousValue;

const convertCase = (oldObject, converterFunction) => {
  let newObject;

  if (!oldObject || typeof oldObject !== 'object' || !Object.keys(oldObject).length) return oldObject;

  if (Array.isArray(oldObject)) {
    newObject = oldObject.map(element => convertCase(element, converterFunction));
  } else {
    newObject = {};
    Object.keys(oldObject).forEach(oldKey => {
      const newKey = converterFunction(oldKey);
      newObject[newKey] = convertCase(oldObject[oldKey], converterFunction);
    });
  }

  return newObject;
};

export const toCamelCase = (obj) => convertCase(obj, camelCase);
export const toSnakeCase = (obj) => convertCase(obj, snakeCase);
export const toLowerCaseSpaced = (obj) => convertCase(obj, lowerCase);

export const convertStringToSnakeCase = (str) => {
  str = str[0].toLowerCase() + str.slice(1, str.length).replace(/[A-Z]/g, letter => `_${ letter.toLowerCase() }`);
  str  = str.replaceAll(' _', '_');

  return str.replaceAll(' ', '_').replace(/(^_*|_*$)/g, '');
};

export const convertStringToCamelCase = (str) => {
  const arr = str.match(/[a-z]+|\d+/gi);
  return arr.map((m, i) => {
    let low = m.toLowerCase();
    if (i !== 0) {
      low = low.split('').map((s, k) => (k === 0 ? s.toUpperCase() : s)).join``;
    }
    return low;
  }).join``;
};

export const downloadAppLink = (afChanel) => {
  if (!window || !window.attribution || !window.attribution.url) return null;
  let parsedURL = window.attribution.url.replace(/&amp;/gi, '&');
  parsedURL += afChanel ? `&${ queryString.stringify({ af_channel: afChanel }) }` : '';
  return parsedURL;
};

export const emailIsValid = (email) => {
  const re = /^[A-Z0-9a-z._%+-]+@([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}$/;
  return re.test(String(email.trim()).toLowerCase());
};

export const nicknameIsValid = (nickname) => {
  const re = /^[a-zA-Z0-9]{2,20}$/;
  return re.test(String(nickname.trim()).toLowerCase());
};

export const paymentKindToAnalyticsName = (kind) => {
  const kindList = {
    cc: 'credit card',
    pp: 'paypal',
    iap: 'IAP',
    ap: 'apple pay',
    none: 'none'
  };
  return kindList[kind];
};

export const noPaymentMethodsEventData = (options) => {
  if (!options) return ({ 'available methods': undefined });
  const hasNoPaymentMethodsSet = options.find((o) => o.adding);
  if (hasNoPaymentMethodsSet) {
    const availableMethods = [];
    options.forEach((o) => {
      if (o.adding) availableMethods.push(paymentKindToAnalyticsName(o.kind));
    });
    return ({ 'available methods': availableMethods.join(' + ') });
  }
  return ({ 'available methods': undefined });
};

export const currentPaymentMethodData = () => {
  const { getState } = window.store;
  const { paymentSources: { current } } = getState();
  if (current) {
    const { kind, id, gatewayName } = current;
    const previousPaymentProps = {
      'prev payment method': paymentKindToAnalyticsName(kind),
      'prev payment id' : id,
      'prev payment gateway': gatewayName
    };
    return previousPaymentProps;
  }
  return null;
};

export const getPurchaseScreenForPaymentSourcesEvent = (clickSource) => {
  let purchaseScreen = clickSourceOrMobileClickSource(clickSource);
  if (window.location.pathname.includes('/payment_sources')) purchaseScreen = 'payment methods';
  return purchaseScreen;
};

export const tryParseJSONObject = (jsonString) => {
  try {
    const o = JSON.parse(jsonString);
    if (o && typeof o === 'object') return o;
  } catch (e) { }
  return false;
};

export const capitalizeFirstChar = (str) => {
  if (!str) return null;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatCardName = (str) => {
  if (!str) return null;
  const splitedString =  str.split('_');
  let cardName = '';
  splitedString.map((item) => {
    let s = item;
    if (s === 'mastercard') {
      s = s.slice(0, s.indexOf('card')) + capitalizeFirstChar(s.slice(s.indexOf('card')));
    }
    cardName += ` ${ capitalizeFirstChar(s) }`;
    return null;
  });
  return cardName.trim();
};

const purchaseTypes = {
  side_menu: purchaseDetailsTypes.SIDE_MENU,
  live_chat: purchaseDetailsTypes.CHAT,
  voice_call: purchaseDetailsTypes.VOICE_CHAT,
  video_call: purchaseDetailsTypes.VIDEO_CHAT,
  matchup_promo: purchaseDetailsTypes.AFFILIATE_PROMO_POPUP
};

export const getPurchaseType = (purchaseType) => {
  if (purchaseType) return purchaseTypes[purchaseType];
  return purchaseDetailsTypes.SIDE_MENU;
};

export const getPrice = (price) => parseFloat(price || 15);
export const getBonus = (bonus) => parseFloat(bonus || 0);
export const getDuration = (duration) => parseFloat(duration);
export const getAdvisorId = (advisorId) => parseFloat(advisorId);
export const getClickSource = (clickSource) => (clickSource || '').split('_').join(' ');
export const getIsPayg = (payg) => payg && payg.toLowerCase() === 'true';

export const hideEmailAdress = (email) => {
  if (!emailIsValid(email)) return email;
  const emailSplit = email.split('@');
  const emailFirstPart = emailSplit[0];
  const firstNCharts = emailFirstPart.slice(0, 3);
  const lastChar = emailFirstPart.length >= 4 ? emailFirstPart.slice(-1) : '';
  const hidedEmail = `${ firstNCharts }****${ lastChar }@${ emailSplit[1] }`;
  return hidedEmail;
};

export const getMinPrice = () => {
  const userReducer = window.store.getState().user;
  return (userReducer.user?.minCreditRequestedCents || 1000) / 100;
};

export const calculateDiffHandler = ({ purchaseType, price, clientAvailableCredit }) => {
  if (purchaseType === purchaseDetailsTypes.SIDE_MENU
      || purchaseType === purchaseDetailsTypes.AFFILIATE_PROMO_POPUP) {
    return price;
  }
  return price - parseFloat(clientAvailableCredit);
};

export const calculateRequiredFunds = ({ diffPrice, purchaseType }) => {
  const minPrice = getMinPrice();
  if (purchaseType === purchaseDetailsTypes.AFFILIATE_PROMO_POPUP) return diffPrice;
  if (diffPrice < minPrice) return minPrice;
  return diffPrice;
};

const localDate = new Date();
export const getDateFromServer = () => {
  const currentDate = new Date();
  const milliSecondsDifference = differenceInMilliseconds(currentDate, localDate);

  const windowConfigUtcDate = new Date(window.config.utc.replace(/-/g, '/'));

  const dateFromServer = addMilliseconds(windowConfigUtcDate, milliSecondsDifference);
  return dateFromServer;
};

export const getDateFromServerUTC = () => {
  const dateFromServer = getDateFromServer();
  const dateFromServerUtc = new Date(dateFromServer.getTime() + dateFromServer.getTimezoneOffset() * 60000);

  return dateFromServerUtc;
};

export const getData = ({ date, type }) => {
  const getDataTypes = {
    date: getDate,
    hours: getHours,
    day: getDay,
    month: getMonth,
    year: getYear
  };
  const itemCount = type === 'month' ? getDataTypes[type](date) + 1 : getDataTypes[type](date);
  const result = itemCount < 10 ? `0${ itemCount }` : itemCount;
  return result;
};

export const calculateDifference = ({ date1, date2, type }) => {
  const calculateDifferenceTypes = {
    days: differenceInDays,
    years: differenceInYears
  };
  if (!date1 || !date2) return null;
  const result = calculateDifferenceTypes[type](new Date(date1), new Date(date2));
  return result;
};

export const getAddCreditUrl = ({ credit, purchaseType, clickSource }) => {
  if (!window.config.addCreditPath) return '';
  const clickSourceParam = clickSource ? { click_source: clickSource } : {};
  let queryParams = { with_payment_sources: true, platform: getClientPlatformForAPI(), ...clickSourceParam };
  if (purchaseType === purchaseDetailsTypes.AFFILIATE_PROMO_POPUP || purchaseType === 'matchup_promo') {
    queryParams = { ...queryParams, is_affiliate_promo_purchase: true };
  }
  const {
    context, bonus, price
  } = queryString.parse(window.location.search);

  let url = window.config.addCreditPath.replace('{credit}', credit);
  if (context === 'credit_screen') {
    const amount = Math.ceil(parseFloat(bonus) + parseFloat(price));
    url = `/my/app_credits/${ amount }`;
  }
  return `${ url }?${ queryString.stringify(queryParams) }`;
};

export const asyncLoadScript = (scriptURL, existingVariable) => new Promise((resolve, reject) => {
  if (existingVariable) {
    resolve();
    return;
  }

  const script = document.createElement('script');
  script.src = scriptURL;
  script.async = true;
  script.onload = () => resolve();
  script.onerror = (error) => reject(error);
  document.body.appendChild(script);
});

export const withCaptcha = (action, callback) => {
  asyncLoadScript(`https://www.google.com/recaptcha/enterprise.js?render=${ window.config.recaptchaKey }`, window.grecaptcha).then(() => {
    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(window.config.recaptchaKey, { action })
        .then(token => callback(token));
    });
  });
};

const ckoGetDeviceSessionIdApi = ({ publicKey, riskJsUrl }) => new Promise((resolve, reject) => {
  asyncLoadScript(riskJsUrl, window.Risk).then(() => {
    const risk = window.Risk.init(publicKey);
    risk.publishRiskData()
      .then((deviceSessionId) => resolve(deviceSessionId))
      .catch(error => reject(error));
  }).catch(error => reject(error));
});

export const withCkoGetDeviceSessionIdApi = ({ callback, params }) => {
  const { paymentSources } = window.store.getState();
  const currentPaymentSource = paymentSources.current;
  if (currentPaymentSource.riskModel === 'riskjs') {
    const { publicKey, jsSrc } = currentPaymentSource.riskModelConfig;
    return ckoGetDeviceSessionIdApi({ publicKey, riskJsUrl: jsSrc })
      .then((deviceSessionId) => callback({ ...params, deviceSessionId }))
      .catch(() => {
        const error = { error: 'ckoGetDeviceSessionIdApi error' };
        return Promise.reject(error);
      });
  }
  return callback(params);
};

export const getAppTheme = () => toCamelCase(queryString.parse(window.location.search)).theme;

export const getTextColorForTheme = () => {
  const theme = getAppTheme();
  if (theme === appThemes.dark) return '#EEEEEE';
  return '#121212';
};

const match = ({ pathname, path }) => matchPath({ path: `${ pathname }`, caseSensitive: true, end: true }, `${ path }`);

export const matchPaths = ({ pathname, path }) => {
  let m;
  if (Array.isArray(pathname)) {
    pathname.find((p) => {
      m = match({ pathname: p, path });
      return m;
    });
  } else {
    m = match({ pathname, path });
  }
  return m;
};

export const isPaygAndNotEligibleToPayAsYouGo = ({ isPayg, eligibleToPayAsYouGo }) => isPayg && eligibleToPayAsYouGo !== undefined && !eligibleToPayAsYouGo;

export const removeEmptyValueInObject = (object) => {
  let clearedObject = {};
  Object.keys(object).forEach((key) => {
    if (object[key] !== null && object[key] !== undefined) {
      clearedObject = { ...clearedObject, [key]: object[key] };
    }
  });
  return clearedObject;
};

export const isVersionHigher = (version) => {
  const search = (queryString.parse(window.location.search) || {});
  const mobileVersion = search?.app_version;
  if (!mobileVersion) return false;

  return semver.gte(mobileVersion, version);
};

export const updatedPurchaseURL = ({ purchaseURL, extraParams }) => {
  if (extraParams) {
    const purchaseURLSearch = purchaseURL.slice(purchaseURL.indexOf('?'));
    const purchaseURLPathName = purchaseURL.slice(0, purchaseURL.indexOf('?'));
    const parsedPurchaseURLSearch = queryString.parse(purchaseURLSearch);
    const parsedExtraParams = queryString.parse(extraParams);
    const updatedUrlSearch = queryString.stringify({
      ...parsedPurchaseURLSearch, ...parsedExtraParams
    });
    const updatedUrl = `${ purchaseURLPathName }?${ updatedUrlSearch }`;
    return updatedUrl;
  }
  return purchaseURL;
};
