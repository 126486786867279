import { connect } from 'react-redux';
import withPGTranslation from '../../config/withPGTranslation';
import AdvisorAvatar from './advisor_avatar';
import { favoriteAdvisor } from '../../actions/favorite_advisors';
import { authPendingAction, displayRegister } from '../../actions/auth';

export const advisorThemes = {
  staff_pick: 'staff_pick',
  personal: 'personal',
  badge: 'badge',
  badge_end_to_end: 'badge_end_to_end',
  searchResult: 'searchResult',
  someOtherAdvisor: 'someOtherAdvisor',
  profileSmallAvatar: 'profileSmallAvatar',
  sticky_icons: 'sticky_icons',
  homePageAllAdvisors: 'homePageAllAdvisors',
  activityAvatar: 'activityAvatar',
  recommendedCarousel: 'recommendedCarousel'
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps, {
  favoriteAdvisor,
  authPendingAction,
  displayRegister
})(withPGTranslation(AdvisorAvatar));
