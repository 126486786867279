import { connect } from 'react-redux';
import withPGTranslation from '../../config/withPGTranslation';
import SideMenu from './side_menu';

const mapStateToProps = ({ user }) => ({
  isSufficientForCashback: user.user ? user.user.isSufficientForCashback : false,
  cashbackAmount: user.user ? user.user.cashbackAmount : '0.0',
  shouldShowPayoutMethodMenuItem:
    user.user && user.user.settings
      ? !!user.user.settings.showPaymentMethodMenuItem : true
});

export default connect(
  mapStateToProps
)(withPGTranslation(SideMenu));
