import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import withPGTranslation from '../../../../config/withPGTranslation';
import Switch from '../../../../../common/helpers/switch';

function FilterSwitcher({
  onChange, checked, t, filterSwitcherId
}) {
  return (
    <div className={ classes.switcherContainer }>
      <div>
        <Switch
          id={ filterSwitcherId }
          onChange={ onChange }
          checked={ checked }
          offColor="#DDDDDD"
          onColor="var(--app-filter-switcher-color)"
          width={ 56 }
          height={ 29 }
          handleDiameter={ 19 }
        />
      </div>
      <div className={ classes.switcherTitle }>
        { t('home_page.show_offline_advisors') }
      </div>
    </div>
  );
}

FilterSwitcher.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange:PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  filterSwitcherId: PropTypes.string.isRequired
};

export default withPGTranslation(FilterSwitcher);
