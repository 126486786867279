import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../common/helpers/alert';

function GeneralError({
  clearApplicationError, errorText
}) {
  if (errorText) {
    return (
      <Alert
        errorString={ errorText }
        onClose={ clearApplicationError }
      />
    );
  }
  return null;
}

GeneralError.propTypes = {
  errorText: PropTypes.string,
  clearApplicationError: PropTypes.func.isRequired
};

GeneralError.defaultProps = {
  errorText: null
};

export default GeneralError;
