/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import ReadingSinceAndCountItem from './readingSinceAndCountItem';
import withPGTranslation from '../../../../config/withPGTranslation';

export const readingSinceAndCountItemThemes = {
  default: 'default',
  staffPick: 'staffPick',
  staffPickMobile: 'staffPickMobile',
  onHomePageAdvisorAvatar: 'onHomePageAdvisorAvatar',
  onHomePageSecriceContainer: 'onHomePageSecriceContainer',
  recommendAdvisor: 'recommendAdvisor'
};

const mapStateToProps = () => (
  {}
);

export default connect(
  mapStateToProps,
  {
  }
)(withPGTranslation(ReadingSinceAndCountItem));
