import React, { Suspense } from 'react';
import {
  Route,
  BrowserRouter,
  useLocation,
  useParams,
  useMatch,
  useNavigate
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { LastLocationProvider } from 'react-router-dom-last-location';
import { useTranslation } from 'react-i18next';
import LocalizedSwitch from './localized_switch';
import LocalizedRouter from './localized_router';
import BugsnagErrorBoundary from '../../config/bugsnag';
import { applicationRoutes } from '../../config/app_routes';
import { appLinks } from '../../config/const';

import Popover from '../popover';
import AnalyticsAndCommonLogicComponent from '../analytics';
import GeneralPopup from '../general_popup';
import CookiesPopover from '../../../common/cookies_popover';
import Registration from '../registration';
import NotifyMe from '../notify_me';
import MessageToApp from '../message_to_app';
import { ProtectedRoute } from './protected_route';
import { useCurrentPath } from './hooks/useCurrentPath';
import Overlays from './overlays/overlays';
import VoipChatOverlay  from '../voip_chat_overlay';
import ChatOverlay from '../chat_overlay';

const routeComponents = {
  HomePage: require('../home_page').default,
  Advisor: require('../advisor').default,
  SearchResults: React.lazy(() => import('../search_results')),
  NoSearchResults: React.lazy(() => import('../no_search_results/no_search_results')),
  MyOrders : React.lazy(() => import('../my_orders')),
  AdvisorOrders : React.lazy(() => import('../advisor_orders')),
  OrderDetails : React.lazy(() => import('../order_details')),
  AddFunds : React.lazy(() => import('../add_funds')),
  PromoCode : React.lazy(() => import('../promo_code')),
  Transcript : React.lazy(() => import('../chat_transcript')),
  Settings : React.lazy(() => import('../settings')),
  Cashback : React.lazy(() => import('../cashback')),
  PaymentSources : React.lazy(() => import('../payment_sources')),
  EmptyPreload : React.lazy(() => import('../empty_preload/empty_preload')),
  SpendLimitsVerifications : React.lazy(() => import('../spend_limits_verification')),
  SignInPage : React.lazy(() => import('../signin_page')),
  PurchaseDetailsForMobileApp : React.lazy(() => import('../purchase_details_for_mobile_app')),
  Categories : React.lazy(() => import('../categories')),
  NotificationPreferences : React.lazy(() => import('../settings/notification_preferences'))
};

const suspenseComponent = (component) => <Suspense>{component}</Suspense>;
const protectedRoute = (component, user) => <ProtectedRoute user={ user }>{component}</ProtectedRoute>;

const RenderRoutes = ({ user }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const history = useNavigate();
  const currentPath = useCurrentPath();

  const getRoutes = () => {
    const routes = [];
    Object.keys(applicationRoutes).forEach((key) => {
      const match = useMatch(`/:lang?${ t(key) }`);

      const { name, withoutSuspense, isProtected } = applicationRoutes[key];

      const Component = routeComponents[name];
      let child = <Component location={ location } params={ params } match={ match } history={ history } currentPath={ currentPath } />;
      if (!withoutSuspense) child = suspenseComponent(child);
      if (isProtected) child = protectedRoute(child, user);

      routes.push(
        <Route
          key={ key }
          exact
          path={ key }
          element={ (
            <LastLocationProvider>
              {child}
            </LastLocationProvider>
          ) }
        />
      );
    });
    return routes;
  };

  const routes = getRoutes();

  return (
    <LocalizedSwitch>
      {routes.map((r) => r)}
    </LocalizedSwitch>
  );
};

function RoutesComponent({
  state, voipState, popoverVisibility, user
}) {
  return (
    <BugsnagErrorBoundary>
      <div suppressHydrationWarning id="layout" style={ { width: '100%', backgroundColor: 'var(--app-background-color)' } }>
        <BrowserRouter basename="/">
          <LocalizedRouter>
            <RenderRoutes user={ user } />
          </LocalizedRouter>
          <Overlays />
          {state ? <ChatOverlay /> : null}
          {voipState ? <VoipChatOverlay /> : null}
          {popoverVisibility ? <Popover /> : null}
          <AnalyticsAndCommonLogicComponent />
          <GeneralPopup />
          <CookiesPopover
            wpccMessageText="cookies_popover.wpcc_message_text"
            wpccPrivacyText="cookies_popover.wpcc_privacy_text"
            wpccPrivacyHref={ appLinks.privacyPolicy }
            wpccBtnText="cookies_popover.wpcc_btn_text"
          />
          <Registration />
          <NotifyMe />
          <MessageToApp />
        </BrowserRouter>
      </div>
    </BugsnagErrorBoundary>
  );
}

RoutesComponent.propTypes = {
  state: PropTypes.string,
  errorCode: PropTypes.number,
  errorUrl: PropTypes.string,
  clearApplicationError: PropTypes.func.isRequired,
  logOutClear: PropTypes.func.isRequired,
  displayRegister: PropTypes.func.isRequired,
  voipState: PropTypes.string,
  popoverVisibility: PropTypes.bool,
  user: PropTypes.object
};

RoutesComponent.defaultProps = {
  state: null,
  errorCode: null,
  voipState: null,
  errorUrl: null,
  popoverVisibility: false,
  user: null
};

RenderRoutes.propTypes = {
  user: PropTypes.object
};

RenderRoutes.defaultProps = {
  user: null
};

export default RoutesComponent;
