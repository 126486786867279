import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useLocalizedNavigate } from '../hooks/useLocalizedNavigate';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const location = useLocation();
    const navigate = useLocalizedNavigate();
    const params = useParams();

    return (
      <Component
        router={ { location, navigate, params } }
        { ...props }
      />
    );
  };

  return Wrapper;
};
