import { connect } from 'react-redux';
import AdvisorRecommendCarousel from './advisors_recommend_carousel';
import { getSimilarOnlineAdvisors, trackBusyCarouselEvent } from '../../../../actions/advisors';

const mapStateToProps = ({ user: { user }, advisors }, { similarOnlineAdvisors, advisorId }) => ({
  userId: user && user.id,
  advisorStatus: advisors[advisorId]?.status,
  carouselAvailability: window.config.available_busy_carousel === 'true',
  advisors: similarOnlineAdvisors?.map((adv) => (advisors[adv.id])),
  userXfmProgram: user && user.userXfmProgram
});

export default connect(mapStateToProps, {
  getSimilarOnlineAdvisors,
  trackBusyCarouselEvent
})(AdvisorRecommendCarousel);
