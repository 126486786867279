import { connect } from 'react-redux';
import PushNotification from './push_notification';
import { loadAdvisor } from '../../actions/advisors';
import { clearPN } from '../../actions/firebase_messaging';
import { loadOrderDetails } from '../../actions/order_details';
import withPGTranslation from '../../config/withPGTranslation';
import { loadHomePage } from '../../actions/home_page';

const mapStateToProps = ({
  pushNotification, chat, user:{ user }, advisors
}) => ({
  ...pushNotification,
  needToHandle: !chat.state,
  userbadgeCounters: user && user.badgeCounters,
  advisors
});

export default connect(
  mapStateToProps,
  {
    clearPN,
    loadAdvisor,
    loadOrderDetails,
    loadHomePage
  }
)(withPGTranslation(PushNotification));
