import { useLocation, useNavigate } from 'react-router';
import { useEffect } from 'react';
import { useLocalizedPath } from './useLocalizedPath';
import { getCurrentPath } from '../routes_helpers';
import { applicationRoutes } from '../../../config/app_routes';

export const usePathValidation = () => {
  const history = useNavigate();
  const location = useLocation();
  const generatePath = useLocalizedPath();

  useEffect(() => {
    const { search = '', pathname, state } = location;
    const { routeKey, params } = getCurrentPath(Object.keys(applicationRoutes));
    const newPath = generatePath(params?.lang, routeKey, params);

    if (newPath !== pathname) {
      history(newPath + search, { state, replace: true });
    }
  }, [location.pathname]);
};
