/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from './classes.module.scss';
import AdvisorPreview from '../../../home_page/nested_views/advisor_preview';
import { liveModeState } from '../../../../config/const';
import AdvisorSection from '../../../home_page/nested_views/advisors_section';
import { advisorsSectionThemes, sectionIds } from '../../../home_page/prop_types';
import { advisorThemes } from '../../../advisor_avatar';
import AdvisorSectionTitle from '../../../home_page/nested_views/advisors_section/advisor_section_title';

export interface AdvisorRecommendCarouselProps {
    loading:boolean,
    advisorId: number,
    advisors: Advisor.ListItem[],
    getSimilarOnlineAdvisors: (advisorId: number) => void,
    analytics: Analytic.Source,
    userXfmProgram: Advisor.UserXfmProgramProps,
    theme: string,
    trackBusyCarouselEvent: (eventName: string, advisors: Advisor.ListItem[]) => void
}

const AdvisorRecommendCarousel = ({
  loading, advisors, getSimilarOnlineAdvisors, advisorId, analytics,  trackBusyCarouselEvent
}: AdvisorRecommendCarouselProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (advisors?.some((adv) => (adv.status === liveModeState.offline || adv.status === liveModeState.busy))) {
      getSimilarOnlineAdvisors(advisorId);
    }
  }, [advisors]);

  const trackBusyCarouselClickAction = () => trackBusyCarouselEvent('busy carousel clicked', advisors);

  const renderAdvisorListSectionMobile = (adv: Advisor.ListItem) => (
    <AdvisorPreview
      key={ !loading ? adv.id : `emptyAdvisorSection_${ adv.id }` }
      advisor ={ adv }
      theme={ advisorThemes.recommendedCarousel }
      advisorCount={ 3 }
      loading={ adv.loading }
      analytics={ analytics || { clickSource: 'busy carousel' } }
      sectionTitleTheme={ advisorThemes.recommendedCarousel }
      onAnalyticsClick={ trackBusyCarouselClickAction }
    />
  );

  const renderAdvisorListSection = (props: Advisor.Section) => (
    <AdvisorSection
      id={ sectionIds.recommend_advisors }
      { ...props }
    />
  );

  const renderRecommendedAdvisors = () => (
    <div className={ classes.recommendedCarouselContainer }>
      <div className={ classes.recommendedCarousel }>
        { renderAdvisorListSection({
          theme: advisorsSectionThemes.recommend_advisors,
          advisors,
          loading,
          advisorAnalytics: analytics || { clickSource: 'busy carousel' },
          title: t('advisorProfile.try_these_advisors'),
          description: t('advisorProfile.explore_other_advisors'),
          onAnalyticsClick: trackBusyCarouselClickAction
        })}
      </div>
      <div className={ classes.recommendedCarouselMobile }>
        <AdvisorSectionTitle
          title={ t('advisorProfile.try_these_advisors') }
          shortDescription= { t('advisorProfile.explore_other_advisors') }
          loading={ loading }
          theme={ advisorsSectionThemes.recommend_advisors }
        />
        <div className={ classes.recommendedAdvisors }>
          { advisors.map((adv: Advisor.ListItem) => (renderAdvisorListSectionMobile(adv))) }
        </div>
      </div>
    </div>
  );

  return renderRecommendedAdvisors();
};

AdvisorRecommendCarousel.propTypes = {
  advisors: PropTypes.array,
  getSimilarOnlineAdvisors: PropTypes.func.isRequired,
  userXfmProgram: PropTypes.object,
  trackBusyCarouselEvent: PropTypes.func.isRequired
};

AdvisorRecommendCarousel.defaultProps = {
  advisors: [],
  userXfmProgram: {}
};

export default AdvisorRecommendCarousel;
