/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
import React from 'react';

export const psCouponLogo = (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7514_85661)">
      <path d="M3.98024 4.80129C1.50903 7.48648 0 11.0709 0 15.0078C0 19.4411 1.91355 23.4275 4.95978 26.1861C3.5436 24.85 2.65968 22.9558 2.65968 20.8552C2.65968 17.8441 5.18215 16.0825 7.75856 14.2832C8.79839 13.557 9.84702 12.8247 10.7421 12.0016C10.887 11.8684 11.0298 11.7318 11.1702 11.5919L11.1843 11.5779H11.1846L11.2058 11.5566C12.7552 10.0015 13.9124 8.20333 14.6776 6.28516C13.2011 4.3 11.5865 2.65967 9.15726 2.65967C7.13605 2.65967 5.30597 3.47795 3.98024 4.80129Z" fill="url(#paint0_linear_7514_85661)" />
      <path d="M3.97667 25.2101C4.05708 25.3005 4.13969 25.3888 4.22422 25.4751C4.26695 25.5187 4.30993 25.562 4.35318 25.6051C4.22533 25.4757 4.0998 25.344 3.97667 25.2101Z" fill="url(#paint1_linear_7514_85661)" />
      <path d="M4.82436 26.0547C7.50545 28.5053 11.0749 30.0002 14.9935 30.0002C19.427 30.0002 23.4134 28.0867 26.1721 25.0406C24.836 26.4568 22.9418 27.3407 20.8411 27.3407C17.8753 27.3407 16.1517 24.8738 14.3827 22.3421C13.6244 21.2567 12.8577 20.1595 11.9813 19.2395C11.912 19.1667 11.8418 19.0946 11.7706 19.0232L11.7503 19.0029L11.7473 19C10.1057 17.3648 8.16475 16.2023 6.08589 15.509C6.0753 15.5055 6.06411 15.5055 6.05386 15.5087C4.17392 16.94 2.66016 18.5228 2.66016 20.8554C2.66016 22.888 3.48775 24.7273 4.82436 26.0547Z" fill="url(#paint2_linear_7514_85661)" />
      <path d="M22.2459 15.7157C21.2117 16.438 20.1689 17.1663 19.2776 17.9839C19.1282 18.1209 18.9812 18.2614 18.8366 18.4054L18.8155 18.4266L18.8155 18.4267L18.8069 18.4352C17.2621 19.9858 16.107 21.7781 15.3418 23.69C16.8129 25.6824 18.4094 27.3404 20.8408 27.3404C22.9067 27.3404 24.7729 26.4855 26.105 25.1103C28.5258 22.4356 30.0002 18.8885 30.0002 14.9969C30.0002 10.5619 28.0852 6.57415 25.0368 3.81543C26.4551 5.1517 27.3405 7.04726 27.3405 9.14959C27.3405 12.1578 24.8198 13.9182 22.2459 15.7157Z" fill="url(#paint3_linear_7514_85661)" />
      <path d="M25.8715 25.3626C25.8361 25.3994 25.8006 25.436 25.7648 25.4725C25.7696 25.4681 25.7743 25.4637 25.779 25.4593C25.81 25.4272 25.8408 25.3949 25.8715 25.3626Z" fill="url(#paint4_linear_7514_85661)" />
      <path d="M15.7242 7.75355C16.4453 8.78656 17.1723 9.82808 17.9887 10.7185C18.1267 10.8691 18.2684 11.0174 18.4136 11.1632L18.4347 11.1842V11.1845L18.456 11.2058C20.0066 12.7506 21.7989 13.9056 23.7108 14.6708C25.6977 13.1929 27.34 11.5778 27.34 9.14964C27.34 7.09522 26.4945 5.23825 25.1325 3.9077C22.4561 1.47954 18.9033 1.704e-07 15.0048 0C10.5745 -1.93648e-07 6.59051 1.91079 3.83203 4.95319C5.16783 3.54081 7.05949 2.65961 9.15697 2.65961C12.1683 2.65961 13.9278 5.18012 15.7242 7.75355Z" fill="url(#paint5_linear_7514_85661)" />
      <path d="M25.4518 4.20594C25.4583 4.21225 25.4648 4.21857 25.4712 4.2249C25.4703 4.22395 25.4694 4.223 25.4686 4.22205C25.463 4.21668 25.4574 4.21131 25.4518 4.20594Z" fill="url(#paint6_linear_7514_85661)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_7514_85661" x1="13.0762" y1="4.24209" x2="-3.61165" y2="15.0151" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.4" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint1_linear_7514_85661" x1="18.8164" y1="30.0002" x2="1.45438" y2="25.4617" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.4" />
      </linearGradient>
      <linearGradient id="paint2_linear_7514_85661" x1="18.8164" y1="30.0002" x2="1.45438" y2="25.4617" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.4" />
      </linearGradient>
      <linearGradient id="paint3_linear_7514_85661" x1="28.4009" y1="5.39775" x2="11.7226" y2="16.1511" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.4" />
      </linearGradient>
      <linearGradient id="paint4_linear_7514_85661" x1="28.4009" y1="5.39775" x2="11.7226" y2="16.1511" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.4" />
      </linearGradient>
      <linearGradient id="paint5_linear_7514_85661" x1="27.34" y1="11.8408" x2="7.29222" y2="9.75961" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.4" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint6_linear_7514_85661" x1="27.34" y1="11.8408" x2="7.29222" y2="9.75961" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.4" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <clipPath id="clip0_7514_85661">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
