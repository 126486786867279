import React from 'react';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import {
  applicationRoutes,
  appRoutes, dynamicRoutes, dynamicRoutesKeys
} from './app_routes';
import {
  isKA,
  matchPaths,
  paramOrNul,
  toCamelCase,
  convertStringToCamelCase,
  isPS
} from '../../common/config/utils';
import Const, { purchaseFlowTypes } from './const';
import  i18n from './i18n';
import env from './variables';

const {
  XFM_ADDITIONAL_FREE_SETUP_TIME, XFM_PROGRAM_ACTIVE, DEFAULT_XFM_CREDITS
} = env;

export const parseAdvisorsFromAdvisorsState = (advisors) => {
  const advisorsState = window.store.getState().advisors;
  if (!advisors || advisors === undefined) return [];
  if (!advisorsState) return advisors;
  return advisors.map((a) => ({ ...a, ...advisorsState[a.id] }));
};

export const convertErrorArrayToString = (fullMessages) => {
  let message = '';
  fullMessages.forEach((m, index) => {
    message = `${ message }${ index === 0 ? '' : ', ' }${ m }`;
  });
  return message;
};

export const advisorModeIsActive = (modeUntil) => !!(new Date(modeUntil) > new Date());

export const filterIsApplied = ({ withSearch = false }) => {
  const {
    includeOffline, minNumberOfReviewsGe, ppmGe, ppmLe, readingVia, query
  } = toCamelCase(queryString.parse(window.location.search));
  let filters = {
    includeOffline, minNumberOfReviewsGe, ppmGe, ppmLe, readingVia
  };
  if (withSearch) filters = { ...filters, query };
  const cleanFilters = Object.keys(filters)
    .filter((key) => filters[key] !== undefined);
  return Object.keys(cleanFilters).length > 0;
};

export const passwordIsValid = (password, isSignUp) => {
  if (typeof password !== 'string') return false;
  return isSignUp ? password.trim().length >= 1 : password.trim().length >= 6;
};

export const saveDataToStorage = (key, data) => {
  if (!window.localStorage) return;
  try {
    localStorage.setItem(key, data);
  } catch (e) {
    if (e.code === DOMException.QUOTA_EXCEEDED_ERR) {
      localStorage.removeItem('logs_batch');
    }
  }
};

export const getDataFromStorage = (key) => {
  if (!window.localStorage) return null;
  return localStorage.getItem(key);
};

export const canShowAdvisorRate = (likesCount, dislikesCount, minReviewsNumberForDisplayingRating) => (
  likesCount + dislikesCount >= minReviewsNumberForDisplayingRating
);

export const cardsIndexes = () => {
  const indexArray = [];
  for (let index = 0; index < 15; index += 1) {
    indexArray.push(index);
  }
  return indexArray;
};

export const roundCredits = credit => {
  const formattedCredit = Number(credit);
  return Number.isNaN(formattedCredit) ? null : formattedCredit.toFixed(2);
};

export const subscribedToAdvisor = (availabilityNotification) => availabilityNotification && availabilityNotification.switchedOn;

export const textTruncate = (str, strLength, strEnding) => {
  if (!str) return str;
  let length = strLength;
  let ending = strEnding;
  if (length == null) length = 100;
  if (ending == null) ending = '...';
  if (str.length > length) return str.substring(0, length - ending.length) + ending;
  return str;
};

export const payPalProcessingFailReason = (errorType) => {
  if (errorType) return errorType.split('_').join(' ');
  return 'error';
};

export const makeMoreReadable = (text) => {
  if ((text || '').length < 400) return text;
  let moreReadableText = '';
  let moreReadableTextPart = '';
  let leftText = text;
  let i = 0;
  do {
    moreReadableTextPart = '';
    if (i === 0) {
      moreReadableTextPart = leftText.substring(0, leftText.indexOf('.') + 1);
    } else {
      const firstPart = leftText.substring(0, 200);
      const secondPart = leftText.substring(200);
      const secondPartforDotOrNewLine = secondPart.substring(0, secondPart.indexOf('.') + 1) || secondPart.substring(0, secondPart.indexOf('\n'));
      if (secondPartforDotOrNewLine) {
        moreReadableTextPart = firstPart + secondPartforDotOrNewLine;
      } else {
        moreReadableTextPart = firstPart + secondPart;
      }
    }
    moreReadableText += `${ moreReadableTextPart.replace(/^\s+/, '') }\n`;
    leftText = leftText.substring(moreReadableTextPart.length);
    i += 1;
  }
  while (leftText.length >= 300);
  moreReadableText += leftText.replace(/^\s+/, '');
  return moreReadableText;
};

export const decodeHtmlBracketCharCodes = str => str.replace(/&lt;/g, '<').replace(/&gt;/g, '>');

export const advisorsEmptyList = (count, loading = false) => {
  const emptyList = [];
  for (let index = 0; index < count; index += 1) {
    const advisor = loading ? { loading: true } : {};
    emptyList.push({ ...advisor });
  }
  return emptyList;
};

export const pathsNameWithLocalization = (object) => {
  const pathsArray = [];
  Object.keys(object).map((key) => pathsArray.push(object[key]));
  return pathsArray;
};

const getOnHeaderSearchClickActionState = (currentState) => {
  const state = { needRefresh: true, ...paramOrNul('params', (currentState || {}).params) };
  return state;
};

const onHeaderSearchClickAction = (history, currentPath, isSearchLineCloseButtonClick, searchQuery, replace) => {
  if (isSearchLineCloseButtonClick && !replace) return null;
  const { routeKey, search: qs, state: currentState } = currentPath;
  const { query, ...rest } = queryString.parse(qs);
  const search = queryString.stringify({ ...rest, ...searchQuery });
  const state =  getOnHeaderSearchClickActionState(currentState);
  if (replace) {
    history(routeKey, { params: { search }, options: { state, replace: true } });
  } else {
    history(appRoutes.psychics, { params: { search }, options: { state } });
  }
  return null;
};

const headerSearchLineButtonDisabled = ({ searchInputRef, isSearchLineCloseButtonClick }) => {
  const { query } = queryString.parse(window.location.search);
  const searchInputRefValue = ((searchInputRef || {}).current || {}).value;
  return !((searchInputRefValue.length !== 0
    && searchInputRefValue !== query) || isSearchLineCloseButtonClick);
};

export const getValueOrDefault = (obj, ...keys) => {
  while (keys.length) {
    const key = keys.shift();
    if (!obj || !(key in obj)) {
      return {};
    }
    obj = obj[key];
  }

  return obj;
};

export const onHeaderSearchClick = ({
  searchInputRef, setSearchInputValue, history, currentPath, isSearchLineCloseButtonClick
}) => {
  if (headerSearchLineButtonDisabled({ searchInputRef, isSearchLineCloseButtonClick })) return null;
  if (isSearchLineCloseButtonClick) {
    searchInputRef.current.value = ''; //eslint-disable-line
    setSearchInputValue('');
  }
  const searchValue =  searchInputRef.current.value.trim();
  if (searchValue.length <= 0) {
    searchInputRef.current.value = ''; //eslint-disable-line
    if (setSearchInputValue) setSearchInputValue('');
    return null;
  }
  const searchQuery = paramOrNul('query', searchValue);
  const { needReplaceHeaderSearch } = getValueOrDefault(applicationRoutes, currentPath.routeKey);
  onHeaderSearchClickAction(
    history,
    currentPath,
    isSearchLineCloseButtonClick,
    searchQuery,
    needReplaceHeaderSearch
  );
  return null;
};

export const separateThousands = (value) => (value || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const specialityNameOnTwoLine = (name, specialityNameClass) => {
  const words = name.split(' ');
  const firstLine = name.substring(0, name.indexOf(words[words.length - 1]));
  const secondLine = words[words.length - 1];
  return (
    <div className={ specialityNameClass }>
      <span suppressHydrationWarning>
        { firstLine }
      </span>
      <span suppressHydrationWarning>
        { secondLine }
      </span>
    </div>
  );
};

export const isAdvisorBusy = ({ liveModes }) => {
  let busy;
  liveModes.forEach((mode) => { if (mode.modeState === 'busy') busy = true; });
  return busy;
};

export const renderCanonicalLink = (url) => {
  const href = url || `${ window.location.origin }${ window.location.pathname }`;
  return (
    <Helmet>
      <link rel="canonical" href= { href } suppressHydrationWarning />
    </Helmet>
  );
};

export const isMobile = () => parseInt(getComputedStyle(document.documentElement).getPropertyValue('--window-width'), 10) <= window.shared.phoneOnlyUpSize;
export const isMiddleMobile = () => parseInt(getComputedStyle(document.documentElement).getPropertyValue('--window-width'), 10) <= window.shared.middlePhoneOnlyUpSize;

export const renderItemPropMeta = (itemPropSearch) => {
  if (!itemPropSearch.meta) return null;
  return Object.keys(itemPropSearch.meta).map((meta) => itemPropSearch.meta[meta]);
};

export const getSectionForEvent = ({ location }) => {
  if (location.state && location.state.section) return { section:  location.state.section };
  return {};
};

export const getClickSourceForEvent = ({ location }) => ({ clickSource: location.state && location.state.clickSource ? location.state.clickSource : 'direct' });

export const cleanupAdvisorFieldsForAnalytics = (advisor) => {
  const {
    serviceDescription,
    aboutMe,
    history,
    instructions,
    reviews,
    ...rest
  } = advisor;
  const res = {};
  Object.keys(rest).forEach(key => {
    if (!(rest[key] instanceof Function)) {
      res[key] = rest[key];
    }
  });
  return res;
};

export const getAddFundsInitialChargedAndInitialDurationPropsNames = ({ chatType }) => {
  let initialChargedPropName = '';
  let initialDurationPropName = '';
  switch (chatType) {
    case Const.chatType.text:
      initialChargedPropName = 'chat charged initial';
      initialDurationPropName = 'chat minutes initial';
      break;
    case Const.chatType.voice:
      initialChargedPropName = 'voice charged initial';
      initialDurationPropName = 'voice minutes initial';
      break;
    case Const.chatType.video:
      initialChargedPropName = 'vc charged initial';
      initialDurationPropName = 'vc minutes initial';
      break;
    default:
      break;
  }
  return { initialChargedPropName, initialDurationPropName };
};

const getMinutes = ({ selectedDuration }) => {
  const minutes = selectedDuration / 60;
  if (Number.isInteger(minutes)) return minutes.toFixed();
  return minutes.toFixed(2);
};

const additionalOptionsName = {
  payAsYouGo: 'pay as you go',
  currentBalance: 'current balance'
};

export const getDefaultDuration = ({ pricingOptions, payAsYouGo }) => {
  if (Object.keys(pricingOptions).length === 0 && payAsYouGo) return additionalOptionsName.payAsYouGo;
  const defaultOption = pricingOptions.find((o) => o.default && !o.additional)?.duration;
  let defaultDuration = defaultOption < 0 ? defaultOption : (defaultOption / 60).toFixed(2);
  if (!defaultOption && pricingOptions.find((o) => o.default && o.additional)) {
    defaultDuration = additionalOptionsName.currentBalance;
  }
  if (defaultDuration === -1) defaultDuration = additionalOptionsName.payAsYouGo;
  return defaultDuration;
};

export const getDurationOptions = ({ pricingOptions, payAsYouGo }) => {
  if (Object.keys(pricingOptions).length === 0 && payAsYouGo) return [additionalOptionsName.payAsYouGo];
  const durationOptions = pricingOptions.map((o) => {
    if (o.duration === -1) return additionalOptionsName.payAsYouGo;
    if (o.additional) return additionalOptionsName.currentBalance;
    return o.duration;
  });
  return durationOptions;
};

const trackPaygRelatedProps = (payAsYouGo, propValue) => (payAsYouGo ? null : propValue);

export const getPayAsYouGoUsedProperty = ({ flow, isPayg }) => {
  if (flow !== purchaseFlowTypes.paymentMethods && flow !== purchaseFlowTypes.addFunds) {
    return { 'pay as you go used':  !!isPayg };
  }
  return null;
};

export const getDurationPickRelatedProps = ({
  requiredAmount, continuedSession, selectedDuration, price, advisorId, homepageSection,
  startChatClickSource, displayVersion, defaultDuration, defaultDurationChanged, durationOptions, time, payAsYouGo, flow, additional
}) => {
  const props = {
    ...requiredAmount,
    ...paramOrNul('continued session', continuedSession),
    ...paramOrNul('minutes', additional ? null : trackPaygRelatedProps(payAsYouGo, getMinutes({ selectedDuration }))),
    ...paramOrNul('expected charged seconds', trackPaygRelatedProps(payAsYouGo, selectedDuration)),
    ...paramOrNul('credits', trackPaygRelatedProps(payAsYouGo, price)),
    tryout: false,
    'advisor id': advisorId,
    ...paramOrNul('homepage section', homepageSection),
    'click source': startChatClickSource,
    'display version': displayVersion,
    ...paramOrNul('default duration preselected', defaultDuration),
    'default duration changed': defaultDurationChanged,
    'duration options': durationOptions,
    'time on screen': parseFloat((time / 1000).toFixed(2)),
    ...getPayAsYouGoUsedProperty({ flow, isPayg: payAsYouGo }),
    flow
  };
  return props;
};

export const getReviewCount = ({ likesCount, dislikesCount }) => {
  const reviewCount = likesCount + dislikesCount;
  return reviewCount;
};

export const getCurrentTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const convertDateTimeZone = ({ dateString, timeZoneString }) => new Date((new Date(dateString)).toLocaleString('en-US', { timeZone: timeZoneString }));

const flattenRouteSettings = (obj) => {
  const flattened = {};

  Object.keys(obj).forEach((key) => {
    if (key === 'translations') return;
    flattened[key] = obj[key];
  });

  const translationObj = obj.translations?.[i18n.language];

  Object.keys(translationObj).forEach((key) => {
    flattened[key] = translationObj[key];
  });

  return flattened;
};

export const getRouteSettings = ({ routeKey }) => {
  const route = i18n.t(routeKey);

  const routesSettings = dynamicRoutesKeys
    .filter(key => (dynamicRoutes[key].translations?.[i18n.language].slug || dynamicRoutes[key].slug) === route)
    .map(key => dynamicRoutes[key]);

  if (!routesSettings.length) return {};
  return flattenRouteSettings(routesSettings[0]);
};

export const isPrerenderMode = () => {
  const { mode } = toCamelCase(queryString.parse(window.location.search));
  return mode === 'prerender';
};

export const routeMatch = (pathnames) => {
  const match = matchPaths({ pathname: pathnames, path: `${ window.location.pathname }` });
  return match || {};
};

export const matchBannerRoutes = (routeKey) => {
  const { showBanner = false } = getValueOrDefault(applicationRoutes, routeKey);
  return showBanner;
};

export const shouldShowAffiliateBanner = (creditMatchingInfo, userShowMatchUpBonusInfo, userLoading, routeKey) => !!(creditMatchingInfo
    && Object.keys(creditMatchingInfo).length !== 0
    && (userShowMatchUpBonusInfo === null || userShowMatchUpBonusInfo)
    && !userLoading
    && matchBannerRoutes(routeKey));

export const isXfmProgram = ({ advisorXfmProgram, userXfmProgram }) => {
  if (window.currentUserId) {
    return advisorXfmProgram?.active && userXfmProgram?.credits;
  }
  const wasLoggedIn = !!getDataFromStorage('WAS_LOGIN');
  if (wasLoggedIn) return false;
  return XFM_PROGRAM_ACTIVE === 'true';
};

export const getXfmFreeSetupMin = () =>  {
  const freeSetupSeconds = XFM_ADDITIONAL_FREE_SETUP_TIME ?? 180;
  return  parseInt(freeSetupSeconds / 60, 10);
};

export const getXfmDefaultMinutes = () =>  {
  const xfmDefaultMinutes = DEFAULT_XFM_CREDITS ?? 3;
  return  xfmDefaultMinutes;
};

export const getModeColor = (mode) => {
  let defaultModesColors = { chat:'#62DD13', voice: '#8755F4', video: '#22B6FF' };
  if (isKA()) defaultModesColors = { chat: '#7BD137', voice: '#6C84FF', video: '#22B6FF' };
  else if (isPS()) defaultModesColors = { chat: '#2BA4FA', voice: '#B45092' };
  return defaultModesColors[mode];
};

export const isPayg = ({ duration }) => duration === -1;

export const setElementZIndex = (decreaseElementZIndex, low, high) => (decreaseElementZIndex ? low : high);

export const getAdvisorDataIfSsrMode = () => {
  if (!window.advisorsBySlug) return null;
  const advisorMatch = matchPaths({ pathname: Object.keys(window.advisorsBySlug), path: window.location.pathname });
  const advisorData = window.advisorsBySlug[advisorMatch?.pattern.path];

  return toCamelCase(advisorData);
};

export const getHomePageDataIfSsrMode = () => {
  if (!window.homePage) return null;
  const homePageData = window.homePage;
  return toCamelCase(homePageData);
};

export const getActiveOffer = () => {
  const offers = window.currentUserId ? window.store?.getState()?.user?.user?.bonusInfos : window.bonusInfos ?? null;
  if (offers?.length) return offers[0];
  return null;
};

export const formatCurrency = (price) => {
  const formater = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    trailingZeroDisplay: 'stripIfInteger'
  });
  return formater.format(price);
};

export const getHeaderHeight = () => {
  const header = document.getElementById('mainHeaderID')?.getBoundingClientRect();
  return `${ header?.height || 0 }px`;
};

export const getUserFirstActivePromotion = ({ user, application }) => {
  const userIsPresented = user && user.user?.id || user?.id;
  if (userIsPresented) {
    return user && user.user && (user.user.activePromotions || [])[0] || user && (user.activePromotions || [])[0] || {};
  }
  return application?.anonymousUser && (application.anonymousUser.activePromotions || [])[0] || {};
};

export const getAvailableSalesPrice = ({ promoPrices, userAdvisorModeSalePrice }) => {
  if (!userAdvisorModeSalePrice || !promoPrices) return null;
  return promoPrices[convertStringToCamelCase(userAdvisorModeSalePrice)];
};

export const getAvailableMinSalesPrice = ({ liveModeMinPromoPrices, userAdvisorModeMinSalesPrice }) => {
  if (!userAdvisorModeMinSalesPrice || !liveModeMinPromoPrices) return null;
  return liveModeMinPromoPrices[convertStringToCamelCase(userAdvisorModeMinSalesPrice)];
};

export const getUserMatchUpBonusInfo = ({ bonusInfos }) => {
  if (!bonusInfos) return null;
  const userMatchUpBonusInfo = bonusInfos.find((bonus) => bonus.bonusType === 'match_up');
  if (!userMatchUpBonusInfo) return {};
  return userMatchUpBonusInfo;
};

export const getTimeOnBubble = ({ time }) => {
  const now = new Date(time);
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const hours12 = hours % 12 || 12;

  const formattedHours = hours12.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  const timeOnBubble = `${ formattedHours }:${ formattedMinutes } ${  ampm }`;
  return timeOnBubble;
};
