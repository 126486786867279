const { isKA, isPS } = require('../../../../../common/config/utils');

export const heroBannerSources = () => {
  if (isKA()) {
    return {
      thumbMobile: 'https://k3cdn.kassrv.com/eyJrZXkiOiJoZXJvX3RodW1icy9zdGF0aWNfaGVyb19tb2JpbGVfa2EuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjo1MTIsImZpdCI6ImNvbnRhaW4ifX19',
      videoMobile: 'https://customer-b7ut0ov42z708uha.cloudflarestream.com/98dc928bae97440b2c073c99dd6beebd/manifest/video.m3u8',
      thumbDesktop: 'https://k3cdn.kassrv.com/eyJrZXkiOiJoZXJvX3RodW1icy9zdGF0aWNfaGVyb19kZXNrdG9wX2thLmpwZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6MTAyNCwiZml0IjoiY29udGFpbiJ9fX0=',
      videoDesktop: 'https://customer-b7ut0ov42z708uha.cloudflarestream.com/54218777a6c4a0829f611a6b973ddcfb/manifest/video.m3u8'
    };
  }
  if (isPS()) {
    return {
      thumbMobile: 'https://psi-production.s3.us-east-1.amazonaws.com/hero_thumbs/static_hero_mobile_psi.jpg',
      videoMobile: 'https://customer-6291eiomcwwadf7s.cloudflarestream.com/25310e92fecb634d30a782a4906bb58b/manifest/video.m3u8',
      thumbDesktop: 'https://psi-production.s3.us-east-1.amazonaws.com/hero_thumbs/static_hero_desktop_psi.jpg',
      videoDesktop: 'https://customer-6291eiomcwwadf7s.cloudflarestream.com/90b7a5395a5e0f2f3d45a8375444792d/manifest/video.m3u8'
    };
  }
  return {
    thumbMobile: 'https://purple.brgsrv.com/eyJrZXkiOiJoZXJvX3RodW1icy9zdGF0aWNfaGVyb19tb2JpbGVfcGcuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjo1MTIsImZpdCI6ImNvbnRhaW4ifX19',
    videoMobile: 'https://customer-6291eiomcwwadf7s.cloudflarestream.com/100e918e1903ca3501400e651ec5aeb0/manifest/video.m3u8',
    thumbDesktop: 'https://purple.brgsrv.com/eyJrZXkiOiJoZXJvX3RodW1icy9zdGF0aWNfaGVyb19kZXNrdG9wX3BnLmpwZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6MTAyNCwiZml0IjoiY29udGFpbiJ9fX0=',
    videoDesktop: 'https://customer-6291eiomcwwadf7s.cloudflarestream.com/a3fea829572877e5795559fbc01b0ff9/manifest/video.m3u8'
  };
};
