import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { appRoutes } from '../../config/app_routes';
import withPGTranslation from '../../config/withPGTranslation';
import { useLocalizedPath } from './hooks/useLocalizedPath';
import { getCurrentLocalization } from '../../config/i18n';

const getUpdatedLink = (to, lang, path, location) => {
  let link = to;
  let state = { ...location.state };
  let search = '';
  if (typeof to === 'object') {
    link = to.pathname;
    state = { ...state, ...to.state };
    search = to.search || '';
  }

  const updatedLink = path(lang, link, to.params);
  if (updatedLink) link = updatedLink;

  return { link, state, search };
};

function AppLink({
  to, className, children, replace, onClick, alt, style, itemProp, blank, rel, reloadDocument
}) {
  const history = useNavigate();
  const location = useLocation();
  const generatePath = useLocalizedPath();
  const lang = getCurrentLocalization();
  const { link, state, search } = getUpdatedLink(to, lang, generatePath, location);

  const onLinkClick = (e) => {
    if (blank) {
      e.preventDefault();
      return;
    }
    if (onClick) {
      onClick(e);
    }

    if (window.location.pathname === link) {
      e.preventDefault();
      history({ pathname: link, search }, { state, replace: true });
      return;
    }
    if (link === appRoutes.home) {
      window.scrollTo(0, 0);
    }
  };

  const renderAppLink = () => (
    <Link
      to={ `${ link }${ search || '' }` }
      state={ state }
      rel={ rel }
      className={ className }
      replace={ replace }
      onClick={ onLinkClick }
      alt={ alt }
      style={ style }
      itemProp={ itemProp }
      suppressHydrationWarning
      reloadDocument={ reloadDocument }
    >
      { children }
    </Link>
  );

  return renderAppLink();
}

AppLink.propTypes = {
  to: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.node,
  replace: PropTypes.bool,
  onClick: PropTypes.func,
  alt: PropTypes.string,
  style: PropTypes.object,
  itemProp: PropTypes.string,
  blank: PropTypes.bool,
  reloadDocument: PropTypes.bool
};

AppLink.defaultProps = {
  to: null,
  className: '',
  children: null,
  replace: false,
  onClick: null,
  alt: null,
  style: null,
  itemProp: null,
  blank: null,
  reloadDocument: false
};
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(withPGTranslation(AppLink));
