/* eslint-disable max-len */
import React from 'react';
import classes from '../classes.module.scss';

export const leftArrow = (
  <svg width="8" height="19" viewBox="0 0 8 19" fill="none" xmlns="http://www.w3.org/2000/svg" className={ classes.arrowImg }>
    <g filter="url(#filter0_d_1030_12130)">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.5647 0.206639C8.03673 0.557462 8.14109 1.23291 7.79779 1.71529L2.6135 9L7.79779 16.2847C8.14109 16.7671 8.03673 17.4425 7.5647 17.7934C7.09267 18.1442 6.43172 18.0375 6.08842 17.5552L0 9L6.08842 0.444847C6.43172 -0.0375337 7.09267 -0.144183 7.5647 0.206639Z" fill="#531FA5" />
    </g>
    <defs>
      <filter id="filter0_d_1030_12130" x="0" y="0" width="8" height="19" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1030_12130" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1030_12130" result="shape" />
      </filter>
    </defs>
  </svg>
);
