import React, {
  useEffect, useState, useRef, useCallback
} from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { add, formatISO } from 'date-fns';
import {
  textTruncate, decodeHtmlBracketCharCodes, renderCanonicalLink, getSectionForEvent,
  cleanupAdvisorFieldsForAnalytics
} from '../../config/util';
import AppLayout from '../shared/content';
import AdvisorHeader from './nested_views/advisor_header';
import classes from './classes.module.scss';
import { AdvisorPropType, AdvisorDefaultProps } from '../../reducers/advisor_prop_types';
import AdvisorMainContent from './nested_views/advisor_main_content/advisor_main_content';
import AdvisorStaticContainer from './nested_views/advisor_static_container';
import env from '../../config/variables';
import TerminatedAdvisor from './terminated_advisor';
import AffiliatePromoPopup from '../affiliate_promo_popup';
import { trackPageVisit } from '../../actions/analytics';
import UsePreviousValue, { isKA } from '../../../common/config/utils';
import AdvisorRecommendCarousel from './nested_views/advisors_recommend_carousel';
import { liveModeState, promotionLabelTypeScreens } from '../../config/const';
import PromotionLabel from '../home_page/nested_views/promotion_label';
import { updatePathTrailingSlashes } from '../routes/routes_helpers';

const NICKNAME = '<NICKNAME>';
const SERVICE_DESCRIPTION = '<SERVICE DESCRIPTION>';

const getMetaTagContent = () => (isKA() ? 'Kasamba' : 'PurpleGarden');

const getTitleMetaTags = (nickname, serviceTitle) => {
  if (!nickname || !serviceTitle) return null;
  if (isKA()) {
    return `${ nickname } - Psychic Reader - Ratings and Reviews`;
  }
  if (nickname.toLowerCase().includes('psychic') || serviceTitle.toLowerCase().includes('psychic')) {
    return `${ nickname } | ${ serviceTitle } - purplegarden.co`;
  }
  return `${ nickname } | ${ serviceTitle } - psychic readings - purplegarden.co`;
};

const getOGTitle = (serviceTitle, nickname, t) => {
  if (isKA()) return `${ nickname } - Psychic Reader - Ratings and Reviews`;
  return `${ serviceTitle } ${ t('app_meta_tag.page_title_advisor') } ${ nickname }`;
};

const getAdvisorDescriptionMetaTagText = (props) => {
  const { PGWEB_ADVISOR_DESCRIPTION_TAG } = env;
  const { serviceDescription, nickname } = props;
  return decodeHtmlBracketCharCodes(PGWEB_ADVISOR_DESCRIPTION_TAG)
    .replace(NICKNAME, nickname).replace(SERVICE_DESCRIPTION, serviceDescription);
};

const getMinPricePerMinute = (liveModes) => {
  if (!liveModes) return null;
  const prices = [];
  let minPricePerMinute = 0;
  liveModes?.forEach((mode) => {
    const modePrice = Number.parseFloat(mode.price);
    if (modePrice) prices.push(modePrice);
  });
  if (prices.length === 0) return minPricePerMinute.toFixed(2);
  minPricePerMinute = Math.min(...prices).toFixed(2);
  return minPricePerMinute;
};

const getSku = ({ id }) => {
  const skuPrefix = 'pur';
  const minLength = 5;
  const numberOnPart = 3;
  const addvisorId = parseInt(id, 10);
  let addvisorIdSkuString = addvisorId.toString();
  if (addvisorIdSkuString.length < minLength) {
    const delta = minLength - addvisorIdSkuString.length;
    let extra = '';
    for (let i = 0; i < delta; i += 1) {
      extra += '0';
    }
    addvisorIdSkuString = extra + addvisorIdSkuString;
  }
  const partArray = [];
  do {
    const lastPart = addvisorIdSkuString.substring(addvisorIdSkuString.length - numberOnPart);
    partArray.push(lastPart);
    const leftPart = addvisorIdSkuString.substring(0, addvisorIdSkuString.length - numberOnPart);
    addvisorIdSkuString = leftPart;
  }
  while (addvisorIdSkuString.length > 0);
  partArray.push(skuPrefix);
  partArray.reverse();
  const sku = partArray.join('/');
  return sku;
};

const getGtin8 = ({ id }) => {
  const advisorId = parseInt(id, 10);
  const gtin8 = 10000000 + advisorId;
  return gtin8.toString();
};

const removeMetaTags = (metaTagsName) => {
  metaTagsName.forEach((metaTagName) => {
    const metaTag = document.getElementById(metaTagName);
    if (metaTag) metaTag.remove();
  });
};

const updateSimilarAdvisors = (status, similarOnlineAdvisors, getSimilarOnlineAdvisors, id) => {
  if ((status === liveModeState.offline || status === liveModeState.busy) && similarOnlineAdvisors?.length > 0) getSimilarOnlineAdvisors(id);
};

const verifyBusyCarouselRender = (userId, advisorStatus, carouselAvailability, similarOnlineAdvisors) => (userId && (advisorStatus === liveModeState.busy || advisorStatus === liveModeState.offline)
&& carouselAvailability && similarOnlineAdvisors?.length > 0);

const onScroll = (setModesContainerVisible) => {
  const el = document.getElementById('advisorModesContainer');
  const position = el.getBoundingClientRect();
  if (position.bottom - 64 < 0) setModesContainerVisible(false);
  if (position.bottom - 64 >= 0) setModesContainerVisible(true);
};

const areArraysEqual = (arr1, arr2) => {
  if (arr1?.length !== arr2?.length) return false;
  return arr1.every((val, index) => val === arr2[index]);
};

const trackBusyCarouselEventIfNeeded = ({
  userId, advisorStatus, carouselAvailability, similarOnlineAdvisors, prevSimilarOnlineAdvisorsRef, trackBusyCarouselEvent
}) => {
  const prevSimilarOnlineAdvisors = prevSimilarOnlineAdvisorsRef.current;
  const similarOnlineAdvisorsIds = similarOnlineAdvisors?.map((adv) => adv.id);
  if (verifyBusyCarouselRender(userId, advisorStatus, carouselAvailability, similarOnlineAdvisors)) {
    if (!areArraysEqual(prevSimilarOnlineAdvisors, similarOnlineAdvisorsIds)) {
      trackBusyCarouselEvent('busy carousel shown', similarOnlineAdvisors);
    }
    prevSimilarOnlineAdvisorsRef.current = similarOnlineAdvisorsIds;
  }
};

function Advisor(props) {
  const {
    match:{ params: { slug } }, id, loadAdvisor, nickname, serviceTitle, profilePictureUrl,
    liveModes, specialities, t, errorCode, trackAdvisorProfileViewEvent, location, trackBusyCarouselEvent,
    trackBEvent, userId, loading, userLoading, similarOnlineAdvisors, analytics, status, getSimilarOnlineAdvisors
  } = props;
  const history = useNavigate();
  const [modesContainerVisible, setModesContainerVisible] = useState(true);
  const [userIdState, setUserIdState] = useState(userId);
  let appearedAt = null;
  const carouselAvailability = window.config.available_busy_carousel === 'true';
  const prevSimilarOnlineAdvisorsRef = useRef(null);

  const handleScroll = () => {
    onScroll(setModesContainerVisible);
  };

  const trackInitialEvents = (extraParams) => {
    trackAdvisorProfileViewEvent({
      ...getSectionForEvent({ location }), history, location, ...extraParams
    });
  };

  const isInitialMount = useRef(true);

  useEffect(() => {
    updateSimilarAdvisors(status, similarOnlineAdvisors, getSimilarOnlineAdvisors, id);
  }, [status]);

  useEffect(() => {
    trackBusyCarouselEventIfNeeded({
      userId, advisorStatus: status, carouselAvailability, similarOnlineAdvisors, prevSimilarOnlineAdvisorsRef, trackBusyCarouselEvent
    });
  }, [similarOnlineAdvisors]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      const analitycsPrams = {
        fromAdvisorProfile: true, analyticsActions: [trackInitialEvents], history, location
      };
      loadAdvisor(slug, analitycsPrams);
      trackBusyCarouselEventIfNeeded({
        userId, advisorStatus: status, carouselAvailability, similarOnlineAdvisors, prevSimilarOnlineAdvisorsRef, trackBusyCarouselEvent
      });
      trackPageVisit('advisor profile');
    }
  }, [slug]);

  useEffect(() => {
    if (id && nickname) {
      const params = cleanupAdvisorFieldsForAnalytics(props);
      appearedAt = (new Date()).getTime();
      trackBEvent('advisor profile - enter', params);
    }
    return () => {
      if (appearedAt) {
        const params = cleanupAdvisorFieldsForAnalytics(props);
        trackBEvent('advisor profile - leave', {  ...params, duration: (new Date()).getTime() - appearedAt });
      }
    };
  }, [id, nickname]);

  useEffect(() => {
    const analitycsPrams = {
      fromAdvisorProfile: true, analyticsActions: [trackInitialEvents], history, location
    };
    loadAdvisor(slug, analitycsPrams);
    window.scrollTo(0, 0);
    window.addEventListener('scroll', handleScroll);
    trackPageVisit('advisor profile');
    return () => {
      window.removeEventListener('scroll', handleScroll);
      // Remove metaTag
      const metaTagsName = ['metaTagOgImage', 'metaTagItemPropRatingCount', 'metaTagItemPropRatingValue', 'schemaOrgBrand', 'metaTagItemPropCategory', 'schemaOrgOffers'];
      removeMetaTags(metaTagsName);
    };
  }, []);

  useEffect(() => {
    if (userId !== userIdState) setUserIdState(userId);
    const prevUserId = <UsePreviousValue value={ userIdState } />;
    if (userId !== prevUserId.props.value && !userLoading && !loading) {
      loadAdvisor(slug);
    }
  }, [userId]);

  const renderAdvisorRecommendCarousel = useCallback(() => (verifyBusyCarouselRender(userId, status, carouselAvailability, similarOnlineAdvisors) ? (
    <AdvisorRecommendCarousel similarOnlineAdvisors={ similarOnlineAdvisors } loading={ loading } advisorId={ id } analytics={ analytics } />
  ) : null), [userId, status, carouselAvailability, similarOnlineAdvisors]);

  const renderPromoLabel = (
    <div className={ classes.promoLabelContainer }>
      <PromotionLabel className={ classes.promotionLabelContainerMobile } promotionLabelTypeScreen={ promotionLabelTypeScreens.advisorProfile } />
    </div>
  );

  useEffect(() => {
    const needReloadAdvisorPage = location.state?.needReloadAdvisorPage;
    if (needReloadAdvisorPage) {
      loadAdvisor(slug);
      history(`${ location.pathname }${ location.search }`, {
        state: { ...location.state, needReloadAdvisorPage: false },
        replace: true
      });
    }
  }, [location.state]);

  const renderContent = () => {
    if (errorCode === 410) return <TerminatedAdvisor advisorId={ id } />;
    return (
      <>
        <AdvisorHeader { ...props } />
        {renderAdvisorRecommendCarousel()}
        {renderPromoLabel}
        <AdvisorMainContent { ...props } />
        <AdvisorStaticContainer modesContainerVisible={ modesContainerVisible } { ...props } />
      </>
    );
  };

  return (
    <AppLayout
      topGradientColor="#F5F5F5"
      footerMobileBottomHeight={ 82 }
    >
      <AffiliatePromoPopup />
      <Helmet>
        <title>{ getTitleMetaTags(nickname, serviceTitle) }</title>
        <meta property="og:description" content={ textTruncate(getAdvisorDescriptionMetaTagText(props), 150, '...') } suppressHydrationWarning />
        <meta name="description" content={ textTruncate(getAdvisorDescriptionMetaTagText(props), 150, '...') } suppressHydrationWarning />
        <meta id="metaTagOgImage" property="og:image" content={ profilePictureUrl } suppressHydrationWarning />
        <meta property="og:title" content={ getOGTitle(serviceTitle, nickname, t) } suppressHydrationWarning />
      </Helmet>
      { renderCanonicalLink(updatePathTrailingSlashes(`${ window.location.origin }${ window.location.pathname }`)) }
      <div className={ classes.container } itemScope itemType="http://schema.org/Product" itemRef="product-name-for-ref-to">
        <meta itemProp="gtin8" content={ getGtin8({ id }) } suppressHydrationWarning />
        <div id="schemaOrgBrand" itemProp="brand" itemType="https://schema.org/Brand" itemScope>
          <meta id="metaTagItemPropBrand" itemProp="name" content={ getMetaTagContent() } suppressHydrationWarning />
        </div>
        <meta id="metaTagItemPropSku" itemProp="sku" content={ getSku({ id }) } suppressHydrationWarning />
        <meta id="metaTagItemPropCategory" itemProp="category" content={ `Psychic Readings > ${ (specialities[0] || {}).name }` } suppressHydrationWarning />
        <div id="schemaOrgOffers" itemProp="offers" itemScope itemType="https://schema.org/Offer">
          <meta id="metaTagItemPropPriceCurrency" itemProp="priceCurrency" content="USD" />
          <meta id="metaTagItemPropPrice" itemProp="price" content={ getMinPricePerMinute(liveModes) } suppressHydrationWarning />
          <meta id="metaTagItemPropUrl" itemProp="url" content={ `${ window.location.origin }${ window.location.pathname }` } suppressHydrationWarning />
          <meta id="metaTagItemPropAvailability" itemProp="availability" content="https://schema.org/OnlineOnly" />
          <meta id="metaTagItemPropPriceValidUntil" itemProp="priceValidUntil" content={ formatISO(add(new Date(), { days: 2 })) } suppressHydrationWarning />
        </div>
        { renderContent() }
      </div>
    </AppLayout>
  );
}

Advisor.propTypes = {
  ...AdvisorPropType,
  loadAdvisor: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  errorCode: PropTypes.number,
  trackAdvisorProfileViewEvent: PropTypes.func.isRequired,
  trackBEvent: PropTypes.func.isRequired,
  userId: PropTypes.number,
  userLoading: PropTypes.bool,
  getSimilarOnlineAdvisors: PropTypes.func.isRequired,
  trackBusyCarouselEvent: PropTypes.func.isRequired
};

Advisor.defaultProps = {
  ...AdvisorDefaultProps,
  errorCode: null,
  userId: null,
  userLoading: null
};

export default Advisor;
