import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formatISO, differenceInSeconds, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { timerEndConversation, pullConversation, trackSessionHireEventIfNeeded } from '../../actions/voip_chat';
import { isXfmProgram } from '../../config/util';

const checkTimerStop = (timerEndCall, callDurationNow, pullTimer, timer, xfmProgramActive, setXfmProgramActive) => {
  const callDuration = callDurationNow();
  if (xfmProgramActive && callDuration <= 0) {
    clearInterval(timer);
    setXfmProgramActive(false);
    return;
  }
  if (callDuration <= 3 && pullTimer) {
    clearInterval(pullTimer);
  }
  if (callDuration <= 0) {
    timerEndCall();
    clearInterval(timer);
  }
};

function CallDurationTimer({
  notifyDate, conversationOption, timerEndCall, pullCall, onCallDurationUpdate,
  advisorXfmProgram, userXfmProgram, updateXfmProgramActive, continuedSession,
  voipType, trackSessionHireEvent
}) {
  const [xfmProgramActive, setXfmProgramActive] = useState(continuedSession === 0 && isXfmProgram({ advisorXfmProgram, userXfmProgram }));
  const [seconds, setSecondsPast] = useState(null);

  let timer;
  let pullTimer;
  let secondsTimer;

  const getSecondsPast = () => {
    const dateNow = formatISO(new Date());
    const secondsPast = differenceInSeconds(parseISO(dateNow), parseISO(notifyDate));
    return secondsPast;
  };

  const callDurationNow = () => {
    const secondsPast = getSecondsPast();
    const newCallDurationNow = (conversationOption || {}).callDurationTimer - secondsPast;
    return newCallDurationNow;
  };

  useEffect(() => {
    updateXfmProgramActive(xfmProgramActive);
  }, [xfmProgramActive]);

  useEffect(() => {
    if (seconds && seconds >= conversationOption.freeSetupSeconds) {
      trackSessionHireEvent(voipType);
    }
  }, [seconds]);

  useEffect(() => {
    if (conversationOption) {
      pullTimer = setTimeout(() => {
        pullCall();
      }, conversationOption.pullConversationStatusTimer * 1000);
      timer = setInterval(() => {
        checkTimerStop(timerEndCall, callDurationNow, pullTimer, timer, xfmProgramActive, setXfmProgramActive);
        onCallDurationUpdate(callDurationNow());
      }, 500);
      secondsTimer =  setInterval(() => {
        setSecondsPast(getSecondsPast);
      }, 1000);
      return () => {
        clearInterval(timer);
        clearInterval(pullTimer);
        clearInterval(secondsTimer);
      };
    }
    return () => {};
  }, [conversationOption, xfmProgramActive]);

  return <div />;
}

CallDurationTimer.propTypes = {
  notifyDate: PropTypes.string,
  conversationOption: PropTypes.object,
  timerEndCall: PropTypes.func.isRequired,
  pullCall: PropTypes.func.isRequired,
  onCallDurationUpdate: PropTypes.func,
  advisorXfmProgram: PropTypes.object,
  userXfmProgram: PropTypes.object,
  advisorId: PropTypes.number.isRequired,
  updateXfmProgramActive: PropTypes.func.isRequired,
  continuedSession: PropTypes.number.isRequired,
  voipType: PropTypes.string.isRequired,
  trackSessionHireEvent: PropTypes.func.isRequired
};

CallDurationTimer.defaultProps = {
  notifyDate: null,
  conversationOption: null,
  onCallDurationUpdate: null,
  advisorXfmProgram: {},
  userXfmProgram: {}
};

const mapStateToProps = ({ voipChat, advisors, user: { user } }) => {
  const { advisorId } = voipChat;
  const advisor = (advisors || {})[parseInt(advisorId, 10)];
  return ({
    ...voipChat,
    ...voipChat.conversationOption,
    advisorXfmProgram: advisor && advisor.xfmProgram,
    userXfmProgram: user && user.xfmProgram
  });
};

const mapDispatchToProps = (dispatch) => ({
  timerEndCall: () => dispatch(timerEndConversation()),
  pullCall: () => dispatch(pullConversation()),
  trackSessionHireEvent: () => dispatch(trackSessionHireEventIfNeeded())
});

export default connect(mapStateToProps, mapDispatchToProps)(CallDurationTimer);
