/* eslint-disable object-property-newline */
import Const from '../config/const';
import { purchaseDetailsTypes } from '../../common/config/const';
import {
  createChatApi, hangupChatApi, continueChatApi,
  getPricingOptions
} from '../config/api';
import { toCamelCase } from '../../common/config/utils';
import { log, LOG_LEVELS } from '../../common/config/app_logger';
import {
  trackChatStartedEvent, trackDurationPickedMPEvent, StatusBusy, StatusHangUp,
  trackChatAttemptEvent, trackEventProfileButton, trackDurationPickExitdMPEvent, getFlow,
  trackSessionHireEvent,
  StatusSuccess
} from './analytics';
import {
  getDurationPickRelatedProps, isPayg, getDefaultDuration, getDurationOptions
} from '../config/util';
import { updatePurchaseDetails, clearPurchaseDetails } from './purchase_details';
import { loadAdvisor } from './advisors';
import { getAdvisorSlug } from '../project_items/helper_functions';

export const valiateUser = (user) => {
  const { gender, dateOfBirth, nickname } = user;
  return !(gender && dateOfBirth && nickname);
};

const continueChatActionCreator = (duration, price) => ({
  type: Const.chat.continueChat,
  payload: {
    duration,
    price
  }
});

export const pricingLoading = () => ({
  type: Const.chat.pricingLoading
});

export const loadPricingOptions = (type, advisorId) => (dispatch, getState) => {
  dispatch(pricingLoading());
  const { chat: { requestParams } } = getState();
  getPricingOptions(advisorId, { liveModes: type, ...requestParams }).then((res) => {
    const {
      chatPricingOptions, conversionEventId, selectDurationKind, user: { xfmProgram }, newUserPaygPricingOption, texts
    } = res;
    if (conversionEventId) dispatch(trackEventProfileButton(conversionEventId));
    dispatch({
      type: Const.chat.start,
      advisorId,
      pricingOptions: chatPricingOptions || [],
      selectDurationKind,
      xfmProgram,
      newUserPaygPricingOption,
      texts
    });
  });
};

export const chatInit = (
  advisorId,
  name,
  profilePictureUrl,
  pricePerMinute,
  startChatClickSource,
  requestParams
) => dispatch => {
  dispatch({
    type: Const.chat.init,
    advisorId,
    name,
    profilePictureUrl,
    pricePerMinute,
    startChatClickSource,
    requestParams
  });
};

export const startChat = (
  advisorId,
  pricePerMinute,
  profilePictureUrl,
  name,
  startChatClickSource,
  homepageSection,
  requestParams
) => ({
  type: Const.chat.start,
  advisorId,
  pricePerMinute,
  profilePictureUrl,
  name,
  startChatClickSource,
  homepageSection,
  requestParams
});

const doContinueChat = (orderId, duration, dispatch) => (continueChatApi(orderId, duration)
  .then(success => {
    log(LOG_LEVELS.INFO, 'Chat', 'everything is fine, chat should be continued now', success);
  })
  .catch(error => {
    // TODO: handle 404 - advisor is not in a live mode
    switch (error.status) {
      case 422: {
        error.json().then(jsonError => {
          const { fullMessages } = toCamelCase(jsonError);
          dispatch({
            type: Const.chat.generalError,
            errors: fullMessages
          });
        });
        break;
      }
      default: {
        dispatch({ type: Const.chat.unknownError, error: error.json() });
        log(LOG_LEVELS.ERROR, 'Chat', 'Error continuing the chat', error);
      }
    }
  })
);

const purchaseBack = continueFlow => ({
  type: Const.chat.purchaseBack,
  payload: {
    continueFlow
  }
});

const continueChat = (duration) => (dispatch, getState) => {
  const {
    chat: { orderId, pricingOptions }
  } = getState();

  const item = pricingOptions.find((i) => i.duration === duration);

  // Duration and Pirce need to be updated here in order to track
  // correct properties in chat started event
  dispatch(continueChatActionCreator(duration, item.price));
  doContinueChat(orderId, duration, dispatch);
};

export const purchaseOnBack = () => (dispatch, getState) => {
  const { chat: { orderId } } = getState();
  dispatch(purchaseBack(!!orderId));
  dispatch(clearPurchaseDetails());
};

const continueCall = (dispatch, getState) => {
  const { chat: { duration } } = getState();
  dispatch(continueChat(duration));
};

export const purchaseSuccess = () => (dispatch, getState) => {
  const { user: { user }, chat: { orderId } } = getState();

  if (orderId) {
    continueCall(dispatch, getState);
    return;
  }

  dispatch({
    type: Const.chat.paymentComplete,
    introduce: valiateUser(user)
  });
};

const durationSelected = ({
  duration, price, selectedCarouselIndex, user, hasEnoughtCredit, isContinue, isNewUser, flow
}) => ({
  type: Const.chat.durationSelected,
  duration,
  price,
  selectedCarouselIndex,
  introduce: valiateUser(user),
  isPayg: isPayg({ duration }),
  paymentRequired: !hasEnoughtCredit,
  onContinue: isContinue,
  isNewUser,
  flow
});

const trackDurationPickRelatedAction = ({
  option, time, defaultDurationChanged, mpEvent, dispatch, getState
}) => {
  const {
    price, duration, payAsYouGo, additional
  } = option;
  const {
    user: { user: { clientAvailableCredit } },
    chat: {
      advisorId, pricingOptions, selectDurationKind,
      continuedSession, homepageSection, startChatClickSource
    }
  } = getState();
  const displayVersion = selectDurationKind === Const.selectDurationKind.newUser ? 'version 1' : 'original';
  const defaultDuration = getDefaultDuration({ pricingOptions, payAsYouGo });
  const durationOptions = getDurationOptions({ pricingOptions, payAsYouGo });
  const hasEnoughtCredit = clientAvailableCredit >= price;
  const requiredAmount = hasEnoughtCredit ? null : { 'purchase required' : (price - clientAvailableCredit).toFixed(2) };
  const flow = getFlow({ additional, payAsYouGo });
  const durationPickRelatedProps = getDurationPickRelatedProps({
    requiredAmount, continuedSession, selectedDuration: duration, price, advisorId,
    homepageSection, startChatClickSource, displayVersion, defaultDuration, defaultDurationChanged,
    durationOptions, time, payAsYouGo, flow, additional
  });
  dispatch(mpEvent(Const.chatType.text, durationPickRelatedProps));
};

const trackDurationPicked = (option, time, defaultDurationChanged) => (dispatch, getState) => {
  trackDurationPickRelatedAction({
    option, time, defaultDurationChanged, mpEvent: trackDurationPickedMPEvent, dispatch, getState
  });
};

export const trackDurationPickExit = (option, time, defaultDurationChanged) => (dispatch, getState) => {
  trackDurationPickRelatedAction({
    option, time, defaultDurationChanged, mpEvent: trackDurationPickExitdMPEvent, dispatch, getState
  });
};

export const selectCredit = (option, time, defaultDurationChanged, isContinue) => (dispatch, getState) => {
  const {
    price, duration, selectedCarouselIndex, additional
  } = option;
  const {
    user: { user, user: { clientAvailableCredit } },
    chat: { advisorId }
  } = getState();
  const hasEnoughtCredit = clientAvailableCredit >= price;
  dispatch(trackDurationPicked(option, time, defaultDurationChanged));
  if (hasEnoughtCredit && isContinue) {
    dispatch(continueChat(duration));
    return;
  }
  const flow = getFlow({ additional, payAsYouGo: isPayg({ duration }) });
  dispatch(updatePurchaseDetails({
    clickSource: 'live chat', purchaseType: purchaseDetailsTypes.CHAT, details: {
      advisorId, duration, price, isPayg: isPayg({ duration }), flow
    }
  }));
  dispatch(durationSelected({
    duration, price, selectedCarouselIndex, user, hasEnoughtCredit, isContinue, option, isPayg: isPayg({ duration }), flow
  }));
};

export const selectDurationOption = (option, isContinue, isNewUser, index) => (dispatch, getState) => {
  const { price, duration, additional } = option;
  const { user: { user }, user: { clientAvailableCredit }  } = getState();
  const hasEnoughtCredit = clientAvailableCredit >= price;
  const flow = getFlow({ additional, payAsYouGo: isPayg({ duration }) });
  dispatch(durationSelected({
    duration,
    price,
    selectedCarouselIndex: index,
    user,
    hasEnoughtCredit,
    isContinue,
    option,
    isNewUser,
    flow
  }));
};

export const cancelChat = () => ({
  type: Const.chat.cancel
});

const trackMPBusyEventIdNeeded = (fullMessages, dispatch, getState) => {
  const { chat, user: { user } } = getState();
  fullMessages.forEach(element => {
    if (element.includes(StatusBusy)) {
      trackChatStartedEvent(Const.chatType.text, StatusBusy, chat, user, dispatch);
    }
  });
};

export const createChat = () => (dispatch, getState) => {
  dispatch({
    type: Const.chat.connect
  });
  const {
    chat: {
      pricePerMinute, duration, advisorId, startChatClickSource
    }, chat
  } = getState();
  trackChatAttemptEvent(Const.chatType.text, chat, dispatch);
  createChatApi(pricePerMinute, duration, advisorId, startChatClickSource)
    .then(config => {
      dispatch({
        type: Const.chat.connectProvider,
        config
      });
    })
    .catch(error => {
      switch (error.status) {
        case 404: {
          dispatch({
            type: Const.chat.notInLiveMode,
            error: 'Advisor is not in live mode anyomre'
          });
          break;
        }
        case 422: {
          error.json().then(jsonError => {
            const { fullMessages } = toCamelCase(jsonError);
            trackMPBusyEventIdNeeded(fullMessages, dispatch, getState);
            dispatch({
              type: Const.chat.cantCreate,
              errors: fullMessages
            });
          });
          break;
        }
        default: {
          dispatch({ type: Const.chat.unknownError, error: error.json() });
        }
      }
    });
};

const processAdminMessageIfNeed = () => ({ adminMessage }, dispatch, getState) => {
  if (!adminMessage) return;
  const { chat: { clientSummaryScreen, advisorId } } = getState();
  if (clientSummaryScreen) return;
  const { state: messageState, reason, ...details } = adminMessage;
  if (advisorId) {
    dispatch(loadAdvisor(getAdvisorSlug({ advisorId })));
  }
  dispatch({
    type: Const.chat.adminUpdate, state: messageState, details: toCamelCase(details), reason
  });
};

export const hangupChat = () => (dispatch, getState) => {
  dispatch({
    type: Const.chat.hangingUp
  });
  const { orderId, state } = getState().chat;
  // We need to keep the old state, because we need data from the old state
  // like duration, ppm etc. When the API request finishes then the state will be a fresh state,
  // so we can't track the MP event correctly
  const { chat, user: { user } } = getState();
  hangupChatApi(orderId).then((response) => {
    if (state === Const.chatStates.ringing) {
      trackChatStartedEvent(Const.chatType.text, StatusHangUp, chat, user, dispatch);
    }
    processAdminMessageIfNeed(response, dispatch, getState);
    log(LOG_LEVELS.INFO, 'Chat', 'is supposed to be ended');
  });
};

export const cancelChatOnPPActions = (isContinue) => ({
  type: Const.chat.cancelOnPP,
  isContinue
});

export const setNeedTrackSessionHireEventAvtion = (value) => ({
  type: Const.chat.setNeedTrackSessionHireEvent,
  value
});

export const trackSessionHireEventIfNeeded = () => (dispatch, getState) => {
  const { chat, user: { user } } = getState();
  const { needTrackSessionHireEvent } = chat;

  if (!needTrackSessionHireEvent) return;

  trackSessionHireEvent(Const.chatType.text, StatusSuccess, chat, user, dispatch);
  dispatch(setNeedTrackSessionHireEventAvtion(false));
};
