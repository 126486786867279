/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import PropTypes from 'prop-types';
import { popoverKinds } from '../../config/const';
import FiltersContainer from '../search_results/inner_views/filters';
import classes from './classes.module.scss';
import AppGeneralPopup from '../../../common/app_general_popup';
import { SvgIcon } from '../../../common/sprites';

const generatePopoverForKind = (kind, payload) => {
  const popovers = {
    [popoverKinds.filter]: (<FiltersContainer
      popoverView
      autofocus={ payload.autoFocus }
      dissmissPopover={ payload.dissmissPopover }
      history={ payload.history }
      location={ payload.location }
    />)
  };
  return popovers[kind];
};

function Popover({ kind, dissmissPopover, payload }) {
  const renderCloseBtn = () => (
    <div className={ classes.closeButtonContainer }>
      <button className={ classes.closeButton } type="button" onClick={ dissmissPopover }>
        <SvgIcon id="close_icon" size={ 22 } />
      </button>
    </div>
  );

  const bottomPopover = (children) => (
    <AppGeneralPopup
      visible={ !!kind }
      customTopHeader={ renderCloseBtn() }
      closable={ dissmissPopover }
    >
      <div className={ classes.popoverContainer }>
        {children}
      </div>
    </AppGeneralPopup>
  );

  return bottomPopover(
    generatePopoverForKind(kind, { ...payload, dissmissPopover })
  );
}

Popover.propTypes = {
  kind: PropTypes.string,
  dissmissPopover: PropTypes.func.isRequired,
  payload: PropTypes.object
};

Popover.defaultProps = {};

export default Popover;
