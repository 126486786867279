/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import classes from './classes.module.scss';
import { separateThousands } from '../../../../config/util';
import ExpandedText from '../../../../../common/helpers/expanded_text';

function ReadingSinceAndCountItem({
  readingsCount, yearJoined, t, theme, loading
}) {
  const renderContent = () => {
    if (loading) return <Skeleton width={ 170 } />;
    return (
      <ExpandedText ellipsis limit={ 2 } className={ classes[theme] } textStyle={ { wordBreak: 'initial' } }>
        <span className={ classes.countContainer } suppressHydrationWarning data-testid="readings-count">
          {separateThousands(readingsCount)}
        </span>
        <span className={ classes.readingsSinceContainer } suppressHydrationWarning data-testid="year-joined">
          {t('home_page.readings_since')}
          <span suppressHydrationWarning>
            {` ${ yearJoined || '' }`}
          </span>
        </span>
        <span className={ classes.readingsSinceMobileContainer } suppressHydrationWarning>
          {t('home_page.readings_since_mobile')}
          <span suppressHydrationWarning>
            {` (${ yearJoined || '' })`}
          </span>
        </span>
      </ExpandedText>
    );
  };
  return renderContent();
}

ReadingSinceAndCountItem.propTypes = {
  readingsCount: PropTypes.number,
  yearJoined: PropTypes.number,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  loading: PropTypes.bool
};

ReadingSinceAndCountItem.defaultProps = {
  readingsCount: null,
  yearJoined: null,
  loading: null
};

export default ReadingSinceAndCountItem;
