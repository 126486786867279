import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocalizedNavigate } from './hooks/useLocalizedNavigate';

const LocalizedNavigate = ({ pathKey, params, options }) => {
  const navigate = useLocalizedNavigate();

  useEffect(() => {
    navigate(pathKey, { params, options });
  }, [pathKey, params, options]);

  return <></>;
};

LocalizedNavigate.propTypes = {
  pathKey: PropTypes.string.isRequired,
  params: PropTypes.object,
  options: PropTypes.object
};

LocalizedNavigate.defaultProps = {
  params: {},
  options: {}
};

export default LocalizedNavigate;
