/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { Coupon } from '@barges/react';
import { Trans, useTranslation } from 'react-i18next';
import classes from './classes.module.scss';
import { minSalesPriceType } from '../../../config/const';
import { getDiscountPercent, oneHourTimer } from '../coupon_utils';
import { getAdvisorSlug } from '../../helper_functions';

import '@barges/scss/lib/coupon.css';
import '@barges/scss/lib/title.css';
import '@barges/scss/lib/icon.css';

interface DiscountOfferProps {
  discountCouponId: number,
  expiresAt: string
}

interface CouponLabelProps {
  discountOffer: DiscountOfferProps,
  userAdvisorModeMinSalesPrice?: string,
  type: 'small' | 'medium' |'large',
  advisorId: number,
  loadAdvisor: (slug: string) => void
}

const needRenderCouponLabel = ({ discountOffer, userAdvisorModeMinSalesPrice, leftSeconds }: { discountOffer: DiscountOfferProps, userAdvisorModeMinSalesPrice: string, leftSeconds?: number }) => {
  if (!discountOffer || Object.keys(discountOffer).length === 0 || userAdvisorModeMinSalesPrice !== minSalesPriceType.couponLiveModeMinSalesPrice) return false;
  if (!leftSeconds || leftSeconds <= 0) return null;
  return true;
};

const CouponLabel: React.FC<CouponLabelProps> = ({
  discountOffer, userAdvisorModeMinSalesPrice, type, loadAdvisor, advisorId
}) => {
  const { t } = useTranslation();
  const [leftSeconds, setLeftSeconds] = useState<number>(0);

  const timerEndAction = () => {
    if (advisorId) {
      loadAdvisor(getAdvisorSlug({ advisorId }));
    }
  };

  useEffect(() => {
    if (discountOffer?.expiresAt) {
      oneHourTimer({ expiresAt: discountOffer?.expiresAt, timerEndAction, setLeftSeconds });
    }
    return () => {};
  }, [discountOffer?.expiresAt]);

  const renderLeftHours = () => {
    const leftHours = Math.ceil(leftSeconds / 60 / 60);

    let content =  (
      <div className={ classes[`couponLabelHoursToRedeemContainer_${ type }`] }>
        <Trans
          i18nKey= "coupon.hours_to_redeem"
          values={ { leftHours } }
        />
      </div>
    );
    if (leftHours <= 1) {
      content = (
        <div className={ classes[`couponLabelOfferEndsIn1HoursToRedeemContainer_${ type }`] }>
          { t('coupon.offer_ends_in_1_hour') }
        </div>
      );
    }
    return  content;
  };

  const renderCouponLabel = () => {
    if (!needRenderCouponLabel({ discountOffer, userAdvisorModeMinSalesPrice, leftSeconds })) return null;
    return (
      <div className={ classes.couponLabelContainer }>
        <div className={ classes[`couponLabelIconContainer_${ type }`] }>
          <Coupon
            couponType={ type }
            active
            discountText={ `${ getDiscountPercent({ discountCouponId: discountOffer?.discountCouponId }) }${ t('coupon.percent_OFF') }` }
          />
        </div>
        {renderLeftHours()}
      </div>
    );
  };

  return renderCouponLabel();
};
export default CouponLabel;
