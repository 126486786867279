import { connect } from 'react-redux';
import { clearApplicationError } from '../../actions/aplication_error';
import { logOutClear, displayRegister } from '../../actions/auth';

import RoutesComponent from './routes';

const mapStateToProps = ({
  chat, applicationError, voipChat, popover, user
}) => ({
  ...chat, ...applicationError, voipState: voipChat.voipState, popoverVisibility: !!popover.kind, user: user?.user
});

export default connect(
  mapStateToProps,
  { clearApplicationError, logOutClear, displayRegister }
)(RoutesComponent);
