import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-dom-last-location';
import NavigationStackBar from './navigation_stack_bar';
import withPGTranslation from '../../config/withPGTranslation';

import { loadMyOrders } from '../../actions/my_orders';
import { resetAdvisorOrders } from '../../actions/advisor_orders';
import { getRouteSettings } from '../../config/util';

const mapStateToProps = ({
  application
}, ownProps) => {
  const { currentPath: { routeKey } } = ownProps;
  return ({
    ...application,
    routeSettings: getRouteSettings({ routeKey })
  });
};

export default withLastLocation(withPGTranslation(connect(
  mapStateToProps,
  { loadMyOrders, resetAdvisorOrders }
)(NavigationStackBar)));
