import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import { rateReviewTheme } from '../../config/const';
import { SvgIcon } from '../../../common/sprites';
import { isPS } from '../../../common/config/utils';

const convertValue = value => {
  if (value === true) {
    return 1;
  } if (value === false) {
    return 0;
  }
  return 2;
};

function FeedbackSwitcher({ value, onValueChanged, theme }) {
  return (
    <div className={ classes.feedbackSwitcherContainer }>
      <div>
        <button
          type="button"
          onClick={ () => {
            onValueChanged(false);
          } }
          className={ classes[`negativeFeedbackButton${ theme }`] }
          style={ { backgroundColor: convertValue(value) === 0 ? 'var(--app-negative-feedback-btn-color)' : '#ffffff00' } }
        >
          <SvgIcon
            className={ classes[`smileyIcon${ theme }`] }
            id={ !isPS() && convertValue(value) === 0 ? 'rate_negative_selected' : 'rate_negative' }
          />
        </button>
      </div>
      <div>
        <button
          type="button"
          onClick={ () => {
            onValueChanged(true);
          } }
          className={ classes[`positiveFeedbackButton${ theme }`] }
          style={ { backgroundColor: convertValue(value) === 1 ? 'var(--app-positive-feedback-btn-color)' : '#ffffff00' } }
        >
          <SvgIcon
            className={ classes[`smileyIcon${ theme }`] }
            id={ !isPS() && convertValue(value) === 1 ? 'rate_positive_selected' : 'rate_positive' }
          />
        </button>
      </div>
    </div>
  );
}

FeedbackSwitcher.propTypes = {
  onValueChanged: PropTypes.func.isRequired,
  value: PropTypes.bool,
  theme: PropTypes.string
};

FeedbackSwitcher.defaultProps = {
  value: null,
  theme: rateReviewTheme.DEFAULT
};

export default FeedbackSwitcher;
