/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import { isKA, isPS } from '../../../common/config/utils';

interface ContextRendererProps {
  componentName: string,
  params: object
}

const ContextRenderer: React.FC<ContextRendererProps> = ({ componentName, params }) => {
  const getContextRenderer =  () => {
    const componentNameToLowerCase = componentName.toLowerCase();
    let projectPrefix = 'pg';
    if (isKA()) {
      projectPrefix = 'ka';
    }
    if (isPS()) {
      projectPrefix = 'ps';
    }

    const ComponentModule = require(`../${ componentNameToLowerCase }/${ projectPrefix }/${ componentNameToLowerCase }.tsx`).default;
    return <ComponentModule props={ params } />;
  };

  return getContextRenderer();
};

export default ContextRenderer;
