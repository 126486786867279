import PropTypes from 'prop-types';

export const SpecialityPropType = {
  id: PropTypes.number,
  name: PropTypes.string,
  analyticsName: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  iconHomeUrl: PropTypes.string,
  iconDetailsUrl: PropTypes.string
};

export const ReviewPropType = {
  like: PropTypes.bool,
  comment: PropTypes.string,
  createdAt: PropTypes.string,
  buyerNickname: PropTypes.string
};
export const FavoriteData = {
  availability: PropTypes.bool,
  availabilityOnce: PropTypes.bool,
  videChat: PropTypes.bool,
  videoChatOnce: PropTypes.bool,
  voiceCall: PropTypes.bool,
  voiceCallOnce: PropTypes.bool,
  textChat: PropTypes.bool,
  textChatOnce: PropTypes.bool
};

export const availabilityNotificationType = {
  subtitle: PropTypes.string,
  switchedOn: PropTypes.bool,
  title: PropTypes.string
};

const ReviewItemsropType = {
  items: PropTypes.arrayOf(PropTypes.shape(ReviewPropType))
};

export const LiveModePropType = {
  mode: PropTypes.string,
  name: PropTypes.string,
  modeState: PropTypes.string,
  price: PropTypes.string,
  salesPrice: PropTypes.string,
  promoPrices: PropTypes.object
};

export const LiveModeDefaultProps = {
  mode: '',
  name: '',
  modeState: '',
  price: '',
  salesPrice: '',
  promoPrices: {}
};

export const AdvisorPropType = {
  id: PropTypes.number,
  status: PropTypes.string,
  nickName: PropTypes.string,
  serviceTitle: PropTypes.string,
  serviceDescription: PropTypes.string,
  instructions: PropTypes.string,
  aboutMe: PropTypes.string,
  profileVideoThumbUrl: PropTypes.string,
  profileVideoUrl: PropTypes.string,
  advisorShareLink: PropTypes.string,
  badges:PropTypes.array,
  favorite: PropTypes.bool,
  readingsCount: PropTypes.number,
  yearJoined: PropTypes.number,
  rating: PropTypes.number,
  isNewAdvisor: PropTypes.bool,
  accuracyPercent: PropTypes.number,
  accuracyReviewersCount: PropTypes.number,
  likesCount: PropTypes.number,
  dislikesCount: PropTypes.number,
  liveModes: PropTypes.arrayOf(PropTypes.shape(LiveModePropType)),
  favoriteData: PropTypes.shape(FavoriteData),
  specialities: PropTypes.arrayOf(PropTypes.shape(SpecialityPropType)),
  reviews: PropTypes.shape(ReviewItemsropType),
  availabilityNotification: PropTypes.shape(availabilityNotificationType),
  analytics: PropTypes.object,
  similarOnlineAdvisors: PropTypes.array
};

export const AdvisorDefaultProps = {
  id: null,
  status: '',
  nickName: '',
  serviceTitle: '',
  serviceDescription: '',
  instructions: '',
  aboutMe: '',
  profileVideoThumbUrl: '',
  profileVideoUrl: '',
  advisorShareLink: '',
  favorite: false,
  readingsCount: null,
  yearJoined: null,
  rating: null,
  isNewAdvisor: false,
  accuracyPercent: null,
  accuracyReviewersCount: null,
  likesCount: null,
  dislikesCount: null,
  badges:[],
  liveModes: [],
  favoriteData: null,
  specialities: [],
  reviews: null,
  availabilityNotification: null,
  analytics: null,
  similarOnlineAdvisors: []
};
