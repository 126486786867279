import React from 'react';
import Skeleton from 'react-loading-skeleton';
import classes from './advisor_preview/classes.module.scss';
import { getXfmFreeSetupMin } from '../../../config/util';
import { SvgIcon } from '../../../../common/sprites';

interface SkeletonOrItemProps {
    item?: string | null;
    skeletonWidth?: number | null;
    maxChars?: number | null;
  }

export const skeletonOrItem = ({ item, skeletonWidth, maxChars }: SkeletonOrItemProps) => {
  if (item) {
    return (maxChars ? item.substring(0, maxChars) : item);
  }
  return <Skeleton width={ skeletonWidth } />;
};

export const renderFreeMinutesLabel = (t:(text:string)=>string) => (
  <div className={ classes.wrapperFreeMinutes }>
    <div className={ classes.advisorFreeMinutes }>
      <SvgIcon id="3fm_image" title="3fm" size={ 14 } />
      {skeletonOrItem({ item: `${ getXfmFreeSetupMin() } ${ t('free_minutes.free_min_label') }`, skeletonWidth: 100 }) as React.ReactNode}
    </div>
  </div>
);
