import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import classes from './classes.module.scss';
import AppGeneralPopup from '../../../../common/app_general_popup';
import { appGeneralPopupTheme } from '../../../../common/config/const';
import { GDPRConsernPopupThemes } from '../../../config/themes';
import { appLogo } from '../../../logo/logo';
import env from '../../../config/variables';
import { updateTcpBanner } from '../../../config/api';
import { parseAndTrackAnalyticEvents } from '../../../actions/analytics';

const {
  TCP_BANNER
} = env;

const termsAndPoliciesUpdateViewedEvent = (tcpBanner, termsAndPpUpdateViewed, termsAndPoliciesUpdateViewed) => {
  if (tcpBanner && termsAndPpUpdateViewed && termsAndPoliciesUpdateViewed) {
    parseAndTrackAnalyticEvents({ termsAndPoliciesUpdateViewed });
  }
};

const handleLinkClick = (url, setPrivacyPolicyClicked, setTermsOfUseClicked) => {
  if (url?.includes('privacy')) {
    setPrivacyPolicyClicked(true);
  } else setTermsOfUseClicked(true);
};

function GDPRConsernPopup({
  GDPRConsernPopupVisible, gdprSwitcherValueChanged, shouldShowGDPRConsent, theme,
  socialParams, authSocial, clearError, tcpBanner, onRegisterClicked, termsAndPoliciesUpdateViewed
}) {
  const [termsOfUseClicked, setTermsOfUseClicked] = useState(false);
  const [privacyPolicyClicked, setPrivacyPolicyClicked] = useState(false);
  const [termsAndPpUpdateViewed, setTermsAndPpUpdateViewed] = useState(false);

  const hideGDPRConsernPopup = () => {
    shouldShowGDPRConsent({ [theme]: false });
  };

  useEffect(() => {
    if (tcpBanner) {
      shouldShowGDPRConsent({ [GDPRConsernPopupThemes.server]: true });
      setTermsAndPpUpdateViewed(true);
    }
  }, [tcpBanner]);

  useEffect(() => {
    termsAndPoliciesUpdateViewedEvent(tcpBanner, termsAndPpUpdateViewed, termsAndPoliciesUpdateViewed);
  }, [termsAndPpUpdateViewed]);

  const serverContentBtnOnClick  = () => {
    const properties = {
      'terms of use clicked': termsOfUseClicked,
      'privacy policy clicked': privacyPolicyClicked
    };
    updateTcpBanner().then((payload) => {
      parseAndTrackAnalyticEvents(payload.analyticEvents, properties);
    });
    hideGDPRConsernPopup();
    gdprSwitcherValueChanged(true);
  };

  const regularContentBtnOnClick = () => {
    gdprSwitcherValueChanged(true);
    onRegisterClicked();
    hideGDPRConsernPopup();
  };

  const socialContinueOnClick = () => {
    clearError();
    gdprSwitcherValueChanged(true);
    authSocial(socialParams.socRequest, socialParams.signupFlow);
    hideGDPRConsernPopup();
  };

  const serverContentLinkOnClick = (href) => {
    if (href) {
      handleLinkClick(href, setPrivacyPolicyClicked, setTermsOfUseClicked);
    }
  };

  const renderServerContent = () => (
    <div className={ classes.ppTofModalSignUpContent }>
      <img src={ appLogo() } width={ 60 } height={ 60 } alt="" />
      <div className={ classes.ppTofModalSignUpHeader }>
        {tcpBanner?.header || ''}
      </div>
      <div className={ classes.tcpBannerText }>
        <ReactMarkdown
          components={ {
            a: ({ href, children, ...props }) => (
              <a
                href={ href }
                target="_blank"
                rel="noopener noreferrer"
                { ...props }
                onClick={ () => serverContentLinkOnClick(href) }
              >
                {children}
              </a>
            )
          } }
        >
          {tcpBanner?.body || ''}
        </ReactMarkdown>
      </div>
      <div className={ classes.tcpBannerText }>
        <ReactMarkdown>{tcpBanner?.description || ''}</ReactMarkdown>
      </div>
      <div>
        <button
          type="button"
          onClick={ serverContentBtnOnClick }
          className={ classes.ppTofModalSignUpButton }
        >
          {tcpBanner?.button || ''}
        </button>
      </div>
    </div>
  );

  const renderRegularContent = () => (
    <div className={ classes.ppTofModalSignUpContent }>
      <img src={ appLogo() } width={ 60 } height={ 60 } alt="" />
      <div className={ classes.ppTofModalSignUpHeader }>
        <ReactMarkdown>{TCP_BANNER.header}</ReactMarkdown>
      </div>
      <div>
        <div className={ classes.tcpBannerBodyText }>
          <ReactMarkdown
            components={ {
              a: ({ href, children, ...props }) => (
                <a
                  href={ href }
                  target="_blank"
                  rel="noopener noreferrer"
                  { ...props }
                >
                  {children}
                </a>
              )
            } }
          >
            {TCP_BANNER.body || ''}
          </ReactMarkdown>
        </div>
      </div>
      <div>
        <ReactMarkdown>{TCP_BANNER.description || ''}</ReactMarkdown>
      </div>
      <div>
        <button
          type="button"
          onClick={ theme === GDPRConsernPopupThemes.social ? socialContinueOnClick : regularContentBtnOnClick }
          className={ classes.ppTofModalSignUpButton }
        >
          {TCP_BANNER.button}
        </button>
        <button
          type="button"
          onClick={ hideGDPRConsernPopup }
          className={ classes.ppTofModalCancelButton }
        >
          {TCP_BANNER.cancel}
        </button>
      </div>
    </div>
  );

  const renderModalContent = () => {
    if (theme === GDPRConsernPopupThemes.server) return renderServerContent();
    return renderRegularContent();
  };

  const renderGDPRConsernPopup = () => (
    <AppGeneralPopup
      visible={ (GDPRConsernPopupVisible && GDPRConsernPopupVisible[theme]) || false }
      theme={ appGeneralPopupTheme.CENTERED }
    >
      {renderModalContent()}
    </AppGeneralPopup>
  );

  return renderGDPRConsernPopup();
}

GDPRConsernPopup.propTypes = {
  GDPRConsernPopupVisible: PropTypes.object,
  tcpBanner: PropTypes.object,
  gdprSwitcherValueChanged: PropTypes.func.isRequired,
  shouldShowGDPRConsent: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  socialOriginOnClick: PropTypes.func,
  authSocial: PropTypes.func,
  onRegisterClicked: PropTypes.func,
  termsAndPoliciesUpdateViewed: PropTypes.object
};

GDPRConsernPopup.defaultProps = {
  GDPRConsernPopupVisible: null,
  socialOriginOnClick: null,
  tcpBanner: null,
  authSocial: null,
  onRegisterClicked: null,
  termsAndPoliciesUpdateViewed: null
};
export default GDPRConsernPopup;
