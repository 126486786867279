import React  from 'react';
import { SvgIcon } from '../../../common/sprites';

interface MarketingImageProps {
  offers: Array<{subtitle: string; title: string}>,
}

const MarketingImage:React.FC<MarketingImageProps> = ({ offers }) => {
  const renderMarketingImage = () => {
    if (offers?.length > 1) return <SvgIcon size={ [69, 76] } id="marketing_img_2_items"  />;
    return <SvgIcon size={ [136, 68] } id="marketing_img_1_item" />;
  };

  return renderMarketingImage();
};

export default MarketingImage;
