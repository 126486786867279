import React from 'react';
import classes from './classes.module.scss';
import Const from '../../config/const';
import { SvgIcon } from '../../../common/sprites';

const getDiscountLabelId = ({ discountData }) => {
  let icon;
  switch (discountData?.kind) {
    case Const.discountDataKind.discountApplied:
      icon = 'discount_applied_icon';
      break;
    case Const.discountDataKind.maxDiscountApplied:
      icon = 'max_discount_applied_icon';
      break;
    case Const.discountDataKind.advisorSpecialOffer:
      icon = 'advisor_special_offer_icon';
      break;
    default:
      break;
  }
  return icon;
};

const getDiscountLabelText = ({ discountData, t }) => {
  let text;
  switch (discountData?.kind) {
    case Const.discountDataKind.discountApplied:
      text = t('chat.discount_applied');
      break;
    case Const.discountDataKind.maxDiscountApplied:
      text = t('chat.max_discount_applied');
      break;
    case Const.discountDataKind.advisorSpecialOffer:
      text = t('chat.advisor_special_offer_applied');
      break;
    default:
      break;
  }
  return text;
};

const renderDiscountLabel = ({ discountData, t }) => {
  if (!discountData || !discountData?.description) return null;
  return (
    <div className={ classes.discountLabelContainer }>
      <SvgIcon id={ getDiscountLabelId({ discountData }) } className={ classes.discountLabelImg } />
      <span className={ classes.discountLabelText }>
        { getDiscountLabelText({ discountData, t }) }
          &nbsp;
        { discountData?.description }
      </span>
    </div>
  );
};

const getCallWithText = ({ voipType, t }) => {
  if (voipType === Const.chatType.voice) return t('chat.voice_call_with');
  if (voipType === Const.chatType.video) return t('chat.video_call_with');
  return null;
};

export const renderNameAndDiscountLabel = ({
  voipType, discountData, name, t
}) => (
  <div className={ classes.nameAndDiscountLabelContainer }>
    <div className={ classes.callWithContainer }>
      { getCallWithText({ voipType, t }) }
    </div>
    <div className={ classes.nameText }>
      { name }
    </div>
    { renderDiscountLabel({ discountData, t }) }
  </div>
);
