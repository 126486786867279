import React, { useEffect } from 'react';
import { propertiesNames } from '../../../config/const';

const colors = [
  { name: propertiesNames.appColor, value: '#B45092' },
  { name: propertiesNames.backgroundSecondary, value: '#F7F7F7' },
  { name: propertiesNames.liveChatChannels, value: '#2BA4FA' },
  { name: propertiesNames.voiceCallChannels, value: '#B45092' },
  { name: propertiesNames.borderPrimary, value: '#E1E1E1' },
  { name: propertiesNames.appOverlayBlackColor, value: '#000000cc' },

  { name: propertiesNames.smartBannerBackground, value: 'radial-gradient(circle at 50% 50%, #9a6bff, #9a6bff 29%, #622bd8 72%)' },
  { name: propertiesNames.noPaymentMethodImgWidth, value: '185px' },
  { name: propertiesNames.connectBtnMaxWidth, value: '200px' },
  { name: propertiesNames.specialitiesShadow, value: 'none' },
  { name: propertiesNames.advisprSpecialitiesMobileDirection, value: 'row' },
  { name: propertiesNames.durationOptionXfmLabelColor, value: '#2BA4FA' },
  { name: propertiesNames.durationItemPriceContainerBackgroundColor, value: '#FFFFFF' },
  { name: propertiesNames.durationItemPriceContainerBorderRadius, value: '50px' },
  { name: propertiesNames.durationItemSelectedPriceContainerBackgroundColor, value: '#F7F7F7' },
  { name: propertiesNames.advisorPreviewXfmLabelBackground, value: 'linear-gradient(94deg, #2BA4FA 0%, #FF82D4 100%)' },
  { name: propertiesNames.advisorPreviewXfmLabelBorderRadius, value: '6px' },
  { name: propertiesNames.advisorBusyColor, value: '#FA4EC0' },

  { name: '--mbw-branding-primary', value: '#B45092' },
  { name: '--mbw-branding-secondary', value: '#2BA4FA' },
  { name: '--mbw-background-primary', value: '#FFFFFF' },
  { name: '--mbw-background-secondary', value: '#F7F7F7' },
  { name: '--mbw-background-tertiary', value: '#D0D0D0' },
  { name: '--mbw-background-overlay', value: '#000000cc' },
  { name: '--mbw-text-primary', value: '#121212' },
  { name: '--mbw-text-secondary', value: '#707070' },
  { name: '--mbw-text-tertiary', value: '#FFFFFF' },
  { name: '--mbw-default-border', value: '#E1E1E1' },
  { name: '--mbw-icon-primary', value: '#121212' },
  { name: '--mbw-icon-secondary', value: '#9C9C9C' },
  { name: '--mbw-icon-tertiary', value: '#FFFFFF' },
  { name: '--mbw-button-border-radius', value: '3.125rem' },
  { name: '--mbw-button-primary', value: '#B45092' },
  { name: '--mbw-button-primary-hover', value: '#BE579B' },
  { name: '--mbw-button-primary-dissabled', value: '#C3C5CA' },
  { name: '--mbw-button-secondary', value: '#121212' },
  { name: '--mbw-button-secondary-hover', value: '#BE579B' },
  { name: '--mbw-button-secondary-disabled', value: '#C3C5CA' },
  { name: '--mbw-chat-color', value: '#2BA4FA' },
  { name: '--mbw-voice-color', value: '#B45092' },
  { name: '--mbw-error-color', value: '#FA4EC0' },
  { name: '--mbw-warning-color', value: '#FFA72C' },
  { name: '--mbw-info-color', value: '#2BA4FA' },
  { name: '--mbw-success-color', value: '#4BCB78' },
  { name: '--mbw-muted-color', value: '#9C9C9C' },
  { name: '--mbw-general-light', value: '#FFFFFF' },
  { name: '--mbw-general-dark', value: '#27292F' },
  { name: '--mbw-default-shadow-color', value: '#F7F7F7' },
  { name: '--mbw-red-accent-color', value: '#FA4EC0' },
  { name: '--mbw-orange-accent-color', value: '#FF8717' },
  { name: '--mbw-yellow-accent-color', value: '#FFA850' },
  { name: '--mbw-green-accent-color', value: '#4BCB78' },
  { name: '--mbw-sky-blue-accent-color', value: '#1CB4FF' },
  { name: '--mbw-blue-accent-color', value: '#4F60FF' },
  { name: '--mbw-purple-accent-color', value: '#8F49D3' },
  { name: '--mbw-possitive-accent-color', value: '#4BCB78' },
  { name: '--mbw-negative-accent-color', value: '#FA4EC0' },

  { name: '--wbw-marketing-banner-first-color', value: '#2BA4FA' },
  { name: '--mbw-marketing-banner-second-color', value: '#FF82D4' },
  { name: '--mbw-marketing-banner-rotation', value: '93deg' },

  { name:'--mbw-speciality-button-padding', value: '0.313rem 0.313rem 1rem 0.313rem' },
  { name:'--mbw-speciality-button-mobile-padding', value: '0.313rem 0.313rem 1rem 0.313rem' },
  { name: '--mbw-duration-item-selected-fg-color', value: '#B45092' },
  { name: '--mbw-duration-item-selected-bg-color', value: '#FFFFFF' },
  { name: '--mbw-select-duration-arrows-color', value: '#B45092' },
  { name: '--mbw-duration-item-price-bg-color', value: '#F7F7F7' },
  { name: '--mbw-duration-item-price-fg-color', value: '#121212' },
  { name: '--mbw-duration-item-selected-price-fg-color', value: '#B45092' },
  { name: '--mbw-speciality-bg-color', value: '#F7F7F7' },
  { name: '--mbw-speciality-advisor-profile-icon-margin-bottom', value: '8px' },
  { name: '--mbw-speciality-button-icon-margin-bottom', value: '0' },
  { name: '--mbw-speciality-button-paddings', value: '1rem 0.625rem 1.125rem 0.625em' },
  { name: '--mbw-speciality-button-title-width', value: 'fit-content' },
  { name: '--mbw-speciality-advisor-profile-title-width', value: 'fit-content' },
  { name: '--mbw-speciality-advisor-profile-width', value: 'min-content' },
  { name: '--mbw-speciality-advisor-profile-mobile-icon-width', value: '5.3rem' },
  { name: '--mbw-speciality-advisor-profile-mobile-icon-height', value: '5.3rem' },

  { name: '--mbw-duration-item-row-gap-lg', value: '0rem' },
  { name: '--app-default-btn-lg-border-radius', value: '3.125rem' },
  { name: '--app-default-btn-md-border-radius', value: '1.563rem' },
  { name: '--app-default-btn-sm-border-radius', value: '1.563rem' },
  { name: '--app-default-btn-xs-border-radius', value: '0.7815rem' },
  { name: '--app-online-indicator-color', value: '#4BCB78' },
  { name: '--app-busy-indicator-color', value: '#FA4EC0' },
  { name: '--app-offline-indicator-color', value: '#9C9C9C' },
  { name: '--app-badge-border-radius', value: '1.563rem' },
  { name: '--app-payment-sources-border-radius', value: '0.938rem' },

  { name: '--app-blue-snap-input-border-color', value: 'var(--app-border-primary-color)' },
  { name: '--app-blue-snap-placeholder-text-color', value: 'var(--app-text-secondary)' },
  { name: '--app-blue-snap-input-border-focus-color', value: 'var(--app-color)' },
  { name: '--app-blue-snap-input-error-color', value: '#FA4EC0' },
  { name: '--app-blue-snap-input-border-radius', value: '6px' },

  { name: '--coupon-bg-color-large-active', value: 'linear-gradient(90deg, #B45092 22.1%, #2BA4FA 100%)' },
  { name: '--app-busy-label-color', value: '#FA4EC0' },
  { name: '--app-positive-feedback-btn-color', value: '#F7F7F7' },
  { name: '--app-negative-feedback-btn-color', value: '#F7F7F7' },
  { name: '--app-new-advisor-badge-color', value: '#4BCB78' },
  { name: '--app-filter-switcher-color', value: '#4BCB78' },
  { name: '--app-personal-section-border-color', value: '#4BCB78' },
  { name: '--app-sale-price-text-color', value: '#4BCB78' },
  { name: '--mbw-status-indicator-online-color', value: '#4BCB78' },
  { name: '--app-rating-bg-color', value: '#F7F7F7' },
  { name: '--app-rating-on-img-bg-color', value: '#FFFFFF' },
  { name: '--mbw-font-family', value: '"Fira Sans"' }
];

const MBWColors = () => {
  useEffect(() => {
    colors.forEach((prop) => document.documentElement.style.setProperty(prop.name, prop.value));
  }, []);
  return (<div />);
};
export default MBWColors;
