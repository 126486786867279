import React from 'react';
import PropTypes from 'prop-types';
import AppLink from '../../../routes/app_link';
import { SectionProps, SectionDefaultProps } from '../../prop_types';
import Rating from '../../../advisor_avatar/rating/rating';
import classes from './classes.module.scss';
import AdvisorAvatar, { advisorThemes } from '../../../advisor_avatar';
import AdvisorModes from '../advisor_modes';
import ReadingSinceAndCountItem, { readingSinceAndCountItemThemes } from '../../../advisor/nested_views/readingSinceAndCountItem';
import ExpandedText from '../../../../../common/helpers/expanded_text';
import { AdvisorModesThemes } from '../../../../config/const';
import PriceText from '../price_text';
import { isKA } from '../../../../../common/config/utils';

const handleLinkClickAction = ({
  e, loading, sectionSlug, updateNavigationStack
}) => {
  if (loading) {
    e.preventDefault();
    return;
  }
  if (sectionSlug) { updateNavigationStack({ dataKey: sectionSlug }); }
};

const getObjectValue = (object) => object || {};

const renderLeftSideFooter = (liveModeMinPrice, liveModeMinPromoPrices, liveModes, theme, text) => (isKA()
  ? (
    <PriceText
      liveModeMinPrice={ liveModeMinPrice }
      liveModeMinPromoPrices={ liveModeMinPromoPrices }
      text={ text }
      theme={ theme }
      liveModes={ liveModes }
    />
  )
  : (
    <AdvisorModes
      liveModeMinPrice={ liveModeMinPrice }
      liveModes={ liveModes }
      liveModeMinPromoPrices={ liveModeMinPromoPrices }
      text={ text }
      theme={ theme }
    />
  )
);

function StaffPickSection({
  iconUrl, title, analytics, content, t, onAnalyticsClick, loading, sectionSlug, updateNavigationStack
}) {
  const { advisor } = getObjectValue(content);
  const {
    liveModeMinPrice, nickname, serviceTitle, rating, staffPickText, readingsCount, yearJoined,
    liveModes, liveModeMinPromoPrices, slug, isNewAdvisor
  } = getObjectValue(advisor);

  const advisorHeader = () => (
    <div className={ classes.advisorInfoHeader }>
      <div className={ classes.advisorTitle }>
        {nickname}
        <div className={ classes.advisorSubtitle }>
          {serviceTitle}
        </div>
      </div>
      <Rating rating={ rating } isNewAdvisor={ isNewAdvisor } />
    </div>
  );

  const onDescriptionExpand = (e) => {
    e.preventDefault();
  };

  const staffPickDescription = () => (
    <div className={ classes.staffPickDescription }>
      <ReadingSinceAndCountItem
        readingsCount={ readingsCount }
        yearJoined={ yearJoined }
        theme={ readingSinceAndCountItemThemes.staffPickMobile }
      />
      <ExpandedText
        className={ classes.serviceTitleContainer }
        ellipsis
        limit={ 4 }
        readMoreKind="limitTextByRows"
        readMoreClick={ onDescriptionExpand }
        t={ t }
      >
        {staffPickText}
      </ExpandedText>
    </div>
  );

  const handleLinkClick = (e) => {
    if (onAnalyticsClick) { onAnalyticsClick(); }
    handleLinkClickAction({
      e, loading, sectionSlug, updateNavigationStack
    });
  };

  const footer = () => (
    <div
      className={ `${ classes.footer } ${ isKA() ? classes.v2 : '' }` }
    >
      {renderLeftSideFooter(liveModeMinPrice, liveModeMinPromoPrices, liveModes, AdvisorModesThemes.staffPick, t('home_page.from'))}
      <ReadingSinceAndCountItem
        readingsCount={ readingsCount }
        yearJoined={ yearJoined }
        theme={ readingSinceAndCountItemThemes.staffPick }
      />
      {isKA() && (
        <AdvisorModes
          liveModes={ liveModes }
          theme={ AdvisorModesThemes.staffPick }
          showPrice={ false }
        />
      )}
    </div>
  );

  const header = () => (
    <div className={ classes.sectionHeader }>
      <img src={ iconUrl } alt="" className={ classes.sectionIcon } />
      <div className={ classes.sectionHeaderSeparator } />
      <div className={ classes.headerTitle }>{title || 'In the spotlight'}</div>
    </div>
  );

  const renderContent = () => {
    if (!advisor) return null;
    return (
      <div className={ classes.mainContentContainer }>
        {header()}
        <AppLink onClick={ handleLinkClick } to={ { pathname: slug, state: { ...analytics } } } className={ classes.advisorViewLink }>
          <div className={ classes.advisorContainer }>
            <div className={ classes.avatarHolder }>
              <AdvisorAvatar { ...advisor } theme={ advisorThemes.staff_pick } analytics={ analytics } />
            </div>
            <div className={ classes.advisorInfoContainer }>
              {advisorHeader()}
              {staffPickDescription()}
              {footer()}
            </div>
          </div>
        </AppLink>
      </div>
    );
  };

  return renderContent();
}

StaffPickSection.propTypes = {
  ...SectionProps,
  t: PropTypes.func.isRequired,
  displayNotifyMe: PropTypes.func.isRequired,
  showGeneralPopup: PropTypes.func.isRequired,
  authPendingAction: PropTypes.func.isRequired,
  displayRegister: PropTypes.func.isRequired,
  onAnalyticsClick: PropTypes.func,
  sectionSlug: PropTypes.string,
  updateNavigationStack: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

StaffPickSection.defaultProps = {
  ...SectionDefaultProps,
  onAnalyticsClick: null,
  loading: null,
  sectionSlug: null
};

export default StaffPickSection;
