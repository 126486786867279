import { connect } from 'react-redux';
import ChangeLanguage from './change_language';

const mapStateToProps = ({
  advisors
}) => ({
  advisors
});

export default connect(
  mapStateToProps,
  {}
)(ChangeLanguage);
