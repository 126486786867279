import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-dom-last-location';
import withPGTranslation from '../../../config/withPGTranslation';
import { clearApplicationError } from '../../../actions/aplication_error';
import SomeOtherAdvisors from './some_other_advisors';
import { loadAdvisors } from '../../../actions/search_results';

const mapStateToProps = ({
  applicationError,
  searchResults
}, {
  location: { pathname }
}) => ({
  ...applicationError,
  pathname,
  ...searchResults[pathname]
});

export default  withLastLocation(withPGTranslation(connect(mapStateToProps, {
  clearApplicationError,
  loadAdvisors
})(SomeOtherAdvisors)));
