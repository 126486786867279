import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Input, { SIGNUP_EMAIL, SIGNUP_PASSWORD, SIGNUP_RETYPE_EMAIL } from './input';
import classes from '../classes.module.scss';
import { passwordIsValid, formatCurrency } from '../../../config/util';
import { emailIsValid } from '../../../../common/config/utils';
import Const from '../../../config/const';
import GDPRConsernPopup from '../gdpr_consern_popup';
import { GDPRConsernPopupThemes } from '../../../config/themes';
import { trackPageVisit } from '../../../actions/analytics';

function SignUp({
  email, password, confirmEmail, switcherChecked, loading, error, t, social, onRegister,
  onFieldChanged, rememberMe, changeScreen, theme, checkInputErrors, clearError,
  shouldShowGDPRConsent, userMatchUpBonusInfo
}) {
  useEffect(() => {
    trackPageVisit('sign up with email');
  }, []);

  const allFieldsAreValid = emailIsValid(email)
    && passwordIsValid(password, true) && email === confirmEmail;

  const showPrivacyModal = () => {
    if (allFieldsAreValid) {
      shouldShowGDPRConsent({ [GDPRConsernPopupThemes.regular]: true });
    }
  };

  const onRegisterClicked = () => {
    checkInputErrors();
    if (allFieldsAreValid) {
      onRegister();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!switcherChecked) {
        showPrivacyModal();
      } else {
        onRegisterClicked();
      }
    }
  };

  const renderPrivacyText = () => (
    <div className = { classes.confirmPpTofTextContainer }>
      { t('app_ppTof.you_must_be_at_least_18_years') }
    </div>
  );

  const renderaAffiliatePromoHeader = () => {
    if (theme !== Const.authScreens.signUpAffiliatePromo) return null;
    return (
      <div className={ classes.affiliatePromoHeaderContainer }>
        <div className={ classes.affiliatePromoHeaderTitle }>
          { t('affitiate_promo_sign_up.claim_your_special_matching_bonus') }
        </div>
        <div className={ classes.affiliatePromoHeaderBottomText }>
          { t('affitiate_promo_sign_up.buy_credit', { amount: formatCurrency(userMatchUpBonusInfo?.requiredPurchasesAmount), extra_amount: formatCurrency(userMatchUpBonusInfo?.bonus) }) }
        </div>
      </div>
    );
  };

  const navigateToSignIn = () => {
    changeScreen(Const.authScreens.signIn);
    clearError();
  };

  const renderAlreadyHaveAnAccountButton = () => {
    if (theme === Const.authScreens.signUpAffiliatePromo) return null;
    return (
      <div className={ classes.navigationButtonContainer }>
        <button className={ classes.navigationButton } type="button" onClick={ navigateToSignIn }>
          <b>{t('already_have')}</b>
        </button>
      </div>
    );
  };

  const renderSignUpAffiliatePromoLabel = (text) => (
    theme === Const.authScreens.signUpAffiliatePromo
      ? (
        <div className={ classes.signUpAffiliatePromoLabel }>
          { text }
        </div>
      ) : null
  );

  return (
    <div>
      { renderaAffiliatePromoHeader() }
      {social}
      <div className={ classes.connectTitle }>
        { theme === Const.authScreens.signUpAffiliatePromo
          ? (
            <div className={ classes.signUpAffiliatePromoLabel }>
              { t('affitiate_promo_sign_up.or') }
            </div>
          ) : (
            <div style={ { paddingTop: '24px' } }>
              { t('or_create_account') }
            </div>
          )}
      </div>
      <div>
        { renderSignUpAffiliatePromoLabel(t('email')) }
        <Input
          inputType={ SIGNUP_EMAIL }
          onKeyDown={ handleKeyPress }
          onChange={ onFieldChanged }
          value={ email }
          id="email"
          disabled={ loading }
          error={ error && error.email && error.email[0] }
          theme={ theme }
        />
      </div>
      <div>
        { renderSignUpAffiliatePromoLabel(t('retype_email')) }
        <Input
          inputType={ SIGNUP_RETYPE_EMAIL }
          onKeyDown={ handleKeyPress }
          onChange={ onFieldChanged }
          value={ confirmEmail }
          id="confirmEmail"
          disabled={ loading }
          error={ error && error.confirmEmail && error.confirmEmail[0] }
          theme={ theme }
        />
      </div>
      <div>
        { renderSignUpAffiliatePromoLabel(t('password')) }
        <Input
          inputType={ SIGNUP_PASSWORD }
          onKeyDown={ handleKeyPress }
          onChange={ onFieldChanged }
          value={ password }
          id="password"
          disabled={ loading }
          error={ error && error.password && error.password[0] }
          theme={ theme }
        />
      </div>
      {rememberMe}
      <div>
        <button
          className={ classNames(
            classes.actionButton,
            theme === Const.authScreens.signUpAffiliatePromo
              ? classes.signUpAffiliatePromoactionButton : null
          ) }
          type="button"
          onClick={ !switcherChecked ? showPrivacyModal : onRegisterClicked }
        >
          <b>{theme === Const.authScreens.signUpAffiliatePromo ? t('affitiate_promo_sign_up.sign_up') : t('create_account')}</b>
        </button>
      </div>
      { renderPrivacyText() }
      { renderAlreadyHaveAnAccountButton() }
      <GDPRConsernPopup onRegisterClicked={ onRegisterClicked } theme={ GDPRConsernPopupThemes.regular } />
    </div>
  );
}

SignUp.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  confirmEmail: PropTypes.string.isRequired,
  switcherChecked: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  t: PropTypes.func.isRequired,
  social: PropTypes.node.isRequired,
  onRegister: PropTypes.func.isRequired,
  onFieldChanged: PropTypes.func.isRequired,
  rememberMe: PropTypes.node.isRequired,
  changeScreen: PropTypes.func.isRequired,
  theme: PropTypes.string,
  checkInputErrors: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  shouldShowGDPRConsent: PropTypes.func.isRequired,
  userMatchUpBonusInfo: PropTypes.object
};

SignUp.defaultProps = {
  error: null,
  theme: Const.authScreens.signUp,
  userMatchUpBonusInfo: null
};
export default SignUp;
