import { getMixpanelInstance } from './mixpanel';

const events = [];
let lastEventAt = (new Date()).getTime();
const RETRY_INTERVAL = 1000;
const MAX_EVENTS_BUFFER = 5000;

const generateUID = () => (
  Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10)
);

const buildUrl = () => `${ window.config.clAnalyticsEndpoint }?local_time=${ (new Date()).getTime() }`;

const buildBody = (list, mixpanel) => JSON.stringify({
  events: list,
  batch_id: generateUID(),
  distinct_id: mixpanel.get_distinct_id(),
  key: window.config.clAnalyticsKey
});

const doSendEvents = (list) => {
  getMixpanelInstance((mixpanel) => {
    window.fetch(buildUrl(), {
      method: 'POST',
      body: buildBody(list, mixpanel)
    }).then(response => {
      if (response.status === 200 || response.status === 201) {
        lastEventAt = (new Date()).getTime();
      } else {
        setTimeout(() => { doSendEvents(list); }, RETRY_INTERVAL);
      }
    }).catch(() => {
      setTimeout(() => { doSendEvents(list); }, RETRY_INTERVAL);
    });
  });
};

const sendEvents = () => {
  const sendingEvents = [...events];
  events.length = 0;
  setTimeout(() => {
    doSendEvents(sendingEvents);
  }, 0);
};

// every MAX_EVENTS_BUFFER seconds
setInterval(() => {
  if ((new Date()).getTime() - lastEventAt > MAX_EVENTS_BUFFER && events.length > 0) {
    sendEvents();
  }
}, MAX_EVENTS_BUFFER);

// on page unload
// TODO: consider https://github.com/mixpanel/mixpanel-js/blob/7c83946d2aa4b28e0cd10464b3d3ed21b94e5412/src/mixpanel-core.js#L267
document.addEventListener('visibilitychange', () => {
  if (window.config.clAnalyticsEndpoint) {
    if (document.visibilityState === 'hidden' && events.length) {
      getMixpanelInstance((mixpanel) => {
        if (navigator.sendBeacon(buildUrl(), buildBody(events, mixpanel))) {
          events.length = 0;
        }
      });
    }
  }
});

export const trackBEvent = (event, properties) => (dispatch, getState) => {
  if (!window.config.clAnalyticsEndpoint) { return; }
  const { user } = getState();
  const userId = ((user || {}).user || {}).id;
  // console.log('trackBEvent', event, properties);
  const updatedProps = {
    ...properties,
    $current_url: window.location.href,
    $screen_height: window.screen_height,
    $screen_width: window.screen_width,
    $insert_id: generateUID()
  };
  events.push([event, userId, updatedProps, (new Date()).getTime()]);
};
