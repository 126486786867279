import { isPGW, toCamelCase } from '../../common/config/utils';
import i18n from './i18n';

export const appRoutes = {
  home: 'routes.home',
  psychics: 'specificRoutes.psychicList',
  advisor: 'specificRoutes.psychic',
  myOrders: 'specificRoutes.orderList',
  advisorOrders: 'specificRoutes.advisorList',
  orderDetails: 'specificRoutes.orderDetails',
  specialities: 'routes.specialities',
  favoriteAdvisors: 'dynamicRoutes.favorite_advisors',
  addFunds: 'routes.addFunds',
  promoCode: 'routes.promoCode',
  transcript: 'routes.transcript',
  settings: 'routes.settings',
  cashback: 'routes.cashback',
  paymentSources: 'routes.paymentSources',
  emptyPreload: 'routes.emptyPreload',
  spendLimits: 'routes.spendLimits',
  register: 'routes.register',
  signin: 'routes.signin',
  purchaseDetails: 'routes.purchaseDetails',
  notificationPreferences: 'routes.notificationPreferences',
  palmReadings: 'routes.palmReadings'
};

export const dynamicRoutes = Object.fromEntries(
  Object.entries(window.config.uiSettings.routes)
    .map(([key, value]) => [`dynamicRoutes.${ toCamelCase(key) }`, toCamelCase(value)])
);

export const dynamicRoutesKeys = Object.keys(dynamicRoutes);
export const specialityRoutesKeys = Object.keys(dynamicRoutes).filter(key => dynamicRoutes[key].isSpeciality);

const createCategoryData = ({ routeKey, screenStack }) => {
  const route = dynamicRoutes[routeKey];
  const routeData = {
    [routeKey]: {
      name: (route?.translations?.[i18n.language].name || route?.name),
      slug: routeKey,
      params: {
        params: { ...route?.params },
        needRefresh: true,
        trackCategoryEvent: true
      },
      directScreenStack: { ...screenStack },
      type: 'specialities'
    }
  };
  return routeData;
};

const directScreenStackDefaultCategory = () => {
  const defaultRoute = dynamicRoutesKeys
    .find(key => dynamicRoutes[key].isDefault);

  if (defaultRoute) return defaultRoute;

  if (process.env.APP_CONTEXTS === 'ka') return 'dynamicRoutes.psychic_reading';

  return 'dynamicRoutes.psychic_readings';
};

const directScreenStack = {
  advisor: {
    [appRoutes.home]: { name: i18n.t('sideMenu.home'), slug: appRoutes.home },
    ...createCategoryData({ routeKey: directScreenStackDefaultCategory() })
  },
  categoty: {
    [appRoutes.home]: { name: i18n.t('sideMenu.home'), slug: appRoutes.home }
  },
  myOrders: {
    [appRoutes.home]: { name: i18n.t('sideMenu.home'), slug: appRoutes.home }
  },
  advisorOrders: {
    [appRoutes.home]: { name: i18n.t('sideMenu.home'), slug: appRoutes.home },
    [appRoutes.myOrders]: { name: i18n.t('activity.my_activity'), slug: appRoutes.myOrders }
  },
  transcript: {
    [appRoutes.home]: { name: i18n.t('sideMenu.home'), slug: appRoutes.home },
    [appRoutes.psychics]: { name: i18n.t('all_advisors'), slug: appRoutes.psychics }
  },
  categoties: {
    [appRoutes.home]: { name: i18n.t('sideMenu.home'), slug: appRoutes.home }
  }
};

const createCategoryNavigationStackBarRoutesData = ({ screenStack }) => dynamicRoutesKeys
  .reduce((data, routeKey) => {
    const routeData = createCategoryData({ routeKey, screenStack });
    return { ...data, ...routeData };
  }, {});

export const navigationStackBarRoutesData = {
  [appRoutes.advisor]: { directScreenStack: { ...directScreenStack.advisor }, type: 'advisor' },
  [appRoutes.home]: { name: i18n.t('sideMenu.home'), slug: appRoutes.home },
  ...createCategoryNavigationStackBarRoutesData({ screenStack: directScreenStack.categoty }),
  [appRoutes.myOrders]: {
    directScreenStack: { ...directScreenStack.myOrders }, name: i18n.t('activity.my_activity'), slug: appRoutes.myOrders, type: 'myOrders'
  },
  [appRoutes.advisorOrders]: {
    directScreenStack: { ...directScreenStack.advisorOrders }, type: 'advisor'
  },
  [appRoutes.transcript]: {
    directScreenStack: { ...directScreenStack.transcript }, type: 'advisor'
  },
  [appRoutes.specialities]: {
    directScreenStack: { ...directScreenStack.categoties }, name: i18n.t('sideMenu.specialities'), slug: appRoutes.specialities, type: 'Specialities'
  }
};

// Settings Routes

const componentsSettings = {
  HomePage: {
    name: 'HomePage',
    withoutSuspense: true
  },
  Advisor: {
    name: 'Advisor',
    withoutSuspense: true
  },
  SearchResults: {
    name: 'SearchResults'
  },
  MyOrders: {
    name: 'MyOrders'
  },
  AdvisorOrders: {
    name: 'AdvisorOrders',
    isProtected: true
  },
  OrderDetails: {
    name: 'OrderDetails',
    isProtected: true
  },
  AddFunds: {
    name: 'AddFunds',
    isProtected: true
  },
  PromoCode: {
    name: 'PromoCode',
    isProtected: true
  },
  Transcript: {
    name: 'Transcript',
    isProtected: true
  },
  Settings: {
    name: 'Settings',
    isProtected: true
  },
  Cashback: {
    name: 'Cashback',
    isProtected: true
  },
  PaymentSources: {
    name: 'PaymentSources',
    isProtected: true
  },
  EmptyPreload: {
    name: 'EmptyPreload'
  },
  SpendLimitsVerifications: {
    name: 'SpendLimitsVerifications',
    isProtected: true
  },
  SignInPage: {
    name: 'SignInPage'
  },
  PurchaseDetailsForMobileApp: {
    name: 'PurchaseDetailsForMobileApp'
  },
  Categories: {
    name: 'Categories'
  },
  NotificationPreferences: {
    name: 'NotificationPreferences',
    isProtected: true
  },
  NoSearchResults: {
    name: 'NoSearchResults'
  }
};

const getDynamicRoutes = () => {
  const routes = Object.keys(dynamicRoutes).reduce((res, key) => {
    res[key] = {
      ...componentsSettings.SearchResults,
      needReplaceHeaderSearch: true,
      shouldShowNavigationStackBar: true,
      shouldSetNavigationStackBar: true,
      shouldClearNavigationStackBar: true,
      showSmartBanner: true,
      showBanner: true
    };

    return res;
  }, {});

  return routes;
};

export const applicationRoutes = {
  [appRoutes.home]: {
    ...componentsSettings.HomePage,
    shouldSetNavigationStackBar: true,
    showSmartBanner: true,
    showBanner: true
  },
  [appRoutes.psychics]: { ...componentsSettings.SearchResults, needReplaceHeaderSearch: true },
  [appRoutes.favoriteAdvisors]: { ...componentsSettings.SearchResults, isProtected: true },
  [appRoutes.advisor]: {
    ...componentsSettings.Advisor,
    shouldShowNavigationStackBar: true,
    shouldSetNavigationStackBar: true,
    showBanner: true
  },
  [appRoutes.myOrders]: {
    ...componentsSettings.MyOrders,
    shouldShowNavigationStackBar: true,
    shouldSetNavigationStackBar: true,
    shouldClearNavigationStackBar: true
  },
  [appRoutes.advisorOrders]: {
    ...componentsSettings.AdvisorOrders,
    shouldShowNavigationStackBar: true,
    shouldSetNavigationStackBar: true
  },
  [appRoutes.orderDetails]: componentsSettings.OrderDetails,
  [appRoutes.addFunds]: componentsSettings.AddFunds,
  [appRoutes.promoCode]: componentsSettings.PromoCode,
  [appRoutes.transcript]: { ...componentsSettings.Transcript, shouldSetNavigationStackBar: true },
  [appRoutes.settings]: componentsSettings.Settings,
  ...isPGW() ? { [appRoutes.cashback]: componentsSettings.Cashback } : undefined,
  [appRoutes.paymentSources]: componentsSettings.PaymentSources,
  [appRoutes.emptyPreload]: componentsSettings.EmptyPreload,
  [appRoutes.spendLimits]: componentsSettings.SpendLimitsVerifications,
  [appRoutes.register]: componentsSettings.SignInPage,
  [appRoutes.signin]: componentsSettings.SignInPage,
  [appRoutes.purchaseDetails]: { ...componentsSettings.PurchaseDetailsForMobileApp, showBanner: true },
  [appRoutes.specialities]: {
    ...componentsSettings.Categories,
    shouldShowNavigationStackBar: true,
    shouldSetNavigationStackBar: true
  },
  [appRoutes.notificationPreferences]: componentsSettings.NotificationPreferences,
  ...isPGW() ? { [appRoutes.palmReadings]: componentsSettings.NoSearchResults } : undefined,
  ...getDynamicRoutes()
};

export const getSpecialitiesRoutes = () => specialityRoutesKeys
  .sort((a, b) => dynamicRoutes[a].order - dynamicRoutes[b].order);
