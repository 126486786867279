/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import withPGTranslation from '../../../config/withPGTranslation';
import SpecialitiesMenuItem from './specialities_menu_item';

const mapStateToProps = ({
  user
}) => ({
  userId: user && user.user && user.user.id
});

export default connect(mapStateToProps, {})(withPGTranslation(SpecialitiesMenuItem));
