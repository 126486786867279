import React from 'react';
import PropTypes from 'prop-types';
import { AdvisorPropType, AdvisorDefaultProps } from '../../reducers/advisor_prop_types';
import Rating from './rating/rating';
import classes from './classes.module.scss';
import ReadingSinceAndCountItem, { readingSinceAndCountItemThemes } from '../advisor/nested_views/readingSinceAndCountItem';
import AdvisorVideoPlayer from '../advisor_video_player';
import ExpandedText from '../../../common/helpers/expanded_text';
import { isPrerenderMode } from '../../config/util';
import { isKA } from '../../../common/config/utils';
import { SvgIcon } from '../../../common/sprites';

const defaultAvatar = require('../header/img/home_placeholder.png');

const onPlayVideoBtnContainerClick = (e) => {
  e.preventDefault();
};

const renderPlayVideoBtnContainer = (profileVideoUrl, id, analytics) => (isKA() ? null : (
  <div className={ classes.playVideoBtnContainerHolder } suppressHydrationWarning>
    <div
      className={ classes.playVideoBtnContainer }
      onClick={ onPlayVideoBtnContainerClick }
      onKeyDown={ onPlayVideoBtnContainerClick }
      suppressHydrationWarning
    >
      <AdvisorVideoPlayer
        profileVideoUrl={ profileVideoUrl }
        needTrackProfileVideoEvent
        advisorId={ id }
        playImg = { (
          <SvgIcon className={ classes.playVideoImg } id="home_play" />
        ) }
        analytics={ analytics }
      />
    </div>
  </div>
));

const renderRateAndFaforite = ({
  loading, rating, isFavorite, isNewAdvisor, profileVideoUrl, id, analytics, theme
}) => {
  if (loading || theme === readingSinceAndCountItemThemes.recommendAdvisor) return null;
  return (
    <div className={ classes.rateAndFavouriteContainer }>
      <Rating rating={ rating } isNewAdvisor={ isNewAdvisor } />
      <div className={ classes.favouriteContainer } suppressHydrationWarning>
        <SvgIcon className={ classes.favoriteImg } id={ isFavorite && window.currentUserId ? 'fav_fill' : 'fav_empty' } />
      </div>
      {renderPlayVideoBtnContainer(profileVideoUrl, id, analytics)}
    </div>
  );
};

const renderNameSection = ({
  loading, nickname, serviceTitle, readingsCount, yearJoined
}) => {
  if (loading) return null;
  return (
    <div className = { classes.nickNameAndServiseTitleConatiner } suppressHydrationWarning>
      <div className={ classes.advisorNickNameContainer } suppressHydrationWarning>
        <ExpandedText
          customClasses={ { text: classes.nickNameText } }
          ellipsis
        >
          { nickname }
        </ExpandedText>
      </div>
      <div className={ classes.serviceTitleContainer } suppressHydrationWarning>
        <ExpandedText
          customClasses={ { text: classes.serviceTitleText } }
          ellipsis
        >
          { serviceTitle }
        </ExpandedText>
      </div>
      <div className={ classes.readingSinceAndCountItemContainer } suppressHydrationWarning>
        <ReadingSinceAndCountItem
          readingsCount={ readingsCount }
          yearJoined={ yearJoined }
          theme={ readingSinceAndCountItemThemes.onHomePageAdvisorAvatar }
          loading={ loading }
        />
      </div>
    </div>
  );
};

const getAvatarUrl = (loading, profilePictureUrl) => {
  if (loading || !profilePictureUrl) return defaultAvatar;
  return profilePictureUrl || defaultAvatar;
};

const getAdvisorStatusClassName = ({ loading, status }) => {
  if (isPrerenderMode() || loading || !status) return classes.indicatorSkeleton;
  return classes[`${ status }Indicator`];
};

function AdvisorAvatar({
  profilePictureUrl, nickname, serviceTitle, status, isFavorite, rating, theme, loading,
  isNewAdvisor, readingsCount, yearJoined, profileVideoUrl, id, analytics, t
}) {
  const renderAdvisorStatus = () => (
    <div className={ classes.indicatorContainer }>
      <div className={ getAdvisorStatusClassName({ loading, status }) } suppressHydrationWarning>
        <span className={ classes.statusLabel } suppressHydrationWarning>
          { t(`advisor_profile.status_label_${ status }`).toUpperCase() }
        </span>
      </div>
    </div>
  );

  const renderAvatar = () => (
    <div className={ classes.mainContainer }>
      <div className={ classes.imgContainerHolder }>
        <div className={ classes.imgContainer }>
          <img
            src={ getAvatarUrl(loading, profilePictureUrl) }
            className={ classes.image }
            alt={ nickname }
            style={ !profilePictureUrl ? { objectFit: 'scale-down' } : null }
            suppressHydrationWarning
          />
          { renderRateAndFaforite({
            loading, rating, isFavorite, isNewAdvisor, profileVideoUrl, id, analytics, theme
          }) }
          { renderNameSection({
            loading, nickname, serviceTitle, readingsCount, yearJoined
          }) }
        </div>
      </div>
      <div className={ classes.statusContainer }>
        { renderAdvisorStatus() }
      </div>
    </div>
  );

  return (
    <div className={ classes[`${ theme }`] }>
      { renderAvatar() }
    </div>
  );
}

AdvisorAvatar.propTypes = {
  ...AdvisorPropType,
  theme: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  t: PropTypes.func.isRequired,
  analytics: PropTypes.object
};

AdvisorAvatar.defaultProps = {
  ...AdvisorDefaultProps,
  theme: null,
  loading: false,
  analytics: null
};

export default AdvisorAvatar;
