/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import MenuItem from '../menu_item';
import { renderSideMenuIcon } from '../side_menu';
import { specialitiesClickSource } from '../../../config/const';
import { dynamicRoutes, dynamicRoutesKeys, getSpecialitiesRoutes } from '../../../config/app_routes';
import classes from './classes.module.scss';
import { isKA } from '../../../../common/config/utils';
import { getCurrentLocalization } from '../../../config/i18n';
import { isActiveRoute } from '../../routes/routes_helpers';
import { useCurrentPath } from '../../routes/hooks/useCurrentPath';
import { SvgIcon } from '../../../../common/sprites';

const sideMenuArrow = (iconToUse) => (
  <SvgIcon id={ iconToUse } className={ classes.specialitiesArrow } />
);

const renderSideMenuSpecialitiesArrow = (specialitiesShow) => {
  const iconName = 'specialities_arrow';
  const iconToUse = specialitiesShow ? `${ iconName }_top` : `${ iconName }_down`;
  return (
    <div className={ isKA() ? classes.specialitiesArrowBtn : null }>
      {sideMenuArrow(iconToUse)}
    </div>
  );
};

const getSpecialitiesStyle = () => {
  if (isKA()) return { width: '24px', height: '24px' };
  return {};
};

const getNeedChekActive = () => {
  if (isKA()) return false;
  return true;
};

const renderSpecialities = (specialities) => {
  if (!specialities) return null;
  return (
    specialities.map((s) => (
      <MenuItem
        key={ s.id + s.name }
        title={ { text: s.name, active: isActiveRoute(s.slug) } }
        icon={ renderSideMenuIcon({
          iconName: s.iconName, path: s.slug, needChekActive: getNeedChekActive(), style: getSpecialitiesStyle()
        }) }
        linkTo={ {
          pathname: s.slug,
          state: {
            needRefresh: true,
            trackCategoryEvent: true,
            clickSource: specialitiesClickSource.menu,
            params: s.params
          }
        } }
      />
    ))
  );
};

function SpecialitiesMenuItem({ userId, t }) {
  const { routeKey } = useCurrentPath();
  const [specialitiesShow, setSpecialitiesShow] = useState(
    dynamicRoutesKeys.includes(routeKey)
  );

  const showSpecialities = () => {
    setSpecialitiesShow(!specialitiesShow);
  };

  const currentLang = getCurrentLocalization();
  const location = useLocation();

  // needed to update isActive property on changing routes
  useEffect(() => { }, [location.pathname]);

  const userLoggedIn = userId;

  const specialities = getSpecialitiesRoutes()
    .map(key => ({ ...dynamicRoutes[key], ...dynamicRoutes[key].translations?.[currentLang], slug: key }));

  const renderSpecialitiesMenuItem = () => {
    if (userLoggedIn) {
      return (
        <>
          <MenuItem
            title={ { text: t('sideMenu.specialities') } }
            icon={ renderSideMenuIcon({ iconName: 'specialities_menu_icon', path: ' ' }) }
            onClick={ showSpecialities }
            extraNode={ renderSideMenuSpecialitiesArrow(specialitiesShow) }
          />
          {specialitiesShow ? (
            <div className={ classes.specialitiesContainer }>
              {renderSpecialities(specialities)}
            </div>
          ) : null}
        </>
      );
    }
    return renderSpecialities(specialities);
  };

  return renderSpecialitiesMenuItem();
}

SpecialitiesMenuItem.propTypes = {
  t: PropTypes.func.isRequired,
  specialities: PropTypes.array
};

SpecialitiesMenuItem.defaultProps = {
  specialities: null
};

export default SpecialitiesMenuItem;
