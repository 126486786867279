import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from './classes.module.scss';
import AppGeneralPopup from '../../../common/app_general_popup';
import { appGeneralPopupTheme } from '../../../common/config/const';

function AdvisorNotProvideServicesPopup({
  onCloseCliked, advisorNotProvideServicesPopupData, setAdvisorNotProvideServicesPopupData, makeChangeLanguage
}) {
  const { t } = useTranslation();

  const onAdvisorNotProvideServicesPopupContinueReadingClick = useCallback(() => {
    onCloseCliked();
    setAdvisorNotProvideServicesPopupData({});
  }, []);

  const advisorNotProvideServicesPopupExploreOthersClick = (e) => {
    makeChangeLanguage({ target: e.target, lang: advisorNotProvideServicesPopupData.lang });
  };

  const renderAdvisorNotProvideServicesPopup = () => {
    const advisorNotProvideServicesPopupDataShow = !!advisorNotProvideServicesPopupData.show;
    if (!advisorNotProvideServicesPopupDataShow) return null;
    return (
      <AppGeneralPopup
        visible={ advisorNotProvideServicesPopupDataShow }
        theme={ appGeneralPopupTheme.CENTERED }
      >
        <div className={ classes.advisorNotProvideServicesPopupContainer }>
          <div className={ classes.advisorNotProvideServicesPopupTitle }>
            {t('change_language.the_advisor_doesnt_provide_services')}
          </div>
          <div className={ classes.advisorNotProvideServicesPopupBody }>
            {t('change_language.this_advisor_does_not_support')}
          </div>
          <div className={ classes.advisorNotProvideServicesPopupButtonsContainer }>
            <div className={ classes.advisorNotProvideServicesPopupButtonContainer }>
              <button type="button" className={ classes.advisorNotProvideServicesPopupContinueReadingButton } onClick={ onAdvisorNotProvideServicesPopupContinueReadingClick }>
                { t('change_language.continue_reading') }
              </button>
            </div>
            <div className={ classes.advisorNotProvideServicesPopupButtonContainer }>
              <button type="button" className={ classes.advisorNotProvideServicesPopupExploreOthersButton } onClick={ advisorNotProvideServicesPopupExploreOthersClick }>
                { t('change_language.explore_others') }
              </button>
            </div>
          </div>
        </div>
      </AppGeneralPopup>
    );
  };

  return renderAdvisorNotProvideServicesPopup();
}

AdvisorNotProvideServicesPopup.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool
};

AdvisorNotProvideServicesPopup.defaultProps = {
  show: false,
  onClose: null
};

export default AdvisorNotProvideServicesPopup;
