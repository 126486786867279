import React from 'react';
import { useCurrentPath } from '../hooks/useCurrentPath';

export const withCurrentPath = (Component) => {
  const Wrapper = (props) => {
    const currentPath = useCurrentPath();

    return (
      <Component
        currentPath={ currentPath }
        { ...props }
      />
    );
  };

  return Wrapper;
};
