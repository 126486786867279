import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import { isKA, convertStringToCamelCase } from '../../../../../common/config/utils';
import { getAvailableMinSalesPrice, getAvailableSalesPrice } from '../../../../config/util';
import { minSalesPriceType, liveModeState } from '../../../../config/const';
import { SvgIcon } from '../../../../../common/sprites';

const needRenderCoupon = ({ liveModeMinPromoPrices, userAdvisorModeMinSalesPrice }) => (
  liveModeMinPromoPrices
  && userAdvisorModeMinSalesPrice === minSalesPriceType.couponLiveModeMinSalesPrice
  && liveModeMinPromoPrices[convertStringToCamelCase(minSalesPriceType.couponLiveModeMinSalesPrice)]
);

const renderCouponImg = ({ liveModeMinPromoPrices, userAdvisorModeMinSalesPrice }) => (needRenderCoupon({ liveModeMinPromoPrices, userAdvisorModeMinSalesPrice }) ? (
  <SvgIcon id="advisor_special_offer_icon" className={ classes.discountCouponIcon } size={ 21 } />
) : null);

const priceColor = ({ availableMinSalesPrice, userAdvisorModeMinSalesPrice, liveModeMinPromoPrices }) => {
  if (needRenderCoupon({ liveModeMinPromoPrices, userAdvisorModeMinSalesPrice })) return classes.salePriceTextWithCoupon;
  if (availableMinSalesPrice) return classes.salePriceText;
  return classes.priceText;
};

const prePriceText = ({
  availableMinSalesPrice, availableMinPrice, text
}) => {
  if (availableMinSalesPrice) {
    return <span className={ classes.crosoutPrice }>{isKA() ? `$${ parseFloat(availableMinPrice).toFixed(2) }/min` : `$${ parseFloat(availableMinPrice).toFixed(2) }`}</span>;
  }
  return <span className={ classes.minPriceText }>{ text }</span>;
};

const getPrices = ({
  liveModes, liveModeMinPrice, userAdvisorModeSalePrice, liveModeMinPromoPrices, userAdvisorModeMinSalesPrice
}) => {
  const onlineLiveModePrices = [];
  liveModes?.forEach((mode) => {
    if (mode.modeState === liveModeState.online) {
      const { price, promoPrices } = mode;

      const parsedPrice = parseFloat(price);
      const availableSalesPrice = getAvailableSalesPrice({ promoPrices, userAdvisorModeSalePrice });
      const parsedAvailableSalesPrice = parseFloat(availableSalesPrice);
      onlineLiveModePrices.push({ availableMinPrice: parsedPrice, availableMinSalesPrice: parsedAvailableSalesPrice });
    }
  });

  const minPrice = Math.min(
    ...onlineLiveModePrices.flatMap(item => [item.availableMinPrice, item.availableMinSalesPrice]).filter(value => !Number.isNaN(value))
  );

  const minPriceObject = onlineLiveModePrices.find(item => item.availableMinPrice === minPrice || item.availableMinSalesPrice === minPrice);

  if (minPriceObject) return minPriceObject;

  return { availableMinPrice: liveModeMinPrice, availableMinSalesPrice: getAvailableMinSalesPrice({ liveModeMinPromoPrices, userAdvisorModeMinSalesPrice }) };
};

function PriceText({
  liveModeMinPrice, liveModeMinPromoPrices, text, userAdvisorModeMinSalesPrice, userAdvisorModeSalePrice, liveModes
}) {
  const { availableMinPrice, availableMinSalesPrice } = getPrices({
    liveModes, liveModeMinPrice, userAdvisorModeSalePrice, liveModeMinPromoPrices, userAdvisorModeMinSalesPrice
  });

  if (!availableMinPrice && !availableMinSalesPrice) return null;

  const price = () => parseFloat(availableMinSalesPrice || availableMinPrice).toFixed(2);
  return (
    <div className={ `${ isKA() ? classes.v2 : '' }` }>
      { renderCouponImg({ liveModeMinPromoPrices, userAdvisorModeMinSalesPrice }) }
      <div className={ isKA() ? classes.textColumnContainer : classes.textContainer }>
        {prePriceText({ availableMinSalesPrice, availableMinPrice, text })}
        <div className={ classes.priceSeparator } />
        <span className={ priceColor({ availableMinSalesPrice, userAdvisorModeMinSalesPrice, liveModeMinPromoPrices }) } suppressHydrationWarning>
          {`$${ price() }/min`}
        </span>
      </div>
    </div>
  );
}

PriceText.propTypes = {
  liveModeMinPrice: PropTypes.string,
  liveModeMinPromoPrices: PropTypes.object,
  loading: PropTypes.bool,
  theme: PropTypes.string,
  text: PropTypes.string,
  userAdvisorModeMinSalesPrice: PropTypes.string,
  userAdvisorModeSalePrice: PropTypes.string,
  liveModes: PropTypes.array
};

PriceText.defaultProps = {
  liveModeMinPrice: null,
  liveModeMinPromoPrices: {},
  loading: false,
  theme: '',
  text: null,
  userAdvisorModeMinSalesPrice: null,
  userAdvisorModeSalePrice: null,
  liveModes: []
};

export default PriceText;
