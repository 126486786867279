import React from 'react';
import { Trans } from 'react-i18next';
import classes from './classes.module.scss';
import Const from '../../../config/const';
import { isPayg } from '../../../config/util';
import { renderDurationItemDiscountLabel } from './duration_selector/duration_selector_utils';

const continueDefaultDuration = (
  onSelect,
  setSelectedCarouselDefaultItem,
  pricingOptions,
  setDefaultDurationChanged,
  isNewUser
) => {
  pricingOptions.map((option, index) => { //eslint-disable-line
    if (option.default && !(isNewUser && option.additional)) {
      onSelect({ ...option, selectedCarouselIndex: index });
      setSelectedCarouselDefaultItem(index);
      setDefaultDurationChanged(false);
    }
  });
};

export const setDefaultSelected = (
  onSelect,
  pricingOptions,
  selectedCarouselIndex,
  setSelectedCarouselDefaultItem,
  setDefaultDurationChanged,
  newUserPaygPricingOption,
  isNewUser
) => {
  if (newUserPaygPricingOption && Object.keys(newUserPaygPricingOption).length > 0) {
    const { price } = newUserPaygPricingOption;
    onSelect({
      ...newUserPaygPricingOption, price: parseFloat(price), duration: -1, payAsYouGo: true
    });
    return;
  }
  if (selectedCarouselIndex !== null
      && selectedCarouselIndex <= pricingOptions.length - 1) {
    const selectedOption = pricingOptions[selectedCarouselIndex];
    onSelect({ ...selectedOption, selectedCarouselIndex });
    setSelectedCarouselDefaultItem(selectedCarouselIndex);
  }
  if (selectedCarouselIndex === null) {
    continueDefaultDuration(
      onSelect,
      setSelectedCarouselDefaultItem,
      pricingOptions,
      setDefaultDurationChanged,
      isNewUser
    );
  }
};

export const setDefaultTransform = (index, carouselRef, pricingOptions, transform) => {
  if (!carouselRef || !carouselRef.current || !carouselRef.current.listRef) return;
  const selectedIndex = index;
  const currentTransform = transform || carouselRef.current.listRef.style.transform;
  if (selectedIndex === 0) {
    carouselRef.current.listRef.setAttribute('style', `left:18%; transform: ${ currentTransform };`);
    return;
  }
  if (selectedIndex === pricingOptions.length - 1) {
    carouselRef.current.listRef.setAttribute('style', `right:18%; transform: ${ currentTransform };`);
    return;
  }
  carouselRef.current.listRef.setAttribute('style', `left:0; right:0; transform: ${ currentTransform };`);
};

const renderNumber = number => (number < 10 ? `0${ number }` : number);

const renderAdditionalTime = (additionalOption) => {
  const minutes = renderNumber(parseInt(additionalOption.duration / 60, 10));
  const remainigSeconds = renderNumber(additionalOption.duration % 60);
  const additionalTime = additionalOption.duration % 60 ? `${ minutes }:${ remainigSeconds }` : parseInt(additionalOption.duration / 60, 10);
  return additionalTime;
};

export const renderAdditionalOption = (additionalOption, onClickCurrentBalance, onKeyDown, t, selectDurationKind) => (
  (additionalOption && selectDurationKind === Const.selectDurationKind.newUser) ? (
    <div className={ classes.additionalOptionContainer }>
      <div
        className={ classes.additionalOption }
        onClick={ onClickCurrentBalance }
        onKeyDown={ onKeyDown }
      >
        <Trans
          i18nKey="chat.use_your_balance"
          components={ {
            bold:<b className={ classes.colorAdditionalText } />
          } }
          values={ {
            balance: parseFloat(additionalOption.price).toFixed(2),
            duration: renderAdditionalTime(additionalOption)
          } }
        />
      </div>
    </div>
  ) : null);

export const renderTitle = (chatType, isContinue, name, t, selectDurationKind) => {
  if (selectDurationKind === Const.selectDurationKind.newUserPayg) return null;
  let titleLabel;
  switch (chatType) {
    case Const.chatType.text:
      titleLabel = t('chat.select_chat_duration');
      break;
    case Const.chatType.video:
      titleLabel = t('chat.select_video_duration');
      break;
    case Const.chatType.voice:
      titleLabel = t('chat.select_voice_duration');
      break;
    default:
      break;
  }
  if (isContinue && selectDurationKind === Const.selectDurationKind.newUser) {
    return (
      <div className={ classes.titleContainer }>
        <div className={ classes.continueCallText }>{t('chat.continue_call')}</div>
        <div className={ classes.advisorNameText }>{t('chat.advisor_name', { name })}</div>
      </div>
    );
  }
  if (isContinue) return t('chat.add_time');

  return titleLabel;
};

export const renderThisChargeTakesIntoAccountLabel = ({ duration, clientAvailableCredit }) => {
  if (isPayg({ duration })) return null;
  if (clientAvailableCredit >= 0) return null;
  const formater = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return (
    <span className={ classes.paygLabelText }>
      <Trans
        i18nKey= "chat.this_charge_takes_into_account_your_current_debt_of"
        values={ { amount: formater.format(Math.abs(clientAvailableCredit)) } }
      />
    </span>
  );
};

export const renderInfoLabel = ({ infoLabelText, selectDurationKind }) => {
  if (selectDurationKind === Const.selectDurationKind.newUserPayg) return null;
  return (
    <span className={ classes.descriptionText }>
      { infoLabelText }
    </span>
  );
};

const renderSaveOverText = ({ saveAmount }) => {
  if (!saveAmount || saveAmount <= 0) return null;
  return (
    <div className={ classes.saveUpDiscountLabelAmount }>
      <Trans
        i18nKey= "chat.save_up_save_over"
        values={ { saveAmount: Math.floor(saveAmount) } }
      />
    </div>
  );
};

export const renderSaveUpLabel = ({ newUserPaygPricingOption }) => {
  const { saveAmount, discount } = newUserPaygPricingOption;
  const parsedSaveAmount = parseFloat(saveAmount);
  if (discount) {
    return (
      <div className={ classes.saveUpLabelContainer }>
        <div className={ classes.saveUpLabelTextContainer }>
          <div className={ classes.saveUpDiscountLabelText }>
            <Trans
              i18nKey= "chat.save_up_sale"
              values={ { discount } }
            />
          </div>
          { renderSaveOverText({ saveAmount: parsedSaveAmount }) }
        </div>
      </div>
    );
  }
  if (saveAmount && parsedSaveAmount > 0) {
    return (
      <div className={ classes.saveUpLabelContainer }>
        <div className={ classes.saveUpLabelTextContainer }>
          <div className={ classes.saveUpLabelText }>
            <Trans
              i18nKey= "chat.save_up_to"
            />
          </div>
          <div className={ classes.saveUpLabelAmount }>
            { `$${ parsedSaveAmount.toFixed(2) }` }
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export const renderZenModeLabel = ({ selectDurationKind, infoLabelText }) => {
  if (selectDurationKind !== Const.selectDurationKind.newUserPayg) return null;
  return (
    <div className={ classes.zenModeLabelContainer }>
      { infoLabelText}
    </div>
  );
};

export const renderNewUserPaygAvailableBalanceLabel = ({ selectDurationKind, clientAvailableCredit, t }) => {
  const parsedClientAvailableCredit = parseFloat(clientAvailableCredit);
  if (selectDurationKind !== Const.selectDurationKind.newUserPayg || parsedClientAvailableCredit <= 0) return null;
  return (
    <div className={ classes.newUserPaygYourAvailableBalanceContainer }>
      { t('chat.your_available_balance_is', { balance: parsedClientAvailableCredit.toFixed(2) }) }
    </div>
  );
};

export const renderDurationItemDiscountLabelForNewUser = ({ discountData, selectDurationKind, t }) => {
  if (selectDurationKind !== Const.selectDurationKind.newUser) return null;
  return renderDurationItemDiscountLabel({ discountData, t });
};
