import React, {  useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import classes from './classes.module.scss';
import  withPGTranslation  from '../../config/withPGTranslation';
import { SvgIcon } from '../../../common/sprites';
import { onHeaderSearchClick } from '../../config/util';
import { useCurrentPath } from '../routes/hooks/useCurrentPath';
import { useLocalizedNavigate } from '../routes/hooks/useLocalizedNavigate';
import { appRoutes } from '../../config/app_routes';

const innerSearchKinds = {
  button: 'button',
  input:'input'
};
const getInnerSearchItemDisplay = (showHeaderSearchLine, kind) => {
  if (kind === innerSearchKinds.button) {
    return { display: showHeaderSearchLine ? 'none' : 'flex' };
  }
  return { display: showHeaderSearchLine ? 'flex' : 'none' };
};

function HeaderSearch({ t }) {
  const history = useLocalizedNavigate();
  const currentPath = useCurrentPath();
  const searchInputRef = useRef();
  const [showHeaderSearchLine, setShowHeaderSearchLine] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState(
    queryString.parse(currentPath.search).query
  );

  useEffect(() => {
    setSearchInputValue(queryString.parse(currentPath.search).query);
  }, [currentPath.search]);

  useEffect(() => {
    if ((searchInputRef || {}).current) searchInputRef.current.focus();
  }, [showHeaderSearchLine]);

  const onHeaderSearchLineButtonClick = () => {
    onHeaderSearchClick({
      searchInputRef, setSearchInputValue, history, currentPath
    });
  };

  const onHeaderSearchLineButtonKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onHeaderSearchLineButtonClick();
    }
  };

  const searchInputValueChange = (e) => {
    setSearchInputValue(e.target.value);
  };

  const headerSearchButtonClick = () => {
    setShowHeaderSearchLine(true);
  };

  const headerSearchLineCloseButtonClick = () => {
    onHeaderSearchClick({
      searchInputRef,
      setSearchInputValue,
      history,
      currentPath,
      isSearchLineCloseButtonClick: true
    });
    setShowHeaderSearchLine(false);
  };

  const closeButton = () => (
    <div className={ classes.headerSearchLineSearchCloseButtonContainer }>
      <button
        type="button"
        className={ classes.headerSearchLineButton }
        onClick={ onHeaderSearchLineButtonClick }
      >
        { t('search_button_title') }
      </button>
      <button
        type="button"
        className={ classes.headerSearchLineCloseButton }
        onClick={ headerSearchLineCloseButtonClick }
      >
        <SvgIcon className={ classes.headerSearchLineCloseButtonImg } id="close_icon" />
      </button>
    </div>
  );

  const searchInput = () => (
    <div
      suppressHydrationWarning
      className={ classes.headerSearchLineContainer }
      style={ getInnerSearchItemDisplay(showHeaderSearchLine, innerSearchKinds.input) }
    >
      <div className={ classes.headerSearchLineInputContainer }>
        <div className={ classes.headerSearchLineSearchIconAndInputContainer }>
          <SvgIcon className={ classes.headerSearchGreyIcon } id="gray_search_icon" size={ 20 } />
          <form className={ classes.headerSearchLineForm }>
            <input
              type="text"
              ref={ searchInputRef }
              value={ searchInputValue || '' }
              onChange={ searchInputValueChange }
              className={ classes.headerSearchLineInput }
              placeholder={ t('search_placeholder') }
              onKeyDown={ onHeaderSearchLineButtonKeyDown }
              autoComplete="off"
            />
          </form>
        </div>
        <div className={ classes.headerSearchLineDivider } />
      </div>
      {closeButton()}
    </div>
  );

  const renderSearch = () => {
    if (currentPath.routeKey === appRoutes.home) return null;
    return (
      <div className={ classes.headerSearchMainContainer } suppressHydrationWarning>
        <button
          suppressHydrationWarning
          type="button"
          className={ classes.headerSearchButton }
          onClick={ headerSearchButtonClick }
          style={ getInnerSearchItemDisplay(showHeaderSearchLine, innerSearchKinds.button) }
        >
          <SvgIcon className={ classes.headerSearchButtonImg } id="black_search_icon" size={ 20 } />
        </button>
        {searchInput()}
      </div>
    );
  };

  return renderSearch();
}

HeaderSearch.propTypes = {
  t:PropTypes.func.isRequired
};

export default withPGTranslation(HeaderSearch);
