import React from 'react';
import queryString from 'query-string';
import Const from '../config/const';
import { getSEODataAPI, welcomePromoCodesAPI } from '../config/api';
import { getValueOrDefault, isPrerenderMode, pathsNameWithLocalization } from '../config/util';
import {
  navigationStackBarRoutesData,
  applicationRoutes
} from '../config/app_routes';
import { matchPaths, toCamelCase } from '../../common/config/utils';
import { findMatchingRouteKey, getCurrentPath } from '../components/routes/routes_helpers';
import { SUPPORTED_LANGUAGES } from '../config/i18n';

export const navigationStackItemProp = {
  itemScope: 'itemscope',
  itemType: 'https://schema.org/BreadcrumbList',
  navigationStackItem: {
    itemProp:'itemListElement',
    itemScope: 'itemscope',
    itemType:'https://schema.org/ListItem',
    link: {
      appLink: { itemProp: 'item' },
      span: { itemProp: 'name' }
    },
    meta: {
      position: (itemPropPosition) => <meta itemProp="position" content={ itemPropPosition } />
    }
  }
};

export const routMatch = ({ pathName, paths }) => {
  const match = matchPaths({ pathname: [...pathsNameWithLocalization(paths)], path: pathName });
  return match || {};
};

const createPathData = ({
  routeKey, getState, itemData, dataKey
}) => {
  const key = dataKey || routeKey;
  let pathData = navigationStackBarRoutesData[key];
  const { application } = getState();
  if (itemData) {
    pathData = { ...pathData, ...itemData };
  }
  if (Object.keys(application.screenStack).length === 0) {
    return { ...pathData.directScreenStack, [key]: pathData };
  }

  return { [key]: pathData };
};

export const updateNavigationStackAction = ({
  showNavigationStackBar, pathData, navigationStackLoading
}) => ({
  type: Const.application.updateNavigationStack,
  pathData,
  showNavigationStackBar,
  navigationStackLoading
});

export const navigationStackLoadingAction = () => ({
  type: Const.application.navigationStackLoading
});

const clearNavigationStackAction = () => ({
  type: Const.application.clearNavigationStack
});

const clearNavigationStackIfNeed = ({ routeKey, dispatch, getState }) => {
  const { shouldClearNavigationStackBar = false } = getValueOrDefault(applicationRoutes, routeKey);
  if (shouldClearNavigationStackBar) {
    const { application: { screenStack } } = getState();
    if (Object.keys(screenStack).length > 1) { // if not only Home
      dispatch(clearNavigationStackAction());
    }
  }
};

export const updateNavigationStack = ({
  dataKey, itemData, navigationStackLoading
}) => (dispatch, getState) => {
  const routes = Object.keys(applicationRoutes);
  const { routeKey } = getCurrentPath(routes);
  if (dataKey) {
    // remove language to match path
    const langPattern = new RegExp(`^/(?:${ SUPPORTED_LANGUAGES.join('|') })/`);
    dataKey = findMatchingRouteKey(dataKey.replace(langPattern, '/'));
  }
  clearNavigationStackIfNeed({ routeKey, dispatch, getState });
  const { shouldSetNavigationStackBar = false, shouldShowNavigationStackBar = false } = getValueOrDefault(applicationRoutes, routeKey);
  if (!shouldSetNavigationStackBar) {
    const { application: { showNavigationStackBar } } = getState();
    if (showNavigationStackBar) {
      dispatch(updateNavigationStackAction({ showNavigationStackBar: false,  pathData: {} }));
    }
    return;
  }
  const pathData = createPathData({
    routeKey, getState, itemData, dataKey
  });
  dispatch(updateNavigationStackAction({
    showNavigationStackBar: shouldShowNavigationStackBar, pathData, navigationStackLoading
  }));
};

const updateSmartBannerVisibilityAction = ({ visible, needPresent }) => ({
  type: Const.application.updateSmartBannerVisibility,
  visible,
  needPresent
});

export const updateSmartBannerVisibility = (routeKey) => (dispatch, getState) => {
  const { application: { needPresent } } = getState();
  if (!needPresent) return;
  const { showSmartBanner = false } = getValueOrDefault(applicationRoutes, routeKey);
  let aditionParams = { needPresent };
  if (needPresent && !showSmartBanner) {
    aditionParams = { needPresent: false };
  }
  dispatch(updateSmartBannerVisibilityAction({ visible: showSmartBanner, ...aditionParams }));
};

const setNewToPG = (value) => ({
  type: Const.application.setNewToPG,
  value
});

export const setNewToPGAction = (value) => (dispatch) => {
  dispatch(setNewToPG(value));
};

export const updateSEOData = (route, { seoData }) => ({
  type: Const.application.udpateSEOData,
  route,
  seoData
});

export const getSEOData = (route) => (dispatch) => {
  if (isPrerenderMode()) return;

  getSEODataAPI(route).then((res) => {
    dispatch(updateSEOData(route, res));
  });
};

export const appLoading = () => ({
  type: Const.application.appLoading
});

const setAnonymousUserAction = (anonymousUser) => ({
  type: Const.application.setAnonymousUser,
  anonymousUser
});

export const getAnonymousUser = () => (dispatch) => {
  const { promo } = toCamelCase(queryString.parse(window.location.search));
  let deeplink = null;
  if (promo) deeplink = `promo/${ promo }`;
  welcomePromoCodesAPI(deeplink).then(res => {
    const { anonymousUser } = res;
    dispatch(setAnonymousUserAction(anonymousUser));
  });
};
