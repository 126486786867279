import queryString from 'query-string';
import { promoCodeAPI, welcomePromoCodesAPI } from '../config/api';
import { toCamelCase } from '../../common/config/utils';
import { log, LOG_LEVELS } from '../../common/config/app_logger';
import { parseAndTrackAnalyticEvents } from './analytics';
import { getUser } from './user';
import Const from '../config/const';
import { setApplicationError } from './aplication_error';
import { getCookie, removeCookie } from '../config/cookies_action';

const promoCodeLoading = () => ({
  type: Const.promoCode.loading
});

const promoError = (error) => ({
  type: Const.promoCode.error,
  error
});

const promoSuccess = (creditText) => ({
  type: Const.promoCode.success,
  creditText
});

export const clearModalText = () => ({
  type: Const.promoCode.clearModalText
});

export const applyCode = (code) => (dispatch) => {
  dispatch(promoCodeLoading());
  promoCodeAPI(code)
    .then((res) =>  {
      dispatch(getUser());
      dispatch(promoSuccess(res.creditText));
      if (res.analyticEvents) { // track redeem promo code event
        parseAndTrackAnalyticEvents(res.analyticEvents);
      }
    })
    .catch((error) => error.json()
      .then((json) => {
        const fJson = toCamelCase(json);
        if (fJson.analyticEvents) { // track redeem promo failed code event
          parseAndTrackAnalyticEvents(fJson.analyticEvents);
        }
        if (fJson.fullMessages && fJson.fullMessages.length > 0) {
          dispatch(promoError(fJson.fullMessages[0]));
          return;
        }
        if (fJson?.errors?.promoCodeId) {
          dispatch(promoError(fJson?.errors?.promoCodeId[0]));
        }
      })
      .catch((toJsonError) => log(LOG_LEVELS.ERROR, 'PromoCode', 'toJsonError!', toJsonError)));
};

const autoRedemSetLoadingAction = ({ loading }) =>  ({
  type: Const.promoCode.autoRedemSetLoading,
  loading
});

export const autoRedemSetAuthHandlerSuccessAction = ({ authHandlerSuccess }) =>  ({
  type: Const.promoCode.autoRedemSetAuthHandlerSuccess,
  authHandlerSuccess
});

export const autoRedemSetJustSignedUpAuthHandlerSuccessAction = ({ justSignedUpAuthHandlerSuccess }) =>  ({
  type: Const.promoCode.autoRedemSetJustSignedUpAuthHandlerSuccess,
  justSignedUpAuthHandlerSuccess
});

export const autoRedemSetBonusPopup = ({ bonusPopup }) =>  ({
  type: Const.promoCode.autoRedemSetBonusPopup,
  bonusPopup
});

const removePromoFromSearch = ({ history }) => {
  if (!history) return;
  const search = toCamelCase(queryString.parse(window.location.search));
  if (!search.promo) return;
  delete search.promo;
  const updatedSearch = queryString.stringify(search);
  history(`${ window.location.pathname }?${ updatedSearch }`, {
    search: updatedSearch,
    state: { ...window.location.state }
  });
};

const autoRedemPromoCodeAfterAction = ({
  authHandlerSuccess, history, dispatch, resUser
}) => {
  dispatch(autoRedemSetLoadingAction({ loading: false }));
  removePromoFromSearch({ history });
  if (authHandlerSuccess) {
    authHandlerSuccess(resUser);
    dispatch(autoRedemSetAuthHandlerSuccessAction({ authHandlerSuccess: null }));
  }
};

const getAutoRedemPromoCode = () => {
  let promo = toCamelCase(queryString.parse(window.location.search))?.promo;
  if (!promo) {
    promo = getCookie({ cookieName: 'autoRedemePromoCode' });
  }
  if (getCookie({ cookieName: 'autoRedemePromoCode' })) {
    removeCookie({ cookieName: 'autoRedemePromoCode', cookieOptions: { path: '/' } });
  }
  return promo;
};

const autoRedemPromoCodeJustSignedUp = ({ history, justSignedUpAuthHandlerSuccess, dispatch }) => {
  const promo = getAutoRedemPromoCode();
  let deeplink = null;
  if (promo) {
    deeplink = `promo/${ promo }`;
    window.attribution.promo = '';
  }
  welcomePromoCodesAPI(deeplink).then((res) => {
    if (res.analyticEvents) { // track redeem promo code event
      parseAndTrackAnalyticEvents(res.analyticEvents);
    }
    if (res.bonusPopup) {
      dispatch(autoRedemSetBonusPopup({ bonusPopup: res.bonusPopup }));
    }
    dispatch(autoRedemSetLoadingAction({ loading: false }));
    removePromoFromSearch({ history });
    justSignedUpAuthHandlerSuccess(res.user);
    dispatch(autoRedemSetJustSignedUpAuthHandlerSuccessAction({ justSignedUpAuthHandlerSuccess: null }));
  });
};

export const autoRedemPromoCode = ({
  history, needShowErrorPoppup, authHandlerSuccess, justSignedUpAuthHandlerSuccess
}) => (dispatch) => {
  if (justSignedUpAuthHandlerSuccess) {
    dispatch(autoRedemSetLoadingAction({ loading: true }));
    autoRedemPromoCodeJustSignedUp({ history, justSignedUpAuthHandlerSuccess, dispatch });
    return;
  }
  const promo = getAutoRedemPromoCode();
  if (!promo) {
    autoRedemPromoCodeAfterAction({ authHandlerSuccess, history, dispatch });
    return;
  }
  dispatch(autoRedemSetLoadingAction({ loading: true }));
  window.attribution.promo = '';
  promoCodeAPI(promo)
    .then((res) => {
      if (res.analyticEvents) { // track redeem promo code event
        parseAndTrackAnalyticEvents(res.analyticEvents);
      }
      if (res.bonusPopup) {
        dispatch(autoRedemSetBonusPopup({ bonusPopup: res.bonusPopup }));
      }
      autoRedemPromoCodeAfterAction({
        authHandlerSuccess, history, dispatch, resUser: res?.user
      });
    })
    .catch((error) => {
      autoRedemPromoCodeAfterAction({ authHandlerSuccess, history, dispatch });
      error.json()
        .then((jsonError) => {
          const jsonErrorCamelCase = toCamelCase(jsonError);

          if (jsonErrorCamelCase.analyticEvents) { // track redeem promo failed code event
            parseAndTrackAnalyticEvents(jsonErrorCamelCase.analyticEvents);
          }

          if (needShowErrorPoppup) {
            dispatch(setApplicationError({ errorText: jsonErrorCamelCase.fullMessages[0] }));
          }
        })
        .catch(() => { dispatch(setApplicationError({ errorCode: error.status, statusText: error.statusText })); });
    });
};
