const pgLogoIcon = require('./img/pg_logo_icon.png');
const kasambaLogoIcon = require('./img/kasamba_logo_icon.png');
const psiquicosLogoIcon = require('./img/psq_logo_icon.png');

const pgFooterLogo = require('./img/pg_footer_logo.png');
const kasambaFooterLogo = require('./img/kasamba_footer_logo.png');
const psiquicosFooterLogo = require('./img/psq_footer_logo.png');

export const appLogo = () => {
  switch (process.env.APP_CONTEXTS) {
    case 'ka':
      return kasambaLogoIcon;
    case 'ps':
      return psiquicosLogoIcon;
    default:
      return pgLogoIcon;
  }
};

export const footerLogo = () => {
  switch (process.env.APP_CONTEXTS) {
    case 'ka':
      return kasambaFooterLogo;
    case 'ps':
      return psiquicosFooterLogo;
    default:
      return pgFooterLogo;
  }
};
