import { connect } from 'react-redux';
import { compose } from 'redux';
import withPGTranslation from '../../config/withPGTranslation';
import Registration from './registration';
import {
  logIn,
  register,
  clearError,
  authSocial,
  displayRegister,
  changeScreen,
  navigationPop,
  authClearPendingAction,
  authErrorActionCreator,
  gdprSwitcherValueChanged,
  shouldShowGDPRConsent,
  updateCurrentScreen
} from '../../actions/auth';
import { loadHomePage } from '../../actions/home_page';
import { clearAffiliatePromoPopup } from '../../actions/affiliate_promo_popup';
import { getUserMatchUpBonusInfo } from '../../config/util';
import { withCurrentPath } from '../routes/hoc/with_current_path';
import { withRouter } from '../routes/hoc/with_router';

const mapStateToProps = ({ auth, user: { user }, application }) => ({
  ...auth,
  userMatchUpBonusInfo:  getUserMatchUpBonusInfo({ bonusInfos: user && user.bonusInfos || application && application.anonymousUser && application.anonymousUser.bonusInfos }),
  userShowMatchUpBonusInfo: user && user.showMatchUpBonusInfo
});

export default connect(
  mapStateToProps,
  {
    logIn,
    register,
    clearError,
    authSocial,
    displayRegister,
    changeScreen,
    navigationPop,
    loadHomePage,
    clearAffiliatePromoPopup,
    authClearPendingAction,
    authErrorActionCreator,
    gdprSwitcherValueChanged,
    shouldShowGDPRConsent,
    updateCurrentScreen
  }
)(compose(withPGTranslation, withCurrentPath, withRouter)(Registration));
